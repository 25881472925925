import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Chip, Popover, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { decodeToken } from "react-jwt";
import { useAuth } from '../../services/auth-provider/AuthProvider';
import { styled } from '@mui/material/styles';

const StyledInfoHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
});

const StyledInfoBox = styled(CardContent)(({ theme }) => ({
    '& > * + *': {
      paddingTop: theme.spacing(1),
      marginTop: theme.spacing(1),
      borderTop: '1px solid ' + theme.palette.divider
    },
}))

interface RefreshToken {
  exp: number
}

type Props = {
  className?: string;
}

export default function AuthView({className}: Props): JSX.Element {
  const [ anchor, setAnchor ] = useState<HTMLElement | null>(null)
  const auth = useAuth();

  // Controls for auth info popover
  const close = () => setAnchor(null)
  const open = (event : React.MouseEvent<HTMLElement>) => setAnchor(event.currentTarget)

  const user = (
    <Button onClick={open} startIcon={<AccountCircle/>} color="inherit">
      <Typography variant="body2">
        {auth.user.profile.name}
      </Typography>
    </Button>
  )
  const expires = new Date(auth.user.expires_at * 1000)
  const badge = auth.user.expired ? <Chip label="expired" size="small" color="secondary" />  : <React.Fragment/>
  const accessInfo = (
    <Box>
        <StyledInfoHeader>
            <Typography variant="subtitle2">
                Access Token
            </Typography>
            {badge}
        </StyledInfoHeader>
      <Typography>{auth.user.profile.name} ({auth.user.profile.email})</Typography>
      <Typography>Expire{auth.user.expired ? "d" : "s"}: {expires.toLocaleString()}</Typography>
    </Box>
  )

  let refreshInfo = <React.Fragment/>
    
  if (auth.user.refresh_token) {
    const refresh = decodeToken(auth.user.refresh_token) as RefreshToken
    const expires = new Date(refresh.exp * 1000)
    const expired = expires < new Date()
    const badge = expired ? <Chip label="expired" size="small" color="secondary" />  : <React.Fragment/>
    refreshInfo = (
      <Box>
          <StyledInfoHeader>
              <Typography variant="subtitle2">Refresh Token</Typography>
              {badge}
          </StyledInfoHeader>
        <Typography>Expire{expired ? "d" : "s"}: {expires.toLocaleString()}</Typography>
      </Box>
    )
  }

  const info = (
    <Popover open={anchor !== null} anchorEl={anchor} onClose={close}>
      <Card>
          <StyledInfoBox>
              {accessInfo}
              {refreshInfo}
          </StyledInfoBox>
      </Card>
    </Popover>
  )

  return <div className={className}>{user} {info}</div>
}


