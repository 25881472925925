/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * The result of an API call. A tagged union containing either a success case along
 * with response data, or an error case along with an error message. Determine the case
 * by checking the value of .type.
 */
 export type ApiResult<S extends number = number, D = unknown, H = unknown> =
 | { type: "success", status: S, data: D, headers: H }
 | Err;


export type Handler<
    Status extends number = number,
    Data = unknown,
    Headers = unknown,
> = (result: ApiResult) => ApiResult<Status, Data, Headers>;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export interface AnyHandler extends Handler<number, any, any>{}

type DistributeApiResult<T extends AnyHandler> = T extends Handler<infer S, infer D, infer H>
    ? ApiResult<S, D, H>
    : never;

/**
 * A helper type constructor to create a union of possible `ApiResult`s based on
 * the input Handlers.
 */
export type ApiResultUnion<T extends AnyHandler[]> =
    | DistributeApiResult<T[number]>
    | Err;


export class Err {
    type: "error" = "error" as const;
    message: string;
    constructor(message: string) {
        this.message = message;
    }
}

export class ErrBadStatus extends Err {
    status: number;
    constructor(status: number) {
        super(`bad status: ${status}`);
        this.status = status;
    }
}