import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(4),
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
}));

const StyledImage = styled('img')({
    width: '90%',
    float: 'right',
});

const StyledNote = styled(Typography)(({ theme }) => ({
    width: '90%',
    textAlign: 'right',
    fontSize: '0.85rem',
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(4),
}));

export const WelcomeImage: React.FC = () => {
    const darkMode = window.localStorage.getItem('dark-mode');

    return (
        <StyledRoot>
            <StyledImage src={`../assets/welcome_screen_${darkMode === 'true' ? 'dark' : 'light'}.svg`} />
            <StyledNote>From top to bottom: Security rating, Industry Benchmark, Threat intelligence</StyledNote>
        </StyledRoot>
    )
}

