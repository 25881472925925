import React from "react";
import { PageData } from "src/services/postgrest-provider";
import { LinkButton, Props as LinkButtonProps } from "../LinkButton/LinkButton";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    alignItems: "flex-end",
    padding: theme.spacing(1),
}))

const StyledTotal = styled('span')({
    lineHeight: 1,
});

const StyledPages = styled('div')(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
    alignItems: "flex-end",
}))

type StyledLinkButtonProps = LinkButtonProps & {
    isActive: boolean;
}

const StyledPage = styled(LinkButton, {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<StyledLinkButtonProps>(({ isActive, theme }) => ({
    lineHeight: 1,
    ...(isActive && {
        fontSize: "1.1rem",
        textDecoration: "underline",
        color: theme.palette.primary.main,
    })
}))

type Page<T> = PageData<T>["pages"][number];

type Props<T> = {
    data: PageData<T>;
    pageSelected: (page: Page<T>) => void;
    item?: string;
};

export const PageControl = <T,>({ data, pageSelected, item = "item" }: Props<T>): JSX.Element => {
    // TODO: Add logic for displaying large pages.
    const paginationList = (
        <>
            {data.pages.map((page) => (
                <StyledPage
                    key={page.number}
                    isActive={page.number === data.current.number}
                    color="inherit"
                    onClick={() => pageSelected(page)}
                >
                    {page.number}
                </StyledPage>
            ))}
        </>
    );

    return (
        <StyledRoot>
            <StyledTotal>
                {data.total} {item}{data.total !== 1 && "s"} in total
            </StyledTotal>
            <StyledPages>
                { paginationList }
            </StyledPages>
        </StyledRoot>
    );
};
