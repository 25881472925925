import * as t from 'io-ts';
import { LiteralUnion } from '../LiteralUnion';

// https://stripe.com/docs/api/sources/object
export const SourceCodec = t.interface({
    object: t.literal("card"),
    id: t.string,
    brand: LiteralUnion([
        "American Express",
        "Diners Club",
        "Discover",
        "JCB",
        "MasterCard",
        "UnionPay",
        "Visa",
        "Unknown",
    ] as const),
    country: t.string,
    cvc_check: LiteralUnion([
        "pass",
        "fail",
        "unavailable",
        "unchecked",
    ] as const),
    exp_month: t.number,
    exp_year: t.number,
    fingerprint: t.string,
    last4: t.string,
    name: t.string,
});

export type Source = t.TypeOf<typeof SourceCodec>;