import { useTheme } from "@mui/material";
import React, { FC } from "react";
import { useNotices } from "src/services/notice-provider/NoticeProvider";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const NoticeBanner: FC = () => {
    const theme = useTheme();
    const { notices } = useNotices();

    if (notices.length < 1) {
        return null;
    }

    const { content, colour } = notices[notices.length - 1];

    const style = {
        background: theme.palette[colour].main,
        color: theme.palette[colour].contrastText,
    };

    return (
        <StyledRoot style={style}>
            {content}
        </StyledRoot>
    );
}
