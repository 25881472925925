import { Dialog } from "@mui/material";
import React from "react";
import { PaymentMethodDialogContent } from "./PaymentMethodDialogContent";
import { PaymentHandler } from "../paymentHandler";
import { styled } from '@mui/material/styles';

type Props = {
    open: boolean;
    handleClose: () => void;
    handlePayment: PaymentHandler;
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    padding: theme.spacing(6),
    margin: theme.spacing(6),
    minHeight: "100vh",
    maxHeight: "100vh",
}))

export const PaymentMethodDialog: React.FC<Props> = ({
    open,
    handleClose,
    handlePayment,
}) => {
    return (
        <StyledDialog
            fullWidth
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="card-dialog"
            open={open}
        >
            <PaymentMethodDialogContent
                handleClose={handleClose}
                handlePayment={handlePayment}
            />
        </StyledDialog>
    );
};
