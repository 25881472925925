import { NavigateNext } from "@mui/icons-material";
import React, { FC } from "react";
import { NavLink } from "src/elements/NavLink/NavLink";
import { IssueTable } from "./IssueTable";
import { styled } from '@mui/material/styles';

type Props = {
    count?: number;
    state?: "all" | "open" | "closed";
}

const StyledRoot = styled('div')({
    display: "flex",
    justifyContent: "center",
})

const StyledLink = styled(NavLink)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.table.head.text,
    display: "flex",
    alignItems: "center",
}))

export const IssueTableWidget: FC<Props> = ({ count = 12, state = "all" }) => {
    return (
        <IssueTable paged={false} pageSize={count} state={state} footer={(
            <StyledRoot>
                <StyledLink to={`/issues?state=${state}`}>
                    View All <NavigateNext fontSize="small"/>
                </StyledLink>
            </StyledRoot>
        )}/>
    );
}