import React, { FC } from "react"
import { Route, Routes } from "react-router-dom";
import { Tabs } from "src/elements/Tabs/Tabs";
import { useSubscription } from "src/services/subscription-provider/SubscriptionProvider";
import { Welcome } from "../welcome/Welcome";
import { MostRecent } from "./MostRecent";
import {WeeklyReport} from "./WeeklyReport";
import { styled } from '@mui/material/styles';

type DashboardProps = {
    children?: React.ReactNode;
}

const StyledRoot = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    minHeight: "100%",
}));

const StyledMostRecent = styled(MostRecent)(({ theme }) => ({
    overflowX: "hidden",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    alignItems: "stretch",
    "& > *:first-child": {
        display: "flex",
        flexGrow: 1,
    },
    "& > *:last-child": {
        display: "flex",
        gap: theme.spacing(2),
    }
}));

export const Dashboard: FC<DashboardProps> = ({ children = <DashboardContent/> }) => {

    const subscription = useSubscription();

    if (subscription.current.show_welcome) {
        return (
            <Welcome/>
        );
    }

    return (
        <>
            {children}
        </>
    );
}

const DashboardContent: FC = () => {
    return (
        <Routes>
            <Route path="*" element={<DashboardContentElement/>}/>
        </Routes>
    );
}

const DashboardContentElement: FC = () => {
    return (
        <StyledRoot>
            <Tabs tabs={["Most Recent", "7 Days"]}>
                <StyledMostRecent />
                <WeeklyReport />
            </Tabs>
        </StyledRoot>
    );
}

export default Dashboard
