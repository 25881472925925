import React from "react"
import { Nav } from "./Nav";
import { Logo } from './Logo';
import { Controls } from "./Controls";
import AuthView from "../auth-view/AuthView";
import { NoticeBanner } from "../notice-banner/NoticeBanner";
import { styled } from '@mui/material/styles';


type Props = {
    children: JSX.Element | Array<JSX.Element>
}

const leftWidth = 240;

const StyledRoot = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    overflow: 'hidden',
    flexGrow: 1,
});

const StyledFullScreen = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
});

const StyledLeft = styled('div')(({ theme }) => ({
    width: `${leftWidth}px`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    alignItems: 'stretch',
    background: theme.palette.background.component,
}));

const StyledLogo = styled(Logo)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    padding: theme.spacing(2),
}))

const StyledExpand = styled('div')({
    flexGrow: 1,
});

const StyledUser = styled(AuthView)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-start',
}));

const StyledControls = styled(Controls)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2),
}));

const StyledContent = styled('div')({
    flexGrow: 1,
    overflow: 'auto',
    flexShrink: 0,
});

export const Layout: React.FC<Props> = ({
    children,
}: Props) => {
    const contentWidth = `calc(100% - ${leftWidth}px)`;

    return (
        <StyledFullScreen>
            <NoticeBanner />
            <StyledRoot>
                <StyledLeft>
                    <StyledLogo />
                    <Nav />
                    <StyledExpand />
                    <StyledUser />
                    <StyledControls />
                </StyledLeft>

                <StyledContent style={{ width: contentWidth }}>
                    {children}
                </StyledContent>
            </StyledRoot>
        </StyledFullScreen>
    );
}
