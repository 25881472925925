import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResult, Err } from "./types";


export interface WrappedAxios {
    request<T = unknown>(config: AxiosRequestConfig): Promise<AxiosResponse<T>>;
}

/**
 * The Api class allows users to make authenticated calls against the backend HTTP API.
 */
export class Api {
    private apiURL: string;

    public client: WrappedAxios;

    constructor(token: string, apiURL: string) {
        this.apiURL = apiURL;
        this.client = axios.create({
            baseURL: this.apiURL,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    updateToken(token: string): void {
        this.client = axios.create({
            baseURL: this.apiURL,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    /**
     * Make a GET request against the API.
     * @param url The URL to perform a GET request against. This should be an absolute path + query parameters.
     * @param codec The codec to use when parsing/validating the response. This determines the type of the response data.
     * @returns A tagged union containing either the response data or an error. The response data is returned if .type is
     * "success", and an error is returned if .type is "error".
     */
    async Get(url: string): Promise<ApiResult> {
        return this.Request({ method: "GET", url });
    }

    async Put(url: string, data: unknown): Promise<ApiResult> {
        return this.Request({ method: "PUT", url, data });
    }

    async Post(url: string, data: unknown): Promise<ApiResult> {
       return this.Request({ method: "POST", url, data });
    }

    async Delete(url: string): Promise<ApiResult> {
       return this.Request({ method: "DELETE", url });
    }

    async Patch(url: string, data: unknown): Promise<ApiResult> {
        return this.Request({ method: "PATCH", url, data});
    }

    async Request(config: AxiosRequestConfig): Promise<ApiResult> {
        return await new Promise(resolve => {
            this.client.request<unknown>({
                ...config,
                validateStatus: () => true, // Accept any status as we expect those to be checked downstream.
            }).then(res => {
                return resolve({
                    type: "success",
                    status: res.status,
                    data: res.data,
                    headers: res.headers,
                });
            }).catch((error: Error) => resolve(new Err(error.message)));
        });
    }
}
