import * as t from "io-ts";
import { tables } from "./tables";
import { Codec, CodecFields, CodecSlice, TableName } from "./types";

/**
 * Get the full codec for the given api table.
 * @param name the name of the table to return codec for.
 * @returns full codec for the given table.
 */
export function getCodec<T extends TableName>(name: T): Codec<T> {
    return tables[name];
}

/**
 * Return a codec for the given api table which included only a subset of the fields/columns
 * from the full codec. This is used when querying only select columns from the api, rather than
 * all columns.
 * @param name The name of the table to return a sliced codec for.
 * @param fields The fields to include in the codec slice.
 * @returns A codec slice which expects only the given fields.
 */
export function getCodecSlice<T extends TableName, K extends CodecFields<T>>(name: T, fields: K[]): CodecSlice<T, typeof fields[0]> {
    const codec = tables[name];
    return makeNarrowedCodec(codec, fields);
}

type NarrowedCodecProps<P extends t.Props, C extends t.InterfaceType<P>, K extends keyof C["props"]> = {
    [Property in K]: C["props"][Property]
}

function makeNarrowedCodec<P extends t.Props, C extends t.InterfaceType<P>, K extends keyof C["props"]>(codec: C, fields: Array<K>): t.TypeC<NarrowedCodecProps<P, C, typeof fields[0]>> {
    const props = {} as NarrowedCodecProps<P, C, typeof fields[0]>
    fields.map(field => {
        props[field] = codec.props[field];
    })
    return t.interface(props);
}
