import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from "@mui/material";
import Alert from '@mui/material/Alert'
import React, { useContext, useState } from "react";
import { useApi, expectStatus } from "../../services/api-provider";
import { SubscriptionContext } from "../../services/subscription-provider/SubscriptionProvider";

const DOMAIN_NAME_PATTERN = /^(?:[_a-z0-9](?:[_a-z0-9-]{0,61}[a-z0-9])?\.)+(?:[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?)?$/;

type Status = { error: false } | { error: true, helperText: string };

export interface TargetDomainCreateDialogProps {
    open: boolean;
    closeDialog: () => void;
    postAction: () => void;
    checkDomainExists: (domain: string) => boolean
}

const isValidDomain = (domain: string) => {
    const pattern = DOMAIN_NAME_PATTERN;
    return pattern.test(domain);
}

export default function TargetDomainCreateDialog({ open, closeDialog, postAction, checkDomainExists }: TargetDomainCreateDialogProps): JSX.Element {
    const api = useApi();
    const [status, setStatus] = useState<Status>({ error: false });

    const [name, setName] = React.useState("");
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }

    const subscriptionId = useContext(SubscriptionContext).current.uuid

    const handleClose = () => {
        closeDialog()
        setStatus({ error: false })
        setName("")
    }

    const handleCreate = () => {
        // Validate if domain name is blank
        if (name.length === 0) {
            setStatus({
                error: true,
                helperText: "Domain name cannot be blank"
            });
            return
        }

        // Validate if domain name is valid
        if (!isValidDomain(name)) {
            setStatus({
                error: true,
                helperText: "Domain name should only contain lower case letters, numbers, full stop (.) and underscore (_)"
            });
            return;
        }

        // Validate if domain exists
        if (checkDomainExists(name)) {
            setStatus({
                error: true,
                helperText: "Domain already exists"
            });
            return;
        }

        setStatus({ error: false });

        api.Post("/api/targetdomain/add", {
            "domain": name,
            "subscription": subscriptionId
        }).then(expectStatus(202)).then(res => {
            if (res.type === "error") {
                console.log(res.message);
            } else {
                success();
            }
        });
    }

    const success = () => {
        handleClose()
        handleOpenSB()
        postAction()
    }

    const [openSB, setOpenSB] = React.useState(false);

    const handleOpenSB = () => {
        setOpenSB(true)
    }

    const handleCloseSB = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSB(false);
    }

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <DialogTitle id="form-dialog-title">Create target domain</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    color="secondary"
                    required
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    onChange={handleNameChange}
                    error={status.error}
                    helperText={status.error && status.helperText} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleCreate}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar open={openSB} autoHideDuration={6000} onClose={() => handleCloseSB()}>
            <Alert onClose={handleCloseSB} severity="success">
                Target Domain was created!
            </Alert>
        </Snackbar>
    </>;
}