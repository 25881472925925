import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, LinearProgress } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { Fragment, useContext, useState } from "react";
import { SubscriptionContext } from "../../services/subscription-provider/SubscriptionProvider";
import { useApi } from "../../services/api-provider/ApiProvider";

type state = "closed" | "confirm" | "deleting" | "error" | "deleted";

export default function DeleteSubscriptionDialog(): JSX.Element {

    const api = useApi();
    const { current, refresh } = useContext(SubscriptionContext);
    const [state, setState] = useState<state>("closed");

    const handleDelete = () => {
        setState("deleting");
        api.Post("/api/subscription/remove", {
            "id": current.uuid
        }).then(res => {
            if (res.type === "error") {
                setState("error");
                console.error("failed calling delete subscription", res.message);
            } else if (200 <= res.status && res.status <= 299) {
                setState("deleted");
            } else {
                setState("error");
            }
        });
    }

    const content = () => {
        switch (state) {
        case "confirm":
            return <DialogContentText>
                Are you sure you want to delete this subscription?
            </DialogContentText>;
        case "deleting":
            return <Fragment>
                 <DialogContentText>
                    The subscription is being deleted...
                </DialogContentText>
                <LinearProgress/>
            </Fragment>;
        case "error":
            return <DialogContentText>
                Deleting the subscription failed.
            </DialogContentText>;
        case "deleted":
            return <DialogContentText>
                The subscription has been deleted, the app will reload.
            </DialogContentText>;
        }
    }

    const actions = () => {
        switch (state) {
        case "confirm":
            return <DialogActions>
                <Button onClick={() => setState("closed")}>
                    No
                </Button>
                <Button onClick={handleDelete} color="secondary">
                    Yes
                </Button>
            </DialogActions>
        case "deleting":
            return <DialogActions>
                <Button disabled>
                    No
                </Button>
                <Button disabled color="secondary">
                    Yes
                </Button>
            </DialogActions>;
        case "error":
            return <DialogActions>
                <Button onClick={() => setState("closed")}>
                    Close
                </Button>
            </DialogActions>;
        case "deleted":
            return <DialogActions>
                <Button onClick={() => {
                    refresh();
                    setState("closed");
                }}>
                    OK
                </Button>
            </DialogActions>
        }
    }

    return (
        <Fragment>
            <Button onClick={() => setState("confirm")} size="small" variant="outlined" endIcon={<DeleteIcon/>}>
                Delete Subscription
            </Button>
            <Dialog open={state !== "closed"} maxWidth="xs" fullWidth>
                <DialogTitle id="form-dialog-title">Delete subscription</DialogTitle>
                <DialogContent>
                    {content()}
                </DialogContent>
                {actions()}
            </Dialog>
        </Fragment>
    )
}