import * as t from 'io-ts';
import { EpochSeconds } from '../EpochSeconds';

// https://stripe.com/docs/api/products/object
export const ProductCodec = t.interface({
    id: t.string,
    active: t.boolean,
    description: t.string,
    name: t.string,
    object: t.literal("product"),
    created: EpochSeconds,
    updated: EpochSeconds,
});

export type Product = t.TypeOf<typeof ProductCodec>;