import { FormControl, FormGroup, FormLabel, Slider } from "@mui/material";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { RefProps } from "./IssueFilterBy";
import { styled, useTheme } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    width: "100%",
}));

const StyledSliderFormGroup = styled(FormGroup)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
})

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.secondary.main,
    padding: "1rem 0",
    width: "90%",
    "& .MuiSlider-valueLabel": {
        backgroundColor: "transparent",
        color: "transparent",
        width: "5rem",
        left: "auto",
        top: "1rem",
        "& span": {
            width: "auto",
            transform: "none",
        }
    }
}))

type DurationSliderProps = {
    min: number;
    max: number;
}

export const IssueFilterByDurationGroup = forwardRef<RefProps, DurationSliderProps>(
    function IssueFilterBase(props: DurationSliderProps, ref) {
        const theme = useTheme();
        const [state, setState] = useState<number | number[]>([]);
        const [range, setRange] = useState({
            min: 0,
            max: 100,
        });

        useImperativeHandle(ref, () => ({
            getQuery() {
                if (Array.isArray(state) && state.length === 2) {
                    const query = `and=(duration.gte.${state[0]},duration.lte.${state[1]})`;
                    return query;
                } else if (typeof state === "number") {
                    const query = `duration=eq.${state}`;
                    return query;
                }
                return "";
            },
            clear() {
                setState([range.min, range.max] as number[]);
            },
        }));


        useEffect(() => {
            const { min, max } = props;
            setRange({
                min: min,
                max: max,
            })

            if (min !== range.min || max !== range.max)
                setState([min, max]);
        }, [props, range.min, range.max]);

        const valuetext = (value: number) => `${value} days`;

        const handleChange = (newValue: number | number[]) => {
            if (Array.isArray(newValue)) {
                setState(newValue.sort((a, b) => a - b));
            }
        };

        return (
            <StyledFormControl variant="standard">
                <FormLabel component="legend" style={{ marginBottom: theme.spacing(2) }} >
                    Open for { Array.isArray(state) ? `${state[0]} to ${state[1]} days`: `${state} day${state === 1 ? "" : "s"}`}
                </FormLabel>
                <StyledSliderFormGroup>
                    <StyledSlider
                        value={state}
                        max={range.max}
                        min={range.min}
                        getAriaValueText={valuetext}
                        aria-labelledby="range-slider"
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `${value} day${value === 1 ? "" : "s"}`}
                        onChange={(_, val) => handleChange(val)}
                        step={1}
                    />
                </StyledSliderFormGroup>
            </StyledFormControl>
        );
    }
);
