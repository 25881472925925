import React from "react";
import { ListItem, ListItemText, Typography, useTheme } from "@mui/material";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { MenuItem } from "./Nav";

type NavSubMenuListItemProp = {
    item: MenuItem;
};

export const NavSubMenuListItem: React.FC<NavSubMenuListItemProp> = ({ item }) => {
    const theme = useTheme();
    const resolved = useResolvedPath(item.link || "");
    const match = useMatch({ path: resolved.pathname, end: false });

    return (
        <ListItem
            selected={!!match}
            button
            component={NavLink}
            to={item.link || ""}
            style={{ paddingLeft: theme.spacing(6) }}
        >
            <ListItemText
                primary={
                    <Typography
                        variant="caption"
                        style={{
                            color: theme.palette.text.primary,
                            fontWeight: match ? "bold" : "normal",
                        }}
                    >
                        {item.name}
                    </Typography>
                }
            />
        </ListItem>
    );
};