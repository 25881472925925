import React, { useRef, useState } from "react";
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from '@mui/material/styles';

type Props = {
    searchData: (text: string) => void;
};

const StyledRoot = styled('div')({
    width: "80%",
});

const StyledForm = styled(FormControl)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    gap: theme.spacing(1),
}))

const StyledInputField = styled(TextField)(({ theme }) => ({
    width: "70%",
    height: "3rem",
    "&::placeholder": {
        color: theme.palette.secondary.light,
        opacity: 1,
    },
    "& > *": {
        height: "100%",
    },
}))

const StyledSearchButton = styled(Button)(({ theme }) => ({
    width: "30%",
    textTransform: "none",
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
}))

const StyledInputSearchIcon = styled(SearchIcon)(({ theme }) => ({
    color: theme.palette.secondary.main,
}))

export const IssueSearchInput: React.FC<Props> = ({ searchData }) => {
    const [searchText, setSearchText] = useState<string>("");
    const [focusState, setFocusState] = useState<boolean>(false);

    const inputRef = useRef<TextFieldProps>();

    const onSearch = () => {
        searchData(searchText);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        if (event.target.value === "") {
            searchData("");
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            searchData(searchText);
        }
    };

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocusState(event.type === "focus");
    };

    const handleClearBtnClick = () => {
        setSearchText("");
        searchData("");
    };

    const startAdornment = () => {
        if (focusState || searchText !== "") {
            return "";
        }

        return (
            <InputAdornment position="start">
                <StyledInputSearchIcon />
            </InputAdornment>
        );
    };

    const endAdornment = () => {
        if (searchText === "") {
            return "";
        }

        return (
            <InputAdornment position="end">
                <IconButton onClick={handleClearBtnClick} color="primary" disableRipple size="large">
                    <ClearIcon />
                </IconButton>
            </InputAdornment>
        );
    };

    return (
        <StyledRoot>
            <StyledForm fullWidth variant="outlined">
                <StyledInputField
                    InputProps={{
                        startAdornment: startAdornment(),
                            endAdornment: endAdornment(),
                    }}
                    placeholder="Search for keywords"
                    inputRef={inputRef}
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleOnFocus}
                    onBlur={handleOnFocus}
                    value={searchText}
                />
                <StyledSearchButton
                    variant="outlined"
                    color="primary"
                    onClick={onSearch}
                >
                    <Typography variant="h3">Search</Typography>
                </StyledSearchButton>
            </StyledForm>
        </StyledRoot>
    );
};
