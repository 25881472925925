import * as t from "io-ts";
import { AnyHandler, ApiResult, ApiResultUnion, Err, ErrBadStatus } from "./types";


const isExpectedStatus = <S extends number, E extends S>(s: S, expected: E[]): s is E => {
    return expected.some(e => e === s);
}

export const expectStatus = <
    Status extends number,
    Data,
    Headers,
    ExpectedStatus extends Status
>(
    ...expected: ExpectedStatus[]
) => (result: ApiResult<Status, Data, Headers>): ApiResult<ExpectedStatus, Data, Headers> => {
    if (result.type === "error") {
        return result;
    }

    if (isExpectedStatus(result.status, expected)) {
        return {
            ...result,
            status: result.status,
        };
    }

    return new ErrBadStatus(result.status);
}


export const parseData = <
    Status extends number,
    Data,
    Headers,
    Parser extends t.Any,
>(
    parser: Parser
) => (result: ApiResult<Status, Data, Headers>): ApiResult<Status, t.TypeOf<Parser>, Headers> => {
    if (result.type === "error") {
        return result;
    }
    const parse = parser.decode(result.data);
    if (parse._tag === "Left") {
        return new Err("couldn't parse data");
    }
    return {
        ...result,
        data: parse.right,
    };
}


export const parseHeaders = <
    Status extends number,
    Data,
    Headers,
    Parser extends t.Any,
>(
    parser: Parser
) => (result: ApiResult<Status, Data, Headers>): ApiResult<Status, Data, t.TypeOf<Parser>> => {
    if (result.type === "error") {
        return result;
    }
    const parse = parser.decode(result.headers);
    if (parse._tag === "Left") {
        return new Err("couldn't parse headers");
    }
    return {
        ...result,
        headers: parse.right,
    };
}


export const handleMany = <
    Handlers extends AnyHandler[]
>(
    ...handlers: Handlers
) => (result: ApiResult): ApiResultUnion<Handlers> => {

    if (result.type === "error") {
        return result;
    }

    const unhandled: ApiResultUnion<Handlers> = new ErrBadStatus(result.status);

    return handlers.reduce<ApiResultUnion<Handlers>>((r, handler) => {
        const res = handler(result);
        
        // If it's a bad status, we ignore it
        if (res.type === "error" && res instanceof ErrBadStatus) {
            return r;
        }
        
        // Asserting the type here
        return res as ApiResultUnion<Handlers>;
    }, unhandled);
}


export const handle = <
    Status extends number,
    Data,
    Headers,
    ExpectedStatus extends Status,
    DataParser extends t.Any,
    HeadersParser extends t.Any,
>(
    status: ExpectedStatus,
    data: DataParser,
    headers: HeadersParser,
) => (
    result: ApiResult<Status, Data, Headers>
): ApiResult<ExpectedStatus, t.TypeOf<DataParser>, t.TypeOf<HeadersParser>> => {

    if (result.type === "error") {
        return result;
    }

    if (!isExpectedStatus(result.status, [status])) {
        return new ErrBadStatus(result.status);
    }

    const parseData = data.decode(result.data);
    if (parseData._tag === "Left") {
        return new Err(`couldn't parse data for status ${result.status}`);
    }

    const parseHeaders = headers.decode(result.headers);
    if (parseHeaders._tag === "Left") {
        return new Err(`couldn't parse headers for status ${result.status}`);
    }

    return {
        type: "success",
        status: status,
        data: parseData.right,
        headers: parseHeaders.right,
    };
}
