import {
    Card,
    CardContent,
    Chip,
    ChipProps,
    IconButton,
    Popover,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import TargetDomainDeleteDialog from "./TargetDomainDeleteDialog";
import { CheckCircle, Error, VerifiedUser, Warning, Delete } from "@mui/icons-material";
import { TinyColor } from "@ctrl/tinycolor";
import { TargetDomain } from "./TargetDomainsPage";
import { styled } from '@mui/material/styles';

interface TargetDomainsListItemProps {
    targetDomain: TargetDomain;
    postDeleteAction: () => void
}

type StyledChipProps = ChipProps & {
    verifiedClass: "verified" | "unverified"
}

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== 'verifiedClass'
})<StyledChipProps>(({ verifiedClass, theme }) => {
    return ({
        "& .MuiChip-icon": {
            color: theme.palette.warning.contrastText,
        },
        ...(verifiedClass === "verified" && {
            backgroundColor: theme.palette.success.main,
            "& .MuiChip-root": {
                color: theme.palette.success.contrastText,
                backgroundColor: theme.palette.success.main,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.success.dark,
                }
            },
            "& .MuiChip-label": {
                color: theme.palette.success.contrastText,
            }
        } || verifiedClass === "unverified" && {
            backgroundColor: theme.palette.warning.main,
            "& .MuiChip-root": {
                color: theme.palette.warning.contrastText,
                backgroundColor: theme.palette.warning.main,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.warning.dark,
                }
            },
            "& .MuiChip-label": {
                color: theme.palette.success.contrastText,
            }
        })
    })
})

const StyledVerificationCard = styled(Card)({
    maxWidth: "30rem",
});

const StyledCode = styled('pre')(({ theme }) => ({
    padding: `${theme.spacing(0.2)} ${theme.spacing(0.4)}`,
    background: theme.palette.background.default,
    border: `solid 1px ${theme.palette.divider}`,
    fontFamily: "monospace",
}));

const StyledVerifiedInfo = styled(Typography)(({ theme }) => ({
    backgroundColor: new TinyColor(theme.palette.warning.main).setAlpha(0.3).toPercentageRgbString(),
    padding: theme.spacing(0.3),
    fontSize: "0.9rem",
    '& > svg': {
        verticalAlign: 'text-bottom',
        fontSize: "1rem",
        color: theme.palette.warning.main,
    }
}))


const StyledUnverifiedInfo = styled(Typography)(({ theme }) => ({
    backgroundColor: new TinyColor(theme.palette.warning.main).setAlpha(0.3).toPercentageRgbString(),
    padding: theme.spacing(0.3),
    fontSize: "0.9rem",
    '& > svg': {
        verticalAlign: 'text-bottom',
        fontSize: "1rem",
        color: theme.palette.warning.main,
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "& > td, & > th": {
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderTop: `1px solid ${theme.palette.divider}`,
    }
}))

const StyledTypographyCode = StyledCode.withComponent(Typography);


export const TargetDomainsListItem: React.FC<TargetDomainsListItemProps> = ({targetDomain, postDeleteAction}: TargetDomainsListItemProps): JSX.Element => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
   

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [verificationDetailsOpen, setVerificationDetailsOpen] = useState(false);
    const toggleVerificationDetails = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setVerificationDetailsOpen(!verificationDetailsOpen);
    };

    const { passed, code, domain, verified_until } = targetDomain;
    const label = passed ? "Verified" : "Unverified";
    const icon = passed ? <VerifiedUser/> : <Error/>;
    const verifiedClass = passed ? "verified" : "unverified";
   
    const verification = <>
        <StyledChip verifiedClass={verifiedClass} icon={icon} label={label} size="small" onClick={toggleVerificationDetails} />
        <Popover 
            anchorEl={anchorEl}
            open={verificationDetailsOpen}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            transformOrigin={{vertical: "top", horizontal: "center"}}
            onClose={() => setVerificationDetailsOpen(false)}
        >
            <StyledVerificationCard>
                <CardContent>
                    <Typography variant="h5">Verification Code</Typography>
                    <StyledCode>{code}</StyledCode>
                    <Typography gutterBottom>
                        Ensure a <StyledCode>TXT</StyledCode> record exists for <StyledCode>{domain}</StyledCode> containing the verification code.
                        { !passed && (
                            <>
                                <span> The record will typically be detected within a minute of publishing.</span>
                                <Typography variant="body2" style={{"marginTop": "2rem"}}>
                                    * Note: TXT Records are applied immediately but may take up to 2 hours to propagate across the internet.
                                </Typography>
                            </>
                        )}

                    </Typography>
                    { passed && verified_until && (
                        <StyledVerifiedInfo>
                            <CheckCircle /> Verified until {verified_until.toLocaleString()}.
                        </StyledVerifiedInfo>
                    )}
                    { !passed && verified_until && (
                        <StyledUnverifiedInfo>
                            <Warning /> Verification expired {verified_until.toLocaleString()}.
                        </StyledUnverifiedInfo>
                    )}
                </CardContent>
            </StyledVerificationCard>
        </Popover>
    </>;


    return (
        <StyledTableRow>
            <TableCell component="th" scope="row">
                <StyledTypographyCode variant="h3">{domain}</StyledTypographyCode>
            </TableCell>
            <TableCell>
                {verification}
            </TableCell>
            <TableCell align="right">
                <IconButton size="small" onClick={handleOpen}>
                    <Delete fontSize="small" />
                </IconButton>
                <TargetDomainDeleteDialog name={targetDomain.domain} open={open} closeDialog={handleClose} postAction={postDeleteAction} />
            </TableCell>
        </StyledTableRow>
    )
}