import { Typography } from "@mui/material";
import React from "react";
import {IssuesByDomainDownloadReportBtn} from "./IssuesByDomainDownloadReportBtn";
import { styled } from '@mui/material/styles';

export type CountBySeverity = {
    critical: number;
    high: number;
    medium: number;
    low: number;
};

export type IssuesByDomainListItemProps = {
    domain: string;
    domain_id: number;
    maxCount: number;
    counts: CountBySeverity;
};

export const DEFAULT_LIST_ITEM: IssuesByDomainListItemProps = {
    domain: "",
    domain_id: 0,
    maxCount: 0,
    counts: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
    },
};


const StyledRoot = styled('div')({
    width: "100%",
    height: "100%",
})

const StyledLabels = styled('div')({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
})

const StyledBar = styled('div')(({ theme }) => ({
    position: "relative",
    "& > div": {
        height: 6,
        borderRadius: 3,
        display: "inline-block",
        position: "absolute",
        "&.critical": {
            background: theme.palette.severity.critical.main,
        },
        "&.high": {
            background: theme.palette.severity.high.main,
        },
        "&.medium": {
            background: theme.palette.severity.medium.main,
        },
        "&.low": {
            background: theme.palette.severity.low.main,
        },
    },
}))

export const IssuesByDomainListItem: React.FC<IssuesByDomainListItemProps> = ({
    domain,
    domain_id,
    maxCount,
    counts,
}) => {
    const totalCount = Object.values(counts).reduce((prev, count) => {
        return prev + count;
    }, 0);

    return (
        <StyledRoot>
            <StyledLabels>
                <div style={{ display: "inline-flex", alignItems: "center", width: "70%"}}>
                    <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                        {domain}
                    </Typography>
                    <IssuesByDomainDownloadReportBtn domain={domain} domain_id={domain_id}/>
                </div>
                <Typography variant="h4" style={{ fontWeight: 700 }}>
                    {totalCount} issue{totalCount === 1 ? "" : "s"}
                </Typography>
            </StyledLabels>
            <StyledBar>
                <div
                    className="low"
                    style={{
                        width: `${
                            ((counts.low +
                                counts.medium +
                                counts.high +
                                counts.critical) /
                                maxCount) *
                            100
                        }%`,
                    }}
                ></div>
                <div
                    className="medium"
                    style={{
                        width: `${
                            ((counts.medium + counts.high + counts.critical) /
                                maxCount) *
                            100
                        }%`,
                    }}
                ></div>
                <div
                    className="high"
                    style={{
                        width: `${
                            ((counts.high + counts.critical) / maxCount) * 100
                        }%`,
                    }}
                ></div>
                <div
                    className="critical"
                    style={{ width: `${(counts.critical / maxCount) * 100}%` }}
                ></div>
            </StyledBar>
        </StyledRoot>
    );
};
