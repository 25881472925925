import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ChildrenProps, GroupByData, RefProps } from "./IssueFilterBy";
import { styled, useTheme } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    width: "100%",
}));

const StyledFormControlLabel = styled(FormControlLabel)({
    margin: "0 -0.25rem",
    "& > span": {
        fontSize: "0.875rem",
        padding: "0.375rem 0.25rem",
    },
})

export const IssueFilterByCategoryGroup = forwardRef<RefProps, ChildrenProps>(
    function IssueFilterBase(props: ChildrenProps, ref) {
        const theme = useTheme();
        const groupByData: GroupByData = {};
        const [state, setState] = useState<string[]>([]);

        const { data } = props;

        data.forEach((record) => {
            if (Object.keys(groupByData).indexOf(record.category) === -1) {
                groupByData[record.category] = [];
            }
            groupByData[record.category].push(record);
        });

        useImperativeHandle(ref, () => ({
            getQuery() {
                if (state.length > 0) {
                    const query = `category=in.("${state.join('","')}")`;
                    return query;
                }
                return "";
            },
            clear() {
                setState([]);
            },
        }));

        const handleOnChange = (category: string) => {
            if (state.indexOf(category) === -1) {
                const newState = [...state];
                newState.push(category);
                setState(newState);
            } else {
                const index = state.indexOf(category);
                const newState = [...state];
                newState.splice(index, 1);
                setState(newState);
            }
        };

        const sortGroupedData = (groupData: GroupByData) => {
            return Object.keys(groupData).sort().reduce(
                (obj: GroupByData, key: string) => {
                    obj[key] = groupData[key]; 
                    return obj;
                },
                {}
            )
        }

        return (
            <StyledFormControl variant="standard">
                <FormLabel component="legend" style={{ marginBottom: theme.spacing(2) }}>
                    Category
                </FormLabel>
                <FormGroup>
                    {Object.keys(sortGroupedData(groupByData)).map((category, index) => (
                        <StyledFormControlLabel
                            key={index}
                            label={`${category} (${
                                groupByData[category].length
                            } issue${
                                groupByData[category].length === 1 ? "" : "s"
                            })`}
                            control={
                                <Checkbox
                                    checked={state.indexOf(category) !== -1}
                                    onChange={() => handleOnChange(category)}
                                    name={category}
                                    size="small"
                                />
                            }
                        />
                    ))}
                </FormGroup>
            </StyledFormControl>
        );
    }
);
