import React from "react";
import List from "@mui/material/List";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Group, Home } from "@mui/icons-material";
import BugReportIcon from "@mui/icons-material/BugReport";
import { NavListSubMenu } from "./NavListSubMenu";
import { NavListItem } from "./NavListItem";

export type MenuItem = {
    name: string;
    link?: string;
    icon?: JSX.Element;
    submenu?: MenuItem[];
};

const threatIntelligenceSubMenu: MenuItem[] = [
    { name: "Interactive map", link: "/threat-exposure" },
    { name: "Attack Surface Exposure", link: "/attack-surface-exposure" },
    { name: "Digital Supply Chain", link: "/digital-supply-chain" },
    {
        name: "Social Threat Intelligence",
        link: "/social-threat-intelligence",
    },
];

const menuItems: MenuItem[] = [
    { name: "Dashboard", link: "/dashboard", icon: <Home /> },
    {
        name: "Threat Intelligence",
        icon: <AssessmentIcon />,
        submenu: threatIntelligenceSubMenu,
    },
    {
        name: "Target domains",
        link: "/target-domains",
        icon: <TrackChangesIcon />,
    },
    { name: "Users", link: "/users", icon: <Group /> },
    { name: "Issues", link: "/issues", icon: <BugReportIcon /> },
    { name: "Billing", link: "/plans", icon: <AccountBalanceWalletIcon /> },
];

export const Nav = (): JSX.Element => {
    return (
        <List>
            {menuItems.map((item) =>
                item.submenu ? (
                    <NavListSubMenu item={item} key={item.name} />
                ) : (
                    <NavListItem item={item} key={item.name} />
                )
            )}
        </List>
    );
};
