import React from "react";
import { ServiceProvidersWithApi } from "./ServiceProvidersWithApi";
import { Tabs } from "src/elements/Tabs/Tabs";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    minHeight: "100%",
    padding: theme.spacing(2),
    background: theme.palette.background.default,
}))

export const DigitalSupplyChainReport: React.FC = () => {
    const children: JSX.Element[] = [
        <ServiceProvidersWithApi key="service-provider"/>
    ];

    return (
        <StyledRoot>
            <Tabs tabs={["Service Provider"]}>
                {children}
            </Tabs>
        </StyledRoot>
    );
};
