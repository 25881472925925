import React from "react";
import { Button } from "@mui/material";
import { WhatsNewRecord } from "./whats-new";
import { WhatsNewCarousel } from "./WhatsNewCarousel";
import { WhatsNewLoading } from "./WhatsNewLoading";
import { RequestStatus } from "../../../../src/shared/enums";
import { styled } from '@mui/material/styles';

type Props = {
  status: number;
  data: WhatsNewRecord[]; 
  getData: () => void; 
}

const StyledWhatNew = styled('div')(({ theme }) => ({
    maxWidth: "350px",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '220px'
}))

export const WhatsNew: React.FC<Props> = ({
  status,
  data,
  getData
}) => {
  let content;

  if (status === RequestStatus.Pending) {
    content = (
      <WhatsNewLoading />
    );
  } else if (status === RequestStatus.Success) {
    content = (
      <WhatsNewCarousel
        whatsNewRecords={data}
      />
    );
  } else if (status === RequestStatus.Error) {
    content = (
        <Button onClick={getData}>
          Refresh
        </Button>
    );
  }
  return (
    <StyledWhatNew>
      {content}
    </StyledWhatNew>
  );
};
