import { debounce, Drawer, IconButton } from "@mui/material";
import { keys } from "fp-ts/lib/ReadonlyRecord";
import React, {useEffect, useState} from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs } from "src/elements/Tabs/Tabs";
import { IssueFilterBy } from "src/features/issue-summary/IssueFilterBy/IssueFilterBy";
import { IssueSearchInput } from "src/features/issue-summary/IssueSearchInput/IssueSearchInput";
import { IssueTable, Issue, Ordering } from "src/features/issue-summary/IssueTable/IssueTable";
import { tables } from "src/services/postgrest-provider/tables";
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    maxHeight: "100%",
}))

const StyledSearchInputWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
    height: "50px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down('lg')]: {
        display: "block"
    },
}))

const StyledTable = styled('div')(({ theme }) => ({
    height: "100%",
    flex: "1 0 70%",
    "&>div": {
        flex: 1,
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    [theme.breakpoints.down('lg')]: {
        flex: "1 0 100%",
    }
}));

const StyledTabs = styled(Tabs)({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    alignItems: "stretch",
    justifyContent: "center",
})

const StyledFilters = styled('div')(({ theme }) => ({
    flex: "1 0 30%",
    [theme.breakpoints.down('lg')]: {
        display: "none"
    },
}))


const StyledFilterDrawer = styled(Drawer)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down('lg')]: {
        display: "block"
    },
    "& > *": {
        width: "50%",
    }
}))

export const IssueTablePage: React.FC = () => {
    const [search, setSearch] = useSearchParams();
    const [searchText, setSearchText] = useState<string>();
    const [filterQuery, setFilterQuery] = useState<string>();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const sort = checkSortParam(search.get("sort"));
    const order = checkOrderParam(search.get("order"));

    const changeSort = (ordering: Ordering) => setSearch({
        sort: ordering.column,
        order: ordering.direction,
    });

    const onSearch = (text: string) => {
        setSearchText(text.toLowerCase());
    }



    const toggleDrawer = (open: boolean) => {
        setDrawerOpen(open);
    };


    useEffect(() => {
        // Close drawer when resizing page
        window.addEventListener("resize", debounce(() => toggleDrawer(false), 300));
    }, [])

    return (
        <StyledRoot>
            <StyledSearchInputWrapper>
                <IssueSearchInput searchData={onSearch} />
                <StyledIconButton
                    aria-label="Filters"
                    color="primary"
                    onClick={() => toggleDrawer(true)}
                    size="large">
                    <FilterListIcon />
                </StyledIconButton>
            </StyledSearchInputWrapper>
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
                <StyledTable>
                    <StyledTabs
                        tabs={["All Task", "Opening", "Closed"]}
                    >
                        <IssueTable
                            state="all"
                            sort={{ column: sort, direction: order }}
                            onChangeSort={changeSort}
                            searchText={searchText}
                        filterQuery={filterQuery}
                        />
                    <IssueTable
                        state="open"
                        sort={{ column: sort, direction: order }}
                        onChangeSort={changeSort}
                        searchText={searchText}
                        filterQuery={filterQuery}
                    />
                    <IssueTable
                        state="closed"
                        sort={{ column: sort, direction: order }}
                        onChangeSort={changeSort}
                        searchText={searchText}
                        filterQuery={filterQuery}
                    />
                    </StyledTabs>
                </StyledTable>
            <StyledFilters>
                <IssueFilterBy
                    searchText={searchText}
                    setFilterQuery={setFilterQuery}
                />
            </StyledFilters>
            </div>
            <StyledFilterDrawer
                anchor="right"
                open={drawerOpen}
                onClose={() => toggleDrawer(false)}
            >
                <IssueFilterBy
                    searchText={searchText}
                    setFilterQuery={setFilterQuery}
                    toggleDrawer={toggleDrawer}
                />
            </StyledFilterDrawer>
        </StyledRoot>
    );
};

const checkSortParam = (s: string | null): keyof Issue => {
    const key = keys(tables.issues.props).find(key => key === s);
    return key ?? "id";
}

const checkOrderParam = (s: string | null): "asc" | "desc" => {
    return s === "desc" ? "desc" : "asc";
}
