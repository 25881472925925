import React from "react";
import {IssueHistory} from "./IssueHistory";
import {IssueHistoryWidgetProps} from "./const";


export const ClosedIssuesWidget: React.FC<IssueHistoryWidgetProps> = ({
    total,
    difference,
    numberOfDays,
    backgroundColor,
    dataSet,
}) => {
    const args = {
        title: ["Closed issues", "on average"],
        subtitle: `in ${numberOfDays} day${numberOfDays === 1 ? "" : "s"}`,
        total: total,
        difference: difference,
        differenceDescription: ["Compare with", `last ${numberOfDays} day${numberOfDays === 1 ? "" : "s"}`],
        backgroundColor: backgroundColor,
        differenceWithColor: true,
        showBaseline: false,
        dataSet: dataSet
    }
    return (
        <IssueHistory {...args}></IssueHistory>
    )
}


