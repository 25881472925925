import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ChildrenProps, GroupByData, RefProps } from "./IssueFilterBy";
import { styled, useTheme } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    width: "100%",
}));

const StyledFormControlLabel = styled(FormControlLabel)({
    margin: "0 -0.25rem",
    "& > span": {
        fontSize: "0.875rem",
        padding: "0.375rem 0.25rem",
    },
})

export const IssueFilterByDomainGroup = forwardRef<RefProps, ChildrenProps>(
    function IssueFilterBase(props: ChildrenProps, ref) {
        const theme = useTheme();
        const groupByData: GroupByData = {};
        const [state, setState] = useState<string[]>([]);

        const { data } = props;

        data.forEach((record) => {
            if (Object.keys(groupByData).indexOf(record.target_domain) === -1) {
                groupByData[record.target_domain] = [];
            }
            groupByData[record.target_domain].push(record);
        });

        useImperativeHandle(ref, () => ({
            getQuery() {
                if (state.length > 0) {
                    const query = `target_domain=in.("${state.join('","')}")`;
                    return query;
                }
                return "";
            },
            clear() {
                setState([]);
            },
        }));

        const handleOnChange = (domain: string) => {
            if (state.indexOf(domain) === -1) {
                const newState = [...state];
                newState.push(domain);
                setState(newState);
            } else {
                const index = state.indexOf(domain);
                const newState = [...state];
                newState.splice(index, 1);
                setState(newState);
            }
        };

        const sortGroupedData = (groupData: GroupByData) => {
            return Object.keys(groupData).sort().reduce(
                (obj: GroupByData, key: string) => {
                    obj[key] = groupData[key]; 
                    return obj;
                },
                {}
            )
        }

        return (
            <StyledFormControl variant="standard">
                <FormLabel component="legend" style={{ marginBottom: theme.spacing(2) }}>
                    Domain
                </FormLabel>
                <FormGroup>
                    {Object.keys(sortGroupedData(groupByData)).map((domain, index) => (
                        <StyledFormControlLabel
                            key={index}
                            label={`${domain} (${
                                groupByData[domain].length
                            } issue${
                                groupByData[domain].length === 1 ? "" : "s"
                            })`}
                            control={
                                <Checkbox
                                    checked={state.indexOf(domain) !== -1}
                                    onChange={() => handleOnChange(domain)}
                                    name={domain}
                                    size="small"
                                />
                            }
                        />
                    ))}
                </FormGroup>
            </StyledFormControl>
        );
    }
);
