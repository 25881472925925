import React from "react";
import { NavLink as RouterNavLink, NavLinkProps } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';

const StyledRouterNavLink = styled(RouterNavLink)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
    textDecoration: "none",
}))

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(function NavLink(props: NavLinkProps, ref) {
    const theme = useTheme();

    return (
        <StyledRouterNavLink
            ref={ref} 
            {...props}
            style={({ isActive }: {isActive: boolean}) => isActive ? {
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
                borderColor: theme.palette.primary.main,
                fontSize: "1.2rem",
            }: undefined}
        />
    );
});