import React from "react";
import {
    TimelineConnector,
    TimelineConnectorProps,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineItemProps,
    TimelineSeparator,
    TimelineSeparatorProps,
} from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { WelcomeStepIndex } from "src/shared/enums";
import { styled } from '@mui/material/styles';

type Props = {
    step: number;
    skipInvite: () => void;
};

const StyledTimelineContent = styled(TimelineContent)({
    minWidth: "100%",
    maxWidth: "100%",
});

const StyledTableData = styled('td')({
    height: "100%",
    verticalAlign: "top",
});

type StyledTimelineConnectorProps = TimelineConnectorProps & {
    isActiveStep: boolean;
    isCompletedStep: boolean;
}

const StyledTimelineConnector = styled(TimelineConnector, {
    shouldForwardProp: (prop) => prop !== 'isActiveStep' && prop !== 'isCompletedStep'
})<StyledTimelineConnectorProps>(({ isActiveStep, isCompletedStep, theme}) => ({
    ...((isActiveStep || isCompletedStep) && { backgroundColor: theme.palette.primary.dark })
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
    width: theme.spacing(2),
    height: theme.spacing(2),
}))

type StyledTimelineSeparatorProps = TimelineSeparatorProps & {
    isCompletedStep: boolean;
}

const StyledTimelineSeparator = styled(TimelineSeparator, {
    shouldForwardProp: (prop) => prop !== 'isCompletedStep'
})<StyledTimelineSeparatorProps>(({ isCompletedStep, theme }) => ({
    ...(!isCompletedStep && ({
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1.3),
    }))
}));

type StyledInactiveTimelineItemProps = TimelineItemProps & {
    isActiveStep: boolean;
}

const StyledTimelineItem = styled(TimelineItem, {
    shouldForwardProp: (prop) => prop !== 'isActiveStep',
})<StyledInactiveTimelineItemProps>(({ isActiveStep, theme }) => ({
    ...(!isActiveStep && { color: theme.palette.text.disabled })
}))

const StyledDescription = styled(Typography)(({ theme }) => ({
    margin: `${theme.spacing(0.8)} 0 ${theme.spacing(1.5)} 0`,
    fontSize: "0.9rem",
}));


type StyledTableRowProps = React.TableHTMLAttributes<HTMLTableRowElement> & {
    isCompletedStep: boolean;
}
const StyledTableRow = styled('tr', {
    shouldForwardProp: (prop) => prop !== 'isCompletedStep'
})<StyledTableRowProps>(({ isCompletedStep }) => ({
    ...(isCompletedStep && { display: 'none' })
}))

type StyledButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    isActiveStep: boolean;
}

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isActiveStep'
})<StyledButtonProps>(({ isActiveStep, theme }) => ({
    marginBottom: theme.spacing(1.5),
    ...(!isActiveStep && { display: 'none' })
}))

export const WelcomeInviteUsers: React.FC<Props> = ({ step, skipInvite }) => {
    const navigate = useNavigate();

    const isActiveStep = step === WelcomeStepIndex.InviteUsers;
    const isCompletedStep = step > WelcomeStepIndex.InviteUsers;

    const sendInvitationAction = () => {
        skipInvite();
        navigate("/users");
    };

    return (
        <StyledTimelineItem isActiveStep={isActiveStep}>
            <StyledTimelineSeparator isCompletedStep={isCompletedStep}>
                <TimelineDot
                    color={isActiveStep || isCompletedStep ? "primary" : "grey"}
                    variant={
                        isActiveStep || isCompletedStep ? "filled" : "outlined"
                    }
                >
                    {isCompletedStep && (
                        <StyledCheckIcon />
                    )}
                </TimelineDot>
                <StyledTimelineConnector isActiveStep={isActiveStep} isCompletedStep={isCompletedStep} />
            </StyledTimelineSeparator>
            <StyledTimelineContent>
                <table>
                    <tbody>
                        <tr>
                            <StyledTableData>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">3.</Box>
                                </Typography>
                            </StyledTableData>
                            <StyledTableData>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    display="inline"
                                >
                                    <Box
                                        fontWeight="fontWeightBold"
                                        display="inline"
                                    >
                                        Invite others
                                    </Box>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    display="inline"
                                >
                                    <Box
                                        fontWeight="fontWeightRegular"
                                        display="inline"
                                    >
                                        {" "}
                                        * optional
                                    </Box>
                                </Typography>
                            </StyledTableData>
                        </tr>
                        <StyledTableRow isCompletedStep={isCompletedStep}>
                            <td></td>
                            <td>
                                <StyledDescription>
                                    Invite more people to manage and review the
                                    threat reports.
                                </StyledDescription>
                                <StyledButton
                                    isActiveStep={isActiveStep}
                                    variant="contained"
                                    color="primary"
                                    onClick={sendInvitationAction}
                                >
                                    Send The Invitation
                                </StyledButton>
                                <StyledButton
                                    isActiveStep={isActiveStep}
                                    color="primary"
                                    onClick={skipInvite}
                                >
                                    Skip Now
                                </StyledButton>
                            </td>
                        </StyledTableRow>
                    </tbody>
                </table>
            </StyledTimelineContent>
        </StyledTimelineItem>
    );
};
