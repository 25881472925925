export type Severity = "low" | "medium" | "high" | "critical";

export const SEVERITY_LOW_MIN = 1;
export const SEVERITY_LOW_MAX = 3;
export const SEVERITY_MEDIUM_MIN = SEVERITY_LOW_MAX + 1;
export const SEVERITY_MEDIUM_MAX = 6;
export const SEVERITY_HIGH_MIN = SEVERITY_MEDIUM_MAX + 1;
export const SEVERITY_HIGH_MAX = 8;
export const SEVERITY_CRITICAL = 9;


export const getSeverityLevel = (severity: number): Severity => {
    if (severity <= SEVERITY_LOW_MAX) {
        return "low";
    } else if (severity <= SEVERITY_MEDIUM_MAX) {
        return "medium";
    } else if (severity <= SEVERITY_HIGH_MAX) {
        return "high";
    } else {
        return "critical";
    }
}


export const getSeverityRange = (severityLevel: Severity): number[] => {
    if (severityLevel === "low") {
        return generateSeverityArray(SEVERITY_LOW_MIN, SEVERITY_LOW_MAX);
    } else if (severityLevel === "medium") {
        return generateSeverityArray(SEVERITY_MEDIUM_MIN, SEVERITY_MEDIUM_MAX);
    } else if (severityLevel === "high") {
        return generateSeverityArray(SEVERITY_HIGH_MIN, SEVERITY_HIGH_MAX);
    } else {
        return [SEVERITY_CRITICAL];
    }
}

const generateSeverityArray = (min: number, max: number): number[] => {
    return [...Array(max - min + 1).keys()].map((i) => i + min) ;
}
