import React from "react";
import {
    TimelineConnector,
    TimelineConnectorProps,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineItemProps,
    TimelineSeparator,
    TimelineSeparatorProps,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { DomainStat } from "./Welcome";
import { WelcomeStepIndex } from "src/shared/enums";
import { styled } from '@mui/material/styles';

type Props = {
    step: number;
    domainStat: DomainStat;
};

type StyledInactiveTimelineItemProps = TimelineItemProps & {
    isActiveStep: boolean;
}

const StyledTimelineItem = styled(TimelineItem, {
    shouldForwardProp: (prop) => prop !== 'isActiveStep',
})<StyledInactiveTimelineItemProps>(({ isActiveStep, theme }) => ({
    ...(!isActiveStep && { color: theme.palette.text.disabled })
}))

type StyledTimelineSeparatorProps = TimelineSeparatorProps & {
    isCompletedStep: boolean;
}

const StyledTimelineSeparator = styled(TimelineSeparator, {
    shouldForwardProp: (prop) => prop !== 'isCompletedStep'
})<StyledTimelineSeparatorProps>(({ isCompletedStep, theme }) => ({
    ...(!isCompletedStep && ({
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1.3),
    }))
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
    width: theme.spacing(2),
    height: theme.spacing(2),
}))

type StyledTimelineConnectorProps = TimelineConnectorProps & {
    isActiveStep: boolean;
    isCompletedStep: boolean;
}

const StyledTimelineConnector = styled(TimelineConnector, {
    shouldForwardProp: (prop) => prop !== 'isActiveStep' && prop !== 'isCompletedStep'
})<StyledTimelineConnectorProps>(({ isActiveStep, isCompletedStep, theme}) => ({
    ...((isActiveStep || isCompletedStep) && { backgroundColor: theme.palette.primary.dark })
}));

const StyledTimelineContent = styled(TimelineContent)({
    minWidth: "100%",
    maxWidth: "100%",
});

const StyledTableData = styled('td')({
    height: "100%",
    verticalAlign: "top",
});


type StyledTableRowProps = React.TableHTMLAttributes<HTMLTableRowElement> & {
    isCompletedStep: boolean;
}
const StyledTableRow = styled('tr', {
    shouldForwardProp: (prop) => prop !== 'isCompletedStep'
})<StyledTableRowProps>(({ isCompletedStep }) => ({
    ...(isCompletedStep && { display: 'none' })
}))

const StyledDescription = styled(Typography)(({ theme }) => ({
    margin: `${theme.spacing(0.8)} 0 ${theme.spacing(1.5)} 0`,
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    width: "90%",
    fontSize: "0.9rem",
}));

export const WelcomeVerifyDomain: React.FC<Props> = ({ step, domainStat }) => {
    const isActiveStep = step === WelcomeStepIndex.VerifyDomain;
    const isCompletedStep = step > WelcomeStepIndex.VerifyDomain;

    return (
        <StyledTimelineItem isActiveStep={isActiveStep}>
            <StyledTimelineSeparator isCompletedStep={isCompletedStep}>
                <TimelineDot
                    color={isActiveStep || isCompletedStep ? "primary" : "grey"}
                    variant={
                        isActiveStep || isCompletedStep ? "filled" : "outlined"
                    }
                >
                    {isCompletedStep && (
                        <StyledCheckIcon />
                    )}
                </TimelineDot>
                <StyledTimelineConnector isActiveStep={isActiveStep} isCompletedStep={isCompletedStep} />
            </StyledTimelineSeparator>
            <StyledTimelineContent>
                <table>
                    <tbody>
                        <tr>
                            <StyledTableData>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">2.</Box>
                                </Typography>
                            </StyledTableData>
                            <StyledTableData>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">
                                        Verify domain ownership (
                                        {domainStat.verified}/{domainStat.total}
                                        )
                                    </Box>
                                </Typography>
                            </StyledTableData>
                        </tr>
                        <StyledTableRow isCompletedStep={isCompletedStep}>
                            <td></td>
                            <td>
                                <StyledDescription>
                                    Add TXT record to the target domain. Once*
                                    it&lsquo;s published, AttackBound will start
                                    daily analyses on overall threat exposure
                                    for your organization.
                                </StyledDescription>
                                <StyledDescription>
                                    *TXT Records are applied immediately, but{" "}
                                    <Box
                                        component="span"
                                        fontWeight={
                                            isActiveStep
                                                ? "fontWeightBold"
                                                : "fontWeightRegular"
                                        }
                                        display="inline"
                                    >
                                        it may take up to 2 hours
                                    </Box>{" "}
                                    to propagate across the internet.
                                </StyledDescription>
                            </td>
                        </StyledTableRow>
                    </tbody>
                </table>
            </StyledTimelineContent>
        </StyledTimelineItem>
    );
};
