import * as t from "io-ts";
import { PaymentMethodCodec } from "./PaymentMethod";
import { SourceCodec } from "./Source";

// https://stripe.com/docs/api/customers/object
export const CustomerCodec =  t.interface({
    default_source: t.union([t.null, SourceCodec]),
    invoice_settings: t.interface({
        default_payment_method: t.union([t.null, PaymentMethodCodec]),
    }),
});

export type Customer = t.TypeOf<typeof CustomerCodec>;