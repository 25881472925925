import React from "react";
import { Subscription } from "src/misc/codecs/stripe/Subscription";
import { capitalize } from "src/misc/format/capitalize";
import { Price } from "src/elements/Price/Price";
import { styled } from '@mui/material/styles';

type Props = {
    subscription: Subscription
}

const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    display: "flex",
    "& > * + *": {
        marginLeft: theme.spacing(3)
    },
}));

const StyledValue = styled('span')(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: ".8rem",
}))

type Interval = Subscription["plan"]["interval"];

const describeInterval = (interval: Interval, count: number): string => {
    if (count !== 1) {
        return `Every ${count} ${capitalize(interval)}s`;
    }
    switch (interval) {
    case "day":
        return "Daily";
    case "week":
        return "Weekly";
    case "month":
        return "Monthly";
    case "year":
        return "Yearly";
    }
}

export const PaymentSchedule: React.FC<Props> = ({ subscription }) => {
    // Next invoice is generated and charged when the current period ends.
    const paymentDue = subscription.current_period_end;

    const frequency = describeInterval(subscription.plan.interval, subscription.plan.interval_count);

    return (
        <StyledRoot>
            <div>
                <span>Frequency</span>
                <br/>
                <StyledValue>{frequency}</StyledValue>
            </div>
            <div>
                <span>Invoice Date</span>
                <br/>
                <StyledValue>{paymentDue.toLocaleDateString()}</StyledValue>
            </div>
            <div>
                <span>Invoice Amount</span>
                <br/>
                <StyledValue>
                    <Price amount={subscription.latest_invoice.amount_due} currency={subscription.latest_invoice.currency}/>
                </StyledValue>
            </div>
        </StyledRoot>
    );
}

