import React, { useCallback, useEffect, useRef, useState } from "react";
import { IssueHistoryChartData } from "src/features/issue-history/IssueHistory";
import { Baseline } from "./Baseline";
import { Size } from "./const";
import { Label } from "./Label";
import { Legends } from "./Legends";
import { Line } from "./Line";
import { DASHARRAY, debounce, findMaxValue, generateLabelData, getCoordinates, getPoints, PADDING } from "./utils";


/** @typedef {object} LineChartProps React props for `<LineChart />` component*/
export type LineChartProps = {
    /**
     * Width of <svg>
     */
    width: number | string;
    /**
     * Height of <svg>
     */
    height: number | string;
    /**
     * Background color of <svg>
     * 
     * Default: "#C4C4C4"
     */
    backgroundColor?: string;
    /**
     * Text color of <svg>
     * 
     * Default: "#C4C4C4"
     */
    textColor?: string;
    /**
     * Dataset for rending <svg>
     */
    dataSet: IssueHistoryChartData[];
    /**
     * Determine the visibility of the baseline
     */
    showBaseline?: boolean;
    /**
     * Other props which passed to svg
     */
    [x:string]: unknown;
}

/**
 * Line chart widget
 *
 * @param {LineChartProps} {
 *     width,
 *     height,
 *     backgroundColor = "#C4C4C4",
 *     textColor = "#C4C4C4",
 *     dataSet,
 *     showBaseline = true,
 *     ...props
 * }
 * @return {JSX.Element} 
 */
export const LineChart: React.FC<LineChartProps> = ({
    width,
    height,
    backgroundColor = "#C4C4C4",
    textColor = "#C4C4C4",
    dataSet,
    showBaseline = true,
    ...props
}) => {
    const [svgSize, setSvgSize] = useState<Size>({ width: 0, height: 0 });
    const [xGap, setXGap] = useState<number>(0);
    const svgRef = useRef<SVGSVGElement>(null);

    const max = findMaxValue(dataSet);

    const updateXGap = useCallback(() => {
        if (svgRef.current) {
            if (dataSet.length > 0) {
                if (dataSet[0].values.length === 1) {
                    setXGap(0);
                } else {
                    setXGap(
                        (svgRef.current?.width.baseVal.value - 2 * PADDING) /
                            (dataSet[0].values.length - 1)
                    );
                }
            }
        }
    }, [dataSet]);

    const handleResize = useCallback(() => {
        if (svgRef?.current) {
            setSvgSize({
                width: svgRef.current.clientWidth,
                height: svgRef.current.clientHeight,
            });
            updateXGap();
        }
    }, [updateXGap]);

    useEffect(() => {
        if (svgRef?.current) {
            setSvgSize({
                width: svgRef.current.clientWidth,
                height: svgRef.current.clientHeight,
            });
            updateXGap();

            window.addEventListener("resize", debounce(handleResize, 300));

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }
    }, [dataSet, svgRef, handleResize, updateXGap]);


    return (
        <div
            style={{
                width: width,
                height: height,
                minWidth: "400px",
                minHeight: height,
            }}
            {...props}
        >
            <svg
                width={width}
                height={height}
                ref={svgRef}
            >
                {dataSet.map((data, index) => {
                    if (!data.display) return;

                    const coordinates = getCoordinates(
                        data.values,
                        max,
                        svgSize,
                        xGap
                    );
                    const points = getPoints(coordinates);
                    const labelData = generateLabelData(coordinates, data.values);
                    return (
                        <g key={index}>
                            <Line
                                stroke={data.stroke.color}
                                strokeDasharray={
                                    data.stroke.style === "dot" ? DASHARRAY : 0
                                }
                                strokeWidth={data.stroke.width}
                                points={points}
                            />
                            {data.showValue && (
                                <Label
                                    stroke={data.stroke?.color}
                                    circleFill={backgroundColor}
                                    textFill={textColor}
                                    data={labelData}
                                />
                            )}
                        </g>
                    );
                })}
                <Baseline width={svgSize.width} height={svgSize.height} color={textColor} show={showBaseline} />
            </svg>
            <Legends dataSet={dataSet} />
        </div>
    );
}