import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import { CircularProgress, Grid, Link, Typography } from "@mui/material";
import { PlanTable } from "./PlanTable/PlanTable";
import { InvoiceList } from "./InvoiceList/InvoiceList";
import { useApi, parseData } from "../../services/api-provider";
import * as t from "io-ts";
import { SubscriptionCodec, Subscription } from "src/misc/codecs/stripe/Subscription";
import Splash from "../splash/Splash";
import { PaymentSchedule } from "./PaymentSchedule";
import { LinkButton } from "src/elements/LinkButton/LinkButton";
import { Error, Info, Warning } from "@mui/icons-material";
import { Notice } from "../notice-banner/Notice";
import { Colour, useNotices } from "src/services/notice-provider/NoticeProvider";
import { stripe_publishable_key } from "src/config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { paymentHandler } from "./paymentHandler";
import { PaymentMethodDialog } from "./PaymentMethod/PaymentMethodDialog";
import { CancelPlan } from "./CancelPlan";
import { PaymentMethod } from "src/misc/codecs/stripe/PaymentMethod";
import { Source } from "src/misc/codecs/stripe/Source";
import { Card } from "./Card/Card";
import { styled } from '@mui/material/styles';

const stripe = loadStripe(stripe_publishable_key);

const StyledRoot = styled(Grid)(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(2),
    "& h2": {
        marginBottom: theme.spacing(1),
    },
}))

const StyledPaymentMethod = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(2),
}))

type State = { key: "loading" }
           | { key: "error", error: string }
           | { key: "ok", subscription: Subscription | null }
           | { key: "reloading", subscription: Subscription | null };

export const Payment: React.FC = () => {
    const api = useApi();
    const [state, setState] = useState<State>({ key: "loading" });

    const getSubscription = useCallback(() => {
        setState(state => state.key === "ok" ? { ...state, key: "reloading" } : { key: "loading" });
        api.Get("/api/stripe/subscription").then(parseData(t.union([SubscriptionCodec, t.null]))).then((res) => {
            if (res.type === 'success') {
                setState({ key: "ok", subscription: res.data });
            } else {
                setState({ key: "error", error: res.message });
            }
        });
    }, [api]);

    useEffect(() => getSubscription(), [getSubscription]);

    switch (state.key) {
    case "loading":
        return (
            <Splash>
                <CircularProgress />
                <Typography>Loading Plans</Typography>
            </Splash>
        );
    case "error":
        return (
            <Splash>
                <Typography>There was an error fetching plan details.</Typography>
                <LinkButton onClick={() => getSubscription()}>Try again.</LinkButton>
            </Splash>
        );
    case "reloading":
    case "ok":
        return (
            <Elements stripe={stripe}>
                <PaymentOK
                    subscription={state.subscription}
                    updateSubscription={subscription => setState({ key: "ok", subscription })}
                />
            </Elements>
        );
    }
}

type PaymentOKProps = {
    subscription: Subscription | null;
    updateSubscription: (updated: Subscription | null) => void;
}

const PaymentOK: FC<PaymentOKProps> = ({ subscription, updateSubscription }) => {
    const api = useApi();
    const stripe = useStripe();
    const [open, setOpen] = useState(false);
    const [priceId, setPriceId] = useState<string | undefined>(undefined);

     // Show a notice if the subscription is in a bad state.
     const notice = useMemo(() => makeNotice(subscription, () => setOpen(true)), [subscription]);
     const { add } = useNotices();
     useEffect(() => {
        if (notice === null) return;
        return add(notice.colour, notice.content);
    }, [notice, add]);

    const checkout = (priceId: string) => {
        setPriceId(priceId);
        setOpen(true);
    }

    const handlePayment = async (cardID: string) => {
        const result = await paymentHandler(subscription, priceId, cardID, stripe, api);
        if (result.updated !== undefined) {
            updateSubscription(result.updated);
        }
        return result;
    };

    const paymentMethod: PaymentMethod | Source | null =
        subscription?.default_payment_method ||
        subscription?.customer.invoice_settings.default_payment_method ||
        subscription?.customer.default_source ||
        null;

    return (
        <>
            <StyledRoot container spacing={3}>
                <Grid item container xs={12}>
                    <PlanTable checkout={checkout} activePlan={subscription?.plan.id} />
                </Grid>
                { subscription && (
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h6" component="h2">
                            Payment Method
                        </Typography>
                        <StyledPaymentMethod>
                            {paymentMethod && (
                                <Card method={paymentMethod} />
                            )}
                            <LinkButton onClick={() => setOpen(true)}>
                                {updatePaymentMethodText(subscription, paymentMethod)}
                            </LinkButton>
                        </StyledPaymentMethod>
                    </Grid>
                )}
                { subscription && !subscription.cancel_at_period_end && (
                    <Grid item container xs={12} lg={6}>
                        <Typography variant="h6" component="h2">
                            Upcoming Invoice
                        </Typography>
                        { subscription && <PaymentSchedule subscription={subscription}/> }
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="h6" component="h2">
                        Billing History
                    </Typography>
                    <InvoiceList subscription={subscription} />
                </Grid>
                <Grid item xs={12}>
                    <CancelPlan
                        update={updated => updateSubscription(updated)}
                        subscription={subscription}
                    />
                </Grid>
                <PaymentMethodDialog
                    open={open}
                    handleClose={() => setOpen(false)}
                    handlePayment={handlePayment}
                />
            </StyledRoot>
        </>
    );
};

type Notice = {
    content: JSX.Element;
    colour: Colour;
}

const makeNotice = (subscription: Subscription | null, action: () => void): Notice | null => {
    if (subscription === null) {
        return null;
    }
    switch (subscription.status) {
    case "trialing":
    case "canceled":
    case "incomplete_expired":
        return null;
    case "active":
        if (!subscription.cancel_at_period_end) {
            return null;
        }
        return { colour: "info", content: (
            <Notice icon={Info}>
                Your plan is scheduled for deletion on {subscription.current_period_end.toLocaleDateString()}.
                You can cancel the deletion <span style={{ textDecoration: "underline" }}>
                    <Link color="inherit" href="#cancel" underline="hover">using the &quot;cancel deletion&quot; button</Link>
                </span>.
            </Notice>
        )};
    case "incomplete":
        return { colour: "warning", content: (
            <Notice icon={Warning}>
                Initial payment failed and your subscription is not yet
                active. <LinkButton onClick={action}>Click here</LinkButton> to finalize.
            </Notice>
        )};
    case "past_due":
        return { colour: "warning", content: (
            <Notice icon={Warning}>
                Scheduled payment has failed for your
                subscription. <LinkButton onClick={action}>Click here</LinkButton> to
                update your payment method.
            </Notice>
        )};
    case "unpaid":
        return { colour: "error", content: (
            <Notice icon={Error}>
                Scheduled payment has failed and your subscription has been
                deactivated. <span style={{ textDecoration: "underline" }}>
                    <LinkButton color="inherit" onClick={action}>Click here</LinkButton>
                </span> to reactivate your subscription.
            </Notice>
        )};
    }
}

const updatePaymentMethodText = (subscription: Subscription, method: null | unknown) => {

    const action = method === null
        ? "Add card"
        : "Update";

    switch (subscription.status) {
    case "active":
    case "canceled":
    case "incomplete_expired":
    case "trialing":
        return action;
    case "incomplete":
    case "past_due":
        return `${action} and pay`;
    case "unpaid":
        return `${action} and reactivate`;
    }
}