import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Typography } from "@mui/material";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { PlanTableHeader } from "./PlanTableHeader";
import { PlanTableBody } from "./PlanTableBody";
import { useApi, parseData } from "src/services/api-provider";
import { Skeleton } from '@mui/material';
import { PlanCodecExpandProduct, PlanExpandProduct } from "src/misc/codecs/stripe/Plan";
import * as t from "io-ts";
import { LinkButton } from "src/elements/LinkButton/LinkButton";
import { styled } from '@mui/material/styles';

type Props = {
    activePlan?: string,
    checkout: (priceId: string) => void;
};

const StyledLoading = styled(Skeleton)({
    width: "100%",
    height: "390px",
    transform: "none",
})

const StyledError = styled('div')(({ theme }) => ({
    height: "390px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
}))

const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
}))

const StyledTable = styled('table')(({ theme }) => ({
    width: "100%",
    textAlign: "center",
    borderCollapse: "collapse",
    fontWeight: theme.typography.fontWeightBold,
    "& td,th,tr": {
        border: `1px solid ${theme.palette.action.disabledBackground}`,
        padding: theme.spacing(1),
    },
    "& td:nth-of-type(1),th:nth-of-type(1)": {
        textAlign: "left",
        paddingLeft: theme.spacing(2),
        fontWeight: theme.typography.fontWeightRegular,
    }
}))

const StyledToggleTableButton = styled('div')(({ theme }) => ({
    width: "100%",
    height: "40px",
    color: theme.palette.fold.text,
    backgroundColor: theme.palette.fold.background,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
        cursor: "pointer",
    },
}))

type State = "loading" | "error" | PlanExpandProduct[];

export const PlanTable: React.FC<Props> = ({
    activePlan,
    checkout,
}) => {
    const api = useApi();
    const [planVisibility, setPlanVisibility] = useState(false);
    const [state, setState] = useState<State>("loading");

    // Fetch data from API
    const fetchPlans = useCallback(() => {
        setState("loading");
        api.Get("/api/stripe/plans").then(parseData(t.array(PlanCodecExpandProduct))).then((res) => {
            if (res.type === "success") {
                // Set state with plans sorted by price
                setState(res.data.sort((a, b) => a.amount - b.amount));
            } else {
                setState("error");
            }
        });
    }, [api]);

    useEffect(() => fetchPlans(), [fetchPlans]);

    // Loading placeholder
    if (state === "loading") {
        return (
            <StyledLoading />
        );
    } else if (state === "error") {
        return (
            <StyledError>
                <Typography>
                    Error loading plans. <LinkButton onClick={() => fetchPlans()}>Retry</LinkButton>.
                </Typography>
            </StyledError>
        )
    } else {
        return (
            <StyledRoot>
                <Collapse
                    in={planVisibility}
                    collapsedSize={350}
                    style={{ width: "100%" }}
                >
                    <div>
                        <StyledTable>
                            <PlanTableHeader
                                plans={state}
                                activePlan={activePlan}
                                checkout={checkout}
                            />
                            <PlanTableBody
                                plans={state}
                                activePlan={activePlan}
                            />
                        </StyledTable>
                    </div>
                </Collapse>
                <StyledToggleTableButton
                    onClick={() => {
                        setPlanVisibility(!planVisibility);
                    }}
                >
                    {planVisibility ? (
                        <>
                            <ArrowDropDownCircleOutlinedIcon
                                style={{ transform: "scaleY(-1)" }}
                            />
                            <Typography variant="body2">Hide</Typography>
                        </>
                    ) : (
                        <>
                            <ArrowDropDownCircleOutlinedIcon />
                            <Typography variant="body2">
                                View More Features
                            </Typography>
                        </>
                    )}
                </StyledToggleTableButton>
            </StyledRoot>
        );
    }
};
