import React, { FC } from "react";
import "./keyframes.css";
import { styled } from '@mui/material/styles';

const StyledSpinner = styled('g')({
    animation: "rotate 2s linear infinite",
    "& > circle": {
        stroke: "currentColor",
        strokeLinecap: "round",
        animation: "dash 1.5s ease-in-out infinite",
        fill: "none",
    }
})

type Props = {
  radius: number;
  x?: number;
}

export const Spinner: FC<Props> = ({ radius, x = 0 }: Props) => {

  const stroke = Math.max(radius/10, 1);
  const innerRadius = Math.max(radius - stroke, 2);
  
  return (
    <g transform={`translate(${x + radius})`}>
        <StyledSpinner>
            <circle r={innerRadius} strokeWidth={stroke} />
        </StyledSpinner>
    </g>
  );
}
