import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NavLink } from "../NavLink/NavLink";
import { styled } from '@mui/material/styles';

const StyledNav = styled('div')(({ theme }) => ({
    display: "flex",
    width: "100%",
    gap: theme.spacing(4),
    alignItems: "flex-end",
    "& > *": {
        lineHeight: 1,
        paddingBottom: theme.spacing(1),
        borderBottom: `solid 3px rgba(0,0,0,0)`,
    }
}))

const StyledTabs = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(1)
}))

export type Tab = {
    id: string;
    label: string;
}

type Tabs = string[];

type Children<T extends Tabs> = { [Property in keyof T]: JSX.Element };

type Props<T extends Tabs> = {
    tabs: T;
    children: Children<T>;
    className?: string;
}

export const Tabs = <T extends Tabs>({ tabs, children, className }: Props<T>): JSX.Element => {
    const slugs = tabs.map(name => slug(name));

    return (
        <div className={className}>
            <StyledNav>
                {tabs.map((name, i) => (
                    <NavLink
                        key={name}
                        to={slugs[i]}
                    >
                        {name}
                    </NavLink>
                ))}
            </StyledNav>
            <StyledTabs>
                <Routes>
                    {children.map((element, i) => (
                        <Route key={i} path={`${slugs[i]}/*`} element={element}/>
                    ))}
                    <Route path="*" element={<Navigate to={slugs[0]}/>}/>
                </Routes>
            </StyledTabs>
        </div>
    );
}

const slug = (s: string) => s
    .normalize('NFKD')        // Normalize unicode
    .toLowerCase()            // Lower case
    .trim()                   // Remove whitespace from ends
    .replace(/\s+/g, '-')     // Spaces to dashes
    .replace(/[^\w-]+/g, '')  // Remove non-word chars
    .replace(/--+/g, '-');    // Collapse dashes
  