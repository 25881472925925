
export enum RequestStatus {
    Pending,
    Success,
    Error
}


export enum WelcomeStepIndex {
    ProviderTargets = 1,
    VerifyDomain,
    InviteUsers,
    Completed
}
