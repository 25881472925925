import React from "react";
import { useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { InputBaseComponentProps } from "@mui/material/InputBase";

type Props = InputBaseComponentProps & {
    component: React.FunctionComponent<InputBaseComponentProps>;
    options: Record<string, unknown>;
};

const StripeInputComponent = (props: Props): JSX.Element => {
    const { component: Component, options, inputRef, ...other } = props;
    const theme = useTheme();
    const [mountNode, setMountNode] = React.useState<HTMLInputElement | null>(
        null
    );

    React.useImperativeHandle(
        inputRef,
        () => ({
            focus: () => {
                if (mountNode === null) {
                    return;
                }

                mountNode.focus();
            },
        }),
        [mountNode]
    );

    return (
        <Component
            onReady={setMountNode}
            options={{
                ...options,
                style: {
                    base: {
                        color: theme.palette.text.primary,
                        fontSize: "16px",
                        fontFamily: theme.typography.fontFamily,
                        "::placeholder": {
                            color: alpha(theme.palette.text.primary, 0.42),
                        },
                    },
                    invalid: {
                        color: theme.palette.text.primary,
                    },
                },
            }}
            {...other}
        />
    );
};

export default StripeInputComponent;
