import React from "react";
import { DASHARRAY, PADDING } from "./utils";

type BaselineProps = {
    /**
     * Width of svg
     */
    width: number;
    /**
     * Height of svg
     */
    height: number;
    /**
     * Stroke color of baseline
     */
    color: string;
    /**
     * Determine the visibility of the baseline
     */
    show?: boolean;
}

/**
 * Baseline on SVG Chart
 *
 * @param {*} {
 *     width,
 *     height,
 *     color,
 *     show = true,
 * }
 */
export const Baseline: React.FC<BaselineProps> = ({
    width,
    height,
    color,
    show = true,
}) => (
    <>
        { show && <polyline
            fill="none"
            stroke={color}
            strokeDasharray={DASHARRAY}
            strokeWidth="2px"
            points={`${PADDING},${height - PADDING/2} ${width - PADDING},${height - PADDING / 2}`}
            style={{ opacity: "0.2"}}
        />}
    </>
);