import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Collapse,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { useApi, parseData } from "src/services/api-provider";
import * as t from "io-ts";
import {
    PaymentMethod,
    PaymentMethodCodec,
} from "src/misc/codecs/stripe/PaymentMethod";
import { CardMenuItem } from "./CardMenuIcon";
import { StripeCardElements } from "./StripeCardElements";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { styled } from '@mui/material/styles';

type Props = {
    onSelect: (selection: CardSelection) => void;
    disabled: boolean;
};

type Card = Extract<PaymentMethod, { type: "card" }>;

const ADD_NEW = "ADD_NEW";
const LOADING = "LOADING";

export type CardIDResult =
    | { type: "ok", id: string }
    | { type: "error", message: string };

export type CardSelection =
    | { type: "none" }
    | { type: "some", id: () => Promise<CardIDResult> };

type State =
    | { key: "loading" }
    | { key: "error" }
    | { key: "ok", cards: Card[] };

const StyledLoading = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    "& > * + *": {
        marginLeft: theme.spacing(1.5),
    }
}));


export const CardSelector: React.FC<Props> = ({ onSelect, disabled }) => {
    const api = useApi();
    const [state, setState] = useState<State>({ key: "loading" });
    const [selected, setSelected] = useState<string | null>(null);
    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        api.Get("api/stripe/payment_methods").then(parseData(t.array(PaymentMethodCodec))).then(res => {
            if (res.type === "success") {
                setState({ key: "ok", cards: res.data.filter(isCard) });
            } else {
                setState({ key: "error" });
            }
        });
    }, [api]);

    const selection = (() => {
        switch (state.key) {
        case "loading":
            return LOADING;
        case "error":
            return ADD_NEW;
        case "ok":
            return selected;
        }
    })();

    // Report selected card back to caller
    useEffect(() => {
        switch (selection) {
        case null:
        case LOADING:
            return onSelect({ type: "none" });
        case ADD_NEW:
            return onSelect({
                type: "some",
                id: async (): Promise<CardIDResult> => {
                    if (stripe === null || elements === null) {
                        return { type: "error", message: "payment system unavailable" };
                    }
                    const cardNumberElement = elements.getElement(CardNumberElement);
                    if (cardNumberElement === null) {
                        return { type: "error", message: "no card element" };
                    }
                    const res = await stripe.createPaymentMethod({ type: "card", card: cardNumberElement });
                    if (res.error) {
                        return { type: "error", message: res.error.message || "couldn't save new card" };
                    }
                    return { type: "ok", id: res.paymentMethod.id };
                }
            });
        default:
            return onSelect({
                type: "some",
                id: async (): Promise<CardIDResult> => ({ type: "ok", id: selection }),
            });
        }
    }, [elements, stripe, selection, onSelect]);

    return (
        <div>
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="payment_method_select_label">
                    Payment Methods
                </InputLabel>
                <Select
                    variant="standard"
                    label="Payment Method&nbsp;"
                    labelId="payment_method_select_label"
                    id="payment_method_select"
                    value={selection || ''}
                    disabled={disabled || state.key === "loading"}>
                    {state.key === "loading" && (
                        <MenuItem value={LOADING}>
                            <StyledLoading>
                                <span>Loading</span>
                                <CircularProgress size={14} color="inherit"/>
                            </StyledLoading>
                        </MenuItem>
                    )}
                    {state.key === "ok" && state.cards.map(card => (
                        <MenuItem value={card.id} key={card.id} onClick={() => setSelected(card.id)}>
                            <CardMenuItem method={card} />
                        </MenuItem>
                    ))}
                    {state.key === "ok" && state.cards.length > 0 && (
                        <MenuItem divider />
                    )}
                    <MenuItem value={ADD_NEW} onClick={() => setSelected(ADD_NEW)}>Add New Card</MenuItem>
                </Select>
                { state.key === "error" && (
                    <FormHelperText>
                        Failed to load existing cards. You can add a new card, or try again later.
                    </FormHelperText>
                )}
            </FormControl>
            <Collapse style={{ marginTop: "1em" }} in={selection === ADD_NEW}>
                <StripeCardElements disabled={disabled} />
            </Collapse>
        </div>
    );
};

const isCard = (method: PaymentMethod): method is Card => {
    return method.type === "card";
}
