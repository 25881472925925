import {
    Button,
    TextField,
    Typography,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useApi } from "../../services/api-provider/ApiProvider";
import { InvitationPage } from './InvitationPage';
import { AcceptInvitation } from "../invitations/AcceptInvitation";
import { PageTitle } from "src/elements/PageTitle/PageTitle";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    '& > * + *': {
        marginTop: theme.spacing(3),
    }
}));

const StyledBox = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
    display: 'flex',
    height: '100%',
    alignItems: 'stretch',
    '& > *': {
        flexGrow: 1,
    }
}))

const StyledOrLabel = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    background: 'none',
    padding: theme.spacing(1),
    flex: 0,
}))

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2)
}))


type Props = {
    reload: () => void
}

type state = "default" | "pending" | "error" | "done";
type step = "subscription" | "invitation";

const isValidUrl = (urlString: string) => {
    let url;

    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export default function CreateSubscriptionWizard({ reload }: Props): JSX.Element {

    const [name, setName] = useState("");
    const [state, setState] = useState<state>("default");
    const [step, setStep] = useState<step>("subscription");
    const [code, setCode] = useState("");

    const api = useApi();

    const handleCreate = () => {
        if (name.length === 0) {
            return // TODO: Validation in UI
        }
        setState("pending");
        api.Post("/api/subscription/add", {
            "name": name
        }).then(res => {
            if (res.type === "error") {
                setState("error");
                console.error("error adding subscription", res.message);
            } else if (200 <= res.status && res.status <= 299) {
				// Jump to add invitation page after subscription is created successfully
				setStep('invitation');
            } else {
                setState("error");
            }
        });
    }

    const content = () => {
        switch (state) {
        case "default":
            return <>
                 <Typography>
                    Enter a name for the new subscription.
                </Typography>
                <TextField variant="standard" label="Name" onChange={e => setName(e.target.value)} />
            </>;
        case "pending":
            return <>
                 <Typography>Your subscription is being created.</Typography>
                 <LinearProgress/>
            </>;
        case "error":
            return <Typography>
                There was an error creating your subscription.
                Confirm to reload and try again.
            </Typography>;
        case "done":
            return <Typography>
                Your subscription has been created.
                You can now continue into the app.
            </Typography>;
        }
    }

    const actions = () => {
        switch (state) {
        case "default":
            return <Button onClick={handleCreate}>Create</Button>;
        case "pending":
            return <Button disabled>Create</Button>;
        case "error":
            return <Button onClick={() => {
                reload();
                setState("default");
            }}>Confirm</Button>;
        case "done":
            return <Button onClick={() => {
                reload();
                setState("default");
            }}>Continue</Button>;
        }
    }

	const updateInvitationCode = (newCode: string) => {
        if (newCode && newCode.trim() !== "") {
            if (isValidUrl(newCode)) {
                const link = new URL(newCode);
                const { pathname } = link;
                const pathnameList = pathname.split('/');

                if (pathnameList.length >= 3) {
                    if (pathnameList[1] === 'invitation') {
                        setCode(pathnameList[2])
                    }
                }
            } else {
                setCode(newCode);
            }
		}
	}
    return (
        <StyledRoot>
            <PageTitle>Welcome to Attack Bound</PageTitle>
            <Typography>
                You do not have a subscription. 
                To use AttackBound, you must either create a new subscription, 
                or be invited to an existing subscription.
            </Typography>
            <StyledBox>
                <StyledCard variant="outlined" style={{ flex: 2 }}>
                    {
                        step === "subscription" ? 
                            <>
                                <CardHeader title="Create Subscription"></CardHeader>
                                <CardContent>{content()}</CardContent>
                                <CardActions>{actions()}</CardActions>
                            </>
                            :
                            <>
                                {/* Add invitation page */}
                                <InvitationPage reload={reload} />
                            </>
                    }
                </StyledCard>
                <StyledOrLabel>
                    <Typography>OR</Typography>
                </StyledOrLabel>
                <StyledCard variant="outlined" style={{ flex: 2 }}>
                    <AcceptInvitation code={code} done={reload}>
                        <TextField
                            variant="standard"
                            label="Invite Code or Link"
                            style={{width: '50%'}}
                            onChange={e => updateInvitationCode(e.target.value)} />
                    </AcceptInvitation>
                </StyledCard>
            </StyledBox>
        </StyledRoot>
    );
}