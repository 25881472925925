import { Typography } from '@mui/material';
import React from 'react';

type Props = { message: string }

export const Error: React.FC<Props> = ({ message }) => {
    return (
        <div>
            <Typography>Error: ${message}</Typography>
        </div>
    );
}