import {
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { DeleteOutline, Done, PersonAdd, Refresh } from '@mui/icons-material';
import React from 'react'
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useApi } from '../../services/api-provider/ApiProvider';
import { v4 as uuidv4 } from 'uuid';
import { Link as RouterLink } from 'react-router-dom';
import { usePostgrest } from 'src/services/postgrest-provider';
import { styled } from '@mui/material/styles';

const StyledControls = styled('span')({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
});

const StyledTable = styled(Table)(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(2)
}))



type Invitation = {
    email: string;
    secret: string;
}

type PendingInvitation = {
    email: string;
    key: string;
    state: "pending" | "submitting" | "error" | "done";
}

type State = { state: "loading" }
           | { state: "error"; error: string }
           | { state: "ok"; invitations: Invitation[]; pending: PendingInvitation[];  refreshing: boolean };

export const InvitationTable: React.FC = (): JSX.Element => {

    const postgrest = usePostgrest();
    const api = useApi();
    const [state, setState] = useState<State>({state: "loading"});

    const load = useCallback(() => {
        setState(state => state.state === "ok" ? {...state, refreshing: true} : {state: "loading"});
        postgrest.GetTable("invitations", "used=eq.false&order=timestamp.desc").then(res => {
            if (res.type === "success") {
                setState(state => ({
                    state: "ok", 
                    invitations: res.data, 
                    refreshing: false, 
                    pending: state.state === "ok" ? state.pending.filter(x => x.state !== "done") : []
                }));
            } else {
                setState({state: "error", error: res.message});
            }
        })
    }, [postgrest]);

    // Load on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => load(), []);

    // Add an empty pending invitation
    const addInvitation = () => setState(state => {
        if (state.state !== "ok") return state;
        return {...state, pending: [...state.pending, {email: "", key: uuidv4(), state: "pending"}]};
    });

    // Submit a pending invitation
    const submitInvitation = useCallback((key: string) => {
        if (state.state !== "ok") return;
       
        setState((state) => {
            if (state.state !== "ok") return state;
            return {...state, pending: state.pending.map(x => {
                if (x.key !== key) return x;
                return {...x, state: "submitting"};
            })};
        });
        
        const invite = state.pending.reduce((a, b) => a.key === key ? a : b);

        api.Post("/api/invitation/create", {email: invite.email}).then(res => {
            if (res.type === "success") {
                setState(state => {
                    if (state.state !== "ok") return state;
                    return {...state, pending: state.pending.map(x => x.key === key ? {...x, state: "done"} : x)}
                });
                load(); // Reload invitations to pickup the new one
            } else {
                setState(state => {
                    if (state.state !== "ok") return state;
                    return {...state, pending: state.pending.map(x => x.key === key ? {...x, state: "error"} : x)}
                });
            }    
        });
    }, [state, api, load]);

    // Update email in pending invitation
    const updateEmail = (key: string, value: string) => setState(state => {
        if (state.state !== "ok") return state;
        return {...state, pending: state.pending.map(x => {
            if (x.key !== key || x.state !== "pending") return x;
            return {...x, email: value};
        })};
    });

    // Clear a pending invitation
    const removePending = useCallback((key: string) => {
        if (state.state !== "ok") return;
        setState({...state, pending: state.pending.filter(x => x.key !== key)});
    }, [state]);

    const refreshButton = (
        <IconButton size="small" onClick={() => load()}>
            <Refresh color="action" fontSize="inherit"/>
        </IconButton>
    );

    const addButton = (
        <IconButton size="small" onClick={addInvitation}>
            <PersonAdd color="action" fontSize="inherit"/>
        </IconButton>
    );

    const theme = useTheme();

    const spinner = <CircularProgress size="18px" color="inherit" style={{padding: 3, color: theme.palette.action.active}}/>;

    const refreshControl = (() => {
        switch (state.state) {
        case "loading":
            return spinner;
        case "error":
            return refreshButton;
        case "ok":
            return state.refreshing ? spinner : refreshButton;
        }
    })();

    const controls = (
        <StyledControls>
            {addButton}
            {refreshControl}
        </StyledControls>
    );

    const contentLoading = (
        <TableRow>
            <TableCell colSpan={2}>
                Loading...
            </TableCell>
        </TableRow>
    );

    const contentError = (error: string) => (
        <TableRow>
            <TableCell colSpan={2}>Error: {error}</TableCell>
        </TableRow>
    );

    const contentOK = (invites: Invitation[], pending: PendingInvitation[]) => (<>
        {pending.map(invite => {

            const submitButton = (
                <IconButton size="small" onClick={() => submitInvitation(invite.key)}>
                    <Done color="action" fontSize="inherit"/>
                </IconButton>
            );

            const removeButton = (
                <IconButton size="small" onClick={() => removePending(invite.key)}>
                    <DeleteOutline color="action" fontSize="inherit"/>
                </IconButton>
            );

            const control = (() => {
                switch (invite.state) {
                case "pending":
                    return [submitButton, removeButton];
                case "done":
                case "error":
                    return removeButton;
                case "submitting":
                    return spinner;
                }
            })();

            const valid = emailRegex.test(invite.email);

            return (
                <TableRow key={invite.key}>
                    <TableCell>
                        <TextField
                            variant="standard"
                            style={{width: "100%", minWidth: 180}}
                            error={invite.email.length > 0 && !valid}
                            helperText={valid || "Enter a valid email address"}
                            onChange={(e) => updateEmail(invite.key, e.target.value)}
                            value={invite.email}
                            disabled={invite.state !== "pending"}
                            size="small" />
                    </TableCell>
                    <TableCell>
                        {(invite.state === "error") && <span style={{marginRight: "1em"}}>Error creating invitation</span>}
                        {(invite.state === "pending" || invite.state === "submitting") && <>Pending</>}
                        {(invite.state === "done") && <>Refreshing...</>}
                    </TableCell>
                    <TableCell>
                        <StyledControls>
                            {control}
                        </StyledControls>
                    </TableCell>
                </TableRow>
            );
        })}
        {invites.map(invite => (
            <TableRow key={invite.secret}>
                <TableCell><Link href={`mailto:${invite.email}`} color='inherit' underline="hover">{invite.email}</Link></TableCell>
                <TableCell>
                    <Link
                        to={`/invitation/${invite.secret}`}
                        component={RouterLink}
                        underline="hover">
                        {`${window.location.protocol}//${window.location.host}/invitation/${invite.secret}`}
                    </Link>
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
        ))}
    </>);

    // The main table content/rows
    const content = (() => {
        switch (state.state) {
        case "loading":
            return contentLoading;
        case "error":
            return contentError(state.error);
        case "ok":
            return contentOK(state.invitations, state.pending);
        }
    })();

    return (
        <StyledTable size="small">
            <TableHead>
                <TableRow>
                    <TableCell><Typography variant="subtitle2">Email</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2">Invitation Link</Typography></TableCell>
                    <TableCell style={{width: 0}}>{controls}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {content}
            </TableBody>
        </StyledTable>
    );
}

// Very basic email validation, it will allow invalid things like a@a.a but is enough
// to prevent totally wrong inputs. The real validation comes by sending a verification link.
const emailRegex = /^.+@.+\..+/;