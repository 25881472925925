import React from "react";
import { useTheme } from "@mui/material";
import { LabelData } from "./const";
import { formatCount, RADIUS } from "./utils";

/** @typedef {object} ValueLabelProps React props for `<Label />` component*/
type ValueLabelProps = {
    /**
     * Color for svg polyline stroke
     */
    stroke?: string;
    /**
     * Width of svg polyline
     */
    strokeWidth?: string;
    /**
     * Circle's fill color
     */
    circleFill: string;
    /**
     * Label's color
     */
    textFill: string;
    /**
     * Determine whether the label should be displayed
     */
    visibility?: boolean | "onChanged";
    /**
     * Label's position
     */
    position?: "up" | "down";
    /**
     * Display data value
     */
    data: LabelData[];
}

/**
 * Label on svg line chart
 *
 * @param {ValueLabelProps} {
 *     stroke,
 *     strokeWidth = "1",
 *     circleFill,
 *     textFill,
 *     visibility,
 *     position,
 *     data,
 * }
 * @return {JSX.Element} 
 */
export const Label: React.FC<ValueLabelProps> = ({
    stroke,
    strokeWidth = "1",
    circleFill,
    textFill,
    visibility = true,
    position = "up",
    data,
}) => {
    const theme = useTheme();

    return (
        <g>
            { data.map((item, index) => {
                const shouldDisplay = index === 0 || index === data.length - 1 || (item.value !== data[index - 1].value || item.value !== data[index + 1].value);
                return (
                    <g key={index}>
                        {/** Circles */}
                        <circle
                            cx={item.x}
                            cy={item.y}
                            r={RADIUS}
                            stroke={stroke || theme.palette.text.primary}
                            strokeWidth={strokeWidth}
                            fill={
                                index === data.length - 1
                                    ? textFill
                                    : circleFill
                            }
                        />

                        {/** Value labels */}
                        {(visibility === true ||
                            (visibility === "onChanged" && shouldDisplay)) && (
                            <text
                                x={item.x}
                                y={position === "up" ? item.y - 8 : item.y + 20}
                                fontSize="1.125rem"
                                fill={textFill}
                                textAnchor="middle"
                                alignmentBaseline="auto"
                            >
                                {typeof item.value === "number"
                                    ? formatCount(Number(item.value))
                                    : item.value}
                            </text>
                        )}
                    </g>
                );
            })}
        </g>
    )
}

