import React, { useEffect, useState } from "react";
import { RequestStatus } from "src/shared/enums";
import {ScanDetails} from "./ScanDetails";
import { ScanDetailRecord } from "../services/scan-details";
import { usePostgrest } from "src/services/postgrest-provider";


export const ScanDetailsWithApi: React.FC = () => {
  const postgrest = usePostgrest();
  const [reqStatus, setReqStatus] = useState(RequestStatus.Pending);
  const [scanDetailRecord, setScanDetailRecord] = useState<ScanDetailRecord[]>([]);
  

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getData(), []);

  const getData = () => {
    postgrest
      .GetTable("scan_log", "order=timestamp.desc&limit=5")
      .then((res) => {
        if (res.type === "success"){
          setReqStatus(RequestStatus.Success);
          setScanDetailRecord(res.data);
        } else if (res.type === "error") {
          console.error(res.message);
          setReqStatus(RequestStatus.Error);
        }
      })
  };


  return (
    <ScanDetails status={reqStatus} data={scanDetailRecord} getData={getData}/>
  )
}

