import { Typography, Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { RowSlice, usePostgrest } from "src/services/postgrest-provider";
import { IssueFilterByCategoryGroup } from "./IssueFilterByCategoryGroup";
import { IssueFilterByDomainGroup } from "./IssueFilterByDomainGroup";
import {IssueFilterByDurationGroup} from "./IssueFilterByDurationGroup";
import { IssueFilterBySeverityGroup } from "./IssueFilterBySeverityGroup";
import { styled } from '@mui/material/styles';

export type DataRow = RowSlice<
    "issues",
    "target_domain" | "category" | "severity" | "user_severity" | "duration"
>;

type State =
    | { key: "loading" }
    | { key: "ok"; data: DataRow[] }
    | { key: "error"; error: string };


const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    display: "flex",
    flexDirection: "column",
}));

const StyledTitle = styled('div')({
    flex: 1,
    marginBottom: "1rem",
});


const StyledGroups = styled('div')(({ theme }) => ({
    flex: 1,
    padding: "1.25rem",
    background: theme.palette.background.paper,
}));


const StyledApplyButton = styled(Button)({
    width: "100%",
    height: "3rem",
    marginTop: "1rem",
    textTransform: "none",
});

const StyledClearButton = styled(Typography)(({ theme }) => ({
    marginTop: "1rem",
    color: theme.palette.filter.title,
    "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
    },
}))

export type GroupByData = {
    [title: string]: DataRow[];
};

export type RefProps = {
    getQuery(): string;
    clear(): void;
};

export type ChildrenProps = {
    data: DataRow[];
};

type Props = {
    searchText?: string;
    setFilterQuery: (query: string) => void;
    className?: string;
    toggleDrawer?: (open: boolean) => void;
};

export const IssueFilterBy: React.FC<Props> = ({
    searchText,
    setFilterQuery,
    className,
    toggleDrawer,
}) => {
    const postgrest = usePostgrest();
    const [state, setState] = useState<State>({ key: "loading" });
    const [sliderRange, setSliderRange] = useState<{ min: number, max: number }>({
        min: 0,
        max: 0,
    })

    const domainRef = useRef<RefProps>(null);
    const categoryRef = useRef<RefProps>(null);
    const severityRef = useRef<RefProps>(null);
    const durationRef = useRef<RefProps>(null);

    const loadData = useCallback(
        (query?: string) => {
            
            // If user has entered keywords for searching, we should also update the filter by data
            if (searchText) {
                query += `&%22lower_title%22=like.*${searchText}*`;
            }

            postgrest
                .GetTableSlice(
                    "issues",
                    [
                        "target_domain",
                        "category",
                        "severity",
                        "user_severity",
                        "duration",
                    ],
                    query
                )
                .then((response) => {
                    if (response.type === "error") {
                        setState({
                            key: "error",
                            error: response.message,
                        });
                    } else {
                        setState({
                            key: "ok",
                            data: response.data,
                        });

                        if (!query) {
                            getSliderRange(response.data);
                        }
                    }
                });
        },
        [postgrest, searchText]
    );

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const queryList: (string | undefined)[] = [
            domainRef.current?.getQuery(),
            categoryRef.current?.getQuery(),
            severityRef.current?.getQuery(),
            durationRef.current?.getQuery(),
        ];

        // Update issue table
        setFilterQuery(queryList.filter((query) => !!query).join("&"));

        // Update filter by data
        loadData(queryList.filter((query) => !!query).join("&"));

        // For filter section in drawer
        if (toggleDrawer) {
            toggleDrawer(false);
        }
    };

    const clearAll = () => {
        domainRef.current?.clear();
        categoryRef.current?.clear();
        severityRef.current?.clear();
        durationRef.current?.clear();
        
        // Reset issue table
        setFilterQuery("");
        
        // Reset filter by data
        loadData();
    };

    const getSliderRange = (data: DataRow[]) => {
        const daysDiff = data.map((record) => record.duration);
        const max = Math.max(...daysDiff);
        const min = Math.min(...daysDiff);

        if (Number.isFinite(min) && Number.isFinite(max)) {
            setSliderRange({
                min: min,
                max: max,
            })
        }
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <StyledRoot className={className}>
            <StyledTitle style={toggleDrawer && { marginTop: "1rem" }}>
                <Typography variant="h3">Filter By</Typography>
            </StyledTitle>
            <StyledGroups>
                {state.key === "ok" && (
                    <>
                        <IssueFilterByDomainGroup
                            ref={domainRef}
                            data={state.data}
                        />
                        <IssueFilterByCategoryGroup
                            ref={categoryRef}
                            data={state.data}
                        />
                        <IssueFilterBySeverityGroup
                            ref={severityRef}
                            data={state.data}
                        />
                        <IssueFilterByDurationGroup
                            ref={durationRef}
                            min={sliderRange.min}
                            max={sliderRange.max}
                        />
                    </>
                )}
                <StyledApplyButton
                    variant="outlined"
                    color="primary"
                    onClick={handleOnClick}
                >
                    <Typography variant="h3">Apply</Typography>
                </StyledApplyButton>
                <StyledClearButton
                    variant="h3"
                    onClick={() => clearAll()}
                >
                    Clear All
                </StyledClearButton>
            </StyledGroups>
        </StyledRoot>
    );
};
