import { PlanExpandProduct } from "src/misc/codecs/stripe/Plan";
import { keys } from "src/misc/keys";

export type PricingTableFeatureRow = {
    name: string;
    columns: boolean[];
};

export type PricingTableFeatureCategoryRow = {
    category: string;
    rows: PricingTableFeatureRow[];
};

export type PricingTable = {
    users: (number | string)[];
    domains: (number | string)[];
    featureRows: PricingTableFeatureRow[];
    featureCategoryRows: PricingTableFeatureCategoryRow[];
};

const DefaultPlan = {
    name: "",
    users: 3 as number | "Unlimited",
    domains: 3 as number | "Unlimited",
    inclusions: {
        "Interactive threat map": false,
        "Security Baseline and Rating": false,
        "Executive Dashboard": true,
        "Reporting": true,
        "API Integration": false,
        "Continuous Security Monitoring": true,
        "Dynamic Threat Updates": false,
    },
    categories: {
        "Attack Surface Exposure": {
            "Web Security": true,
            "DNS Security": true,
            "Certificate Strength": true,
            "Critical Network": true,
            "Critical System": true,
            "Data Breach Report": false,
        },
        "Digital Supply Chain": {
            "Third Party Hosted": false,
            "Cloud Services": false,
            "Data Breach Report": false,
            "Data Sovereign Risk Management": false,
        },
        "Social Threat Intelligence": {
            "Data Web Exposure": false,
            "People Social Threat Map": false,
            "Compromised Data Exposure": false,
            "Identity Breach Detection": false,
        }
    }
}

type PlanFeatures = typeof DefaultPlan;

const SmallBusinessPlan: PlanFeatures = {
    ...DefaultPlan,
    name: "Small Business",
}

const CorporatePlan: PlanFeatures = {
    name: "Corporate",
    users: 10,
    domains: 10,
    inclusions: {
        ...SmallBusinessPlan.inclusions,
        "Interactive threat map": true,
        "Security Baseline and Rating": true,
        "API Integration": true,
        "Dynamic Threat Updates": true,
    },
    categories: {
        "Attack Surface Exposure": {
            ...SmallBusinessPlan.categories["Attack Surface Exposure"],
            "Data Breach Report": true,
        },
        "Digital Supply Chain": {
            "Cloud Services": true,
            "Data Breach Report": true,
            "Data Sovereign Risk Management": true,
            "Third Party Hosted": true
        },
        "Social Threat Intelligence": {
            "Compromised Data Exposure": true,
            "Data Web Exposure": true,
            "Identity Breach Detection": true,
            "People Social Threat Map": true,
        },
    }
}

const EnterprisePlan: PlanFeatures = {
    ...CorporatePlan,
    name: "Enterprise",
    users: "Unlimited",
    domains: "Unlimited",
}

const planFeatures = [SmallBusinessPlan, CorporatePlan, EnterprisePlan];

type PlanWithFeatures = PlanExpandProduct & PlanFeatures;

const plansWithFeatures = (plans: PlanExpandProduct[]): PlanWithFeatures[] => {
    return plans.map(plan => ({
        ...plan,
        ...(planFeatures.find(p => p.name === plan.product.name) || DefaultPlan)
    }));
}

export const MakePricingTable = (_plans: PlanExpandProduct[]): PricingTable => {
    const plans = plansWithFeatures(_plans);
    return {
        users: plans.map(plan => plan.users),
        domains: plans.map(plan => plan.domains),
        featureRows: keys(DefaultPlan.inclusions).map(feature => ({
            name: feature,
            columns: plans.map(plan => plan.inclusions[feature])
        })),
        featureCategoryRows: keys(DefaultPlan.categories).map(category => ({
            category: category,
            rows: keys(DefaultPlan.categories[category]).map(feature => ({
                name: feature,
                columns: plans.map(plan => plan.categories[category][feature])
            }))
        })),
    }
}
