import React from 'react';
import { GetChildrenFnResult, TreeNode } from './TreeNode';
import { groupBy } from '../../misc/group';
import { formatRelative, subDays } from 'date-fns';
import { formatRFC3339 } from 'date-fns/esm';
import { Postgrest as Api, Row } from 'src/services/postgrest-provider';
import { ApiResult } from 'src/services/api-provider';

export const makeTree = (api: Api, target: string): [TreeNode[], TreeNode[]] => [[
    new TreeNode({
        name: "Social Exposure",
        tooltip: <p>Information on people and social connections discovered with the use of data found on the Internet.</p>,
        getChildren: () => getSocial(api, target)
    }),
], [
    new TreeNode({
        name: "Technical Exposure",
        tooltip: <p>Technical exposure discovered with the use of data found on the Internet.</p>,
        getChildren: () => getTech(api, target)
    }),
    new TreeNode({
        name: "Digital Supply Chain",
        tooltip: <p>Third-party digital providers associated with {target} discovered using public Internet data.</p>,
        getChildren: () => getSupplyChain(api, target)
    })
]];

type Result = Promise<GetChildrenFnResult>;

const err = (msg: string) => ({ type: "error" as const, error: msg });
const ok = (children: TreeNode[]) => ({ type: "success" as const, children });

// Helper function providing result unwrapping so individual getChildren functions can ignore
// error handling.
async function HandleResult<T>(result: Promise<ApiResult<number, T>>, handle: (data: T) => TreeNode[]): Result {
    const res = await result;
    if (res.type === "error") {
        console.error(res.message);
        return err("couldn't fetch data");
    } else {
        return ok(handle(res.data));
    }
}

// Helper function for fetching children via multiple API calls
async function CombineResults(...results: Result[]): Result {
    const r = await Promise.all(results);
    return r.reduce((result, current) => {
        if (result.type === "error") return result;
        if (current.type === "error") return current;
        return ok(result.children.concat(...current.children));
    }, ok([]));
}

// Helper for the common pattern of: return a "static" getChildren function if we have
// some data available, or otherwise return undefined. If getChildren is undefined the
// node is considered a leaf node.
function mapChildren<T>(data: T[], map: (t: T) => TreeNode) {
    if (data.length < 1) {
        return undefined;
    }
    return async () => ok(data.map(map));
}

const getTech = async (api: Api, target: string): Result => ok([
    new TreeNode({
        name: "Weaknesses and Exposures",
        tooltip: <p>All vulnerabilities, weaknesses and exposures discovered with the use of public Internet data.</p>,
        getChildren: () => getWeaknessesAndExposures(api, target)
    }),
    new TreeNode({
        name: "Online Footprint",
        tooltip: <p>Security data gathered from the digital footprint of {target}.</p>,
        getChildren: () => getOnlineFootprint(api, target)
    }),
]);

const getSocial = async (api: Api, target: string): Result => ok([
    new TreeNode({
        name: "Phishing Targets",
        tooltip: <p>Discovered individuals and credentials which can be used as targets for phishing attacks.</p>,
        getChildren: () => getPhishingTargets(api, target)
    }),
    new TreeNode({
        name: "Social Media Exposure",
        tooltip: <p>Discovered social media accounts.</p>,
        getChildren: () => getSocialMediaExposure(api, target)
    })
]);

const getSupplyChain = async (api: Api, target: string): Result => CombineResults(
    getServiceProviders(api, target),
    getLocations(api, target),
    getSoftware(api, target)
);

const getServiceProviders = async (api: Api, domain: string): Result => {
    return HandleResult(api.GetTable("service_providers", `domain=eq.${domain}`), data => {
        return [
            new TreeNode({
                name: `Service Providers - ${data.length}`,
                tooltip: <p>Discovered third-party service providers.</p>,
                category: false,
                getChildren: mapChildren(data, v => new TreeNode({
                    name: v.service_provider,
                    tooltip: (
                        <>
                            <p><b>{v.service_provider}</b></p>
                            {v.reasons.map(reason => (
                                <p key={reason}>{reason}</p>
                            ))}
                            <p>First seen on {new Date(v.first_seen).toLocaleDateString()}.</p>
                            <p>Most recently seen on {new Date(v.last_seen).toLocaleDateString()}.</p>
                        </>
                    )
                }))
            })
        ];
    })
}

const getLocations = async (api: Api, domain: string): Result => {
    return HandleResult(api.GetTableSlice("ip_address_locations", ["address", "country", "subdomain"], `target_domain=eq.${domain}`), data => {
        const countries = groupBy(data, "country");
        return [
            new TreeNode({
                name: `Locations - ${countries.length}`,
                tooltip: <p>Geographic locations in which infrastructure associated with {domain} is hosted.</p>,
                category: false,
                getChildren: mapChildren(countries, v => new TreeNode({
                    name: v.country,
                    tooltip: (
                        <>
                            <p><b>{v.country}</b></p>
                            {groupBy(v.items, "address").map(ip => (
                                <p key={ip.address}>{ip.address} ({ip.items.map(x => x.subdomain).join(", ")})</p>
                            ))}
                        </>
                    )
                }))
            })
        ];
    })
}

const getSoftware = async (api: Api, target: string): Result => {
    const earliest = subDays(new Date(), 7);

    return HandleResult(api.GetTable("services", `target_domain=eq.${target}&seen=gt.${encodeURIComponent(formatRFC3339(earliest))}`), data => {
        const services = groupBy(data, "product").filter(x => x.product != "");
        return [new TreeNode({
            name: `Software - ${services.length}`,
            tooltip: <p>Discovered third-part software in use by {target}.</p>,
            category: false,
            getChildren: async () => ok(services.map(v => {
                const items = groupBy(v.items, "name");
                return new TreeNode({
                    name: v.product,
                    tooltip: (
                        <>
                            {items.map(item => (
                                <p key={item.name}>{item.name}</p>
                            ))}
                        </>
                    ),
                })
            }))
        })]
    })
};

const getWeaknessesAndExposures = async (api: Api, target: string): Result => ok([
    new TreeNode({
        name: "Exposed Assets",
        tooltip: <p>Digital assets with exposure to the public Internet.</p>,
        getChildren: () => getExposedAssets(api, target)
    }),
    new TreeNode({
        name: "External Vulnerabilities",
        tooltip: <p>Detected vulnerabilities.</p>,
        getChildren: () => getExternalVulnerabilities(api, target)
    }),
]);

const getOnlineFootprint = async (api: Api, target: string): Result => ok([
    new TreeNode({
        name: "DNS Security",
        tooltip: <p>All DNS domains and subdomains.</p>,
        getChildren: () => getDNSSecurity(api, target)
    }),
    new TreeNode({
        name: "Email Security",
        tooltip: <p>Email security.</p>,
        getChildren: () => getEmailSecurity(api, target)
    }),
    new TreeNode({
        name: "Certificates",
        tooltip: <p>Security data on all discovered TLS certificates.</p>,
        getChildren: () => getCertificates(api, target)
    }),
    new TreeNode({
        name: "Websites",
        tooltip: <p>Security data on all discovered websites.</p>,
        getChildren: () => getWebsites(api, target)
    }),
]);

const getExposedServices = async (api: Api, target: string): Result => {
    const sketchy = [["RDP", "rdp"], ["Telnet", "telnet"], ["SMB", "smb"]];
    return HandleResult(api.GetTable("issues", `target_domain=eq.${target}&type=eq.exposed_service&resolved=is.null`), data => {
        return sketchy.map(service => {
            const entries = data.filter(x => x.desc.startsWith(`Exposed ${service[1]}`));
            if (entries.length <= 0) {
                return new TreeNode({
                    name: `${service[0]} Servers - none found`,
                    tooltip: <p>All servers with open {service[0]} ports.</p>
                })
            }

            return new TreeNode({
                    name: `${service[0]} Servers - ${entries.length}`,
                    tooltip: <p>Tets</p>,
                    category: false,
                    getChildren: mapChildren(entries, v => new TreeNode({
                        name: `${v.title}`,
                        tooltip: (
                            <div>
                                <p>{v.title}</p>
                                <p>Severity: {v.severity}</p>
                                <p>{v.desc}</p>
                                <p>First seen: {v.first_seen.toLocaleString()}</p>
                                <p>Last seen: {v.last_seen.toLocaleString()}</p>
                            </div>
                        ),
                        category: false,
                    }))
            });
        });
    });
}
const getExposedAssets = async (api: Api, target: string): Result => {
    const earliest = subDays(new Date(), 7);

    const services = getExposedServices(api, target);

    const all = HandleResult(api.GetTable("services", `target_domain=eq.${target}&seen=gt.${encodeURIComponent(formatRFC3339(earliest))}`), data => {
        const services = groupBy(data, "service");
        const count = groupBy(data, "service", "address", "port").length;
        return [new TreeNode({
            name: `Interesting Open Ports - ${count}`,
            tooltip: <p>All open network ports seen since {formatRelative(earliest, new Date())} (the last week) which may or may not represent risk.</p>,
            category: false,
            getChildren: async () => ok(services.map(v => new TreeNode({
                name: `${v.service} - ${groupBy(v.items, "address", "port").length}`,
                tooltip: <p>{v.service} servers associated with {target}.</p>,
                getChildren: () => getServiceType(v.items),
                category: false,
            })))
        })]
    })

    return CombineResults(services, all);
}

const getExternalVulnerabilities = async (api: Api, target: string): Result => {
    // Array map for [`node_title`, `issue_type`]
    const keyMap = [["CMS Vulnerabilities", "cms_vuln"]];

    return HandleResult(api.GetTable("issues", `target_domain=eq.${target}`), data => {
        return keyMap.map(service => {
            const entries = data.filter(x => x.type === service[1]);
            if (entries.length <= 0) {
                return new TreeNode({
                    name: `${service[0]} - none found`,
                })
            }

            return new TreeNode({
                name: `${service[0]} - ${entries.length}`,
                category: false,
                getChildren: mapChildren(entries, v => new TreeNode({
                    name: `${v.title}`,
                    tooltip: (
                        <div>
                            <p>{v.title}</p>
                            <p>Severity: {v.severity}</p>
                            <p>{v.desc}</p>
                            <p>First seen: {v.first_seen.toLocaleString()}</p>
                            <p>Last seen: {v.last_seen.toLocaleString()}</p>
                        </div>
                    ),
                    category: false,
                }))
            })
        })
    });
};

const getServiceType = async (items: Row<"services">[]): Result => {
    const service = items.reduce((_, v) => v.service, "");
    return ok(groupBy(items, "address", "port").map(host => new TreeNode({
        name: `${host.address}:${host.port}`,
        tooltip: (
            <div>
                {groupBy(host.items, "product").map(product => {
                    const seen = product.items.reduce((last, v) => last > v.seen ? last : v.seen, new Date(0));
                    const name = product.product === "" ? `Unrecognised ${service} service` : product.product;
                    return (
                        <p key={name}><b>{name}</b>: most recently seen {formatRelative(seen, new Date())}.</p>
                    )
                })}
            </div>
        )
    })))
}

const getDNSSecurity = async (api: Api, domain: string): Result => {
    const a = HandleResult(api.GetTable("subdomains_counts", `target_domain=eq.${domain}`), data => {
        const count = data.reduce((total, x) => x.count + total, 0);
        return [new TreeNode({
            name: `Interesting Subdomains - ${count}`,
            tooltip: <p>Subdomains which may potentially contain weaknesses and exposed applications.</p>,
            category: false,
            getChildren: () => getSubdomains(api, domain)
        })]
    });
    const b = getIssues(
        api,
        domain,
        ["subdomain_takeover"],
        "Potential Takeovers",
        <p>Attackers could potentially take control of subdomains configured for disused or legacy third-party cloud services,
        allowing them to launch a variety of attacks against your organization.</p>
    );
    return CombineResults(a, b);
}

const getSubdomains = async (api: Api, domain: string): Result => {
    return HandleResult(api.GetTable("dns_records", `target_domain=eq.${domain}`), data => {
        return groupBy(data, "name").map(v => new TreeNode({
            name: v.name,
            tooltip: (
                <>
                    <p><b>{v.name}</b></p>
                    {v.items.map(record => (
                        <p key={record.answer}>{record.type} record: {record.answer} (last seen {new Date(record.last_seen).toLocaleDateString()})</p>
                    ))}
                </>
            )
        }))
    })
}


const getEmailSecurity = async (api: Api, domain: string): Result => {
    const sketchy = [["SPF", "spf"], ["DMARC", "dmarc"]];
    return HandleResult(api.GetTable("issues", `target_domain=eq.${domain}&type=eq.email_config&resolved=is.null`), data => {
        return sketchy.map(service => {
            const entries = data.filter(x => x.desc.startsWith(`No ${service[0]}`));
            if (entries.length <= 0) {
                return new TreeNode({
                    name: `${service[0]} Issues - none found`,
                    tooltip: <p>All servers with open {service[0]} ports.</p>
                })
            }
            let tooltip = <p>Sender Policy Framework DNS records help detect and prevent spammers from sending forged email messages.</p>;
            if (service[0] == sketchy[1][0]) {
                tooltip = <p>
                Domain-based Message Authentication, Reporting &amp; Conformance, or DMARC, is a protocol
                which helps determine authenticity and prevent forgery of email messages.
                </p>;
            }

            return new TreeNode({
                name: `${service[0]} Issues - ${entries.length}`,
                tooltip,
                category: false,
                getChildren: mapChildren(entries, v => new TreeNode({
                    name: `${v.title}`,
                    tooltip: (
                        <div>
                            <p>{v.title}</p>
                            <p>Severity: {v.severity}</p>
                            <p>{v.desc}</p>
                            <p>First seen: {v.first_seen.toLocaleString()}</p>
                            <p>Last seen: {v.last_seen.toLocaleString()}</p>
                        </div>
                    ),
                    category: false,
                }))
            });
        });
    });
}

const getCertificates = async (api: Api, domain: string): Result => CombineResults(
    getIssues(
        api,
        domain,
        ["lets_encrypt_cert"],
        "Let's Encrypt Certificates",
        <p>Free, automated, and open certificates.</p>
    ),
    getIssues(
        api,
        domain,
        ["wildcard_cert"],
        "Wildcard Certificates",
        <p>Public key certificates which can be used with multiple sub-domains of a domain.</p>
    ),
    getIssues(
        api,
        domain,
        ["cert_trust", "cert_expired", "self_signed_cert"],
        "Untrusted Certificates",
        <p>Certificates which are not trusted or cannot be verified.</p>
    ),
);

const getWebsites = async (api: Api, domain: string): Result => CombineResults(
    getPotentiallyInsecureCMS(api, domain),
    getAverageEncryptionStrength(api, domain),
    getWeakestEncryptionStrength(api, domain),
    getInterestingURLs(),
);

const getPotentiallyInsecureCMS = async (api: Api, domain: string): Result => {
    return getIssues(
        api,
        domain,
        ["cms_vuln"],
        "Potentially insecure CMS",
        <p>The Content Management System used for the website contains vulnerabilities or security misconfigurations.</p>
    )
};

const getAverageEncryptionStrength = async (api: Api, domain: string): Result => {
    return HandleResult(api.GetTable("domain_cipher_strength_score", `target_domain=eq.${domain}`), data => {
        let name = `Average Encryption Strength - none found`
        if(data.length > 0){
            name = `Average Encryption Strength - ${getGrade(data[0].average)}`
        } 
        return [
            new TreeNode({
                name,
                tooltip: <p>Average transport layer encryption cipher strength.</p>,
            })
        ];
    })
};

const getWeakestEncryptionStrength = async (api: Api, domain: string): Result => {
    return HandleResult(api.GetTable("domain_cipher_strength_score", `target_domain=eq.${domain}`), data => {
        let name = `Weakest Encryption Strength - none found`
        if(data.length > 0){
            name = `Weakest Encryption Strength - ${getGrade(data[0].average)}`
        } 
        return [
            new TreeNode({
                name,
                tooltip: <p>Weakest transport layer encryption cipher strength.</p>,
            })
        ];
    })
};

const getInterestingURLs = async (): Result => ok([
    new TreeNode({
        name: "Interesting URLs - 0",
        tooltip: <p>URLs containing data or documents which may be useful for attackers.</p>
    })
]);

const getDiscoveredEmailAddresses = async (name: string, api: Api, domain: string): Result => {
    let emails: string[] = [];

    const socialAccounts = await api.GetTableSlice("social_accounts", ["email"], `domain=eq.${domain}`);
    if (socialAccounts.type == "success") {
        emails = emails.concat(...socialAccounts.data.map(v => v.email));
    }

    return HandleResult(api.GetTableSlice("known_users", ["email"], `domain=eq.${domain}&email=not.is.null`), data => {
        emails = emails.concat(...data.map(v => v.email ? v.email : "unknown"));
        emails = emails.filter((n, i) => emails.indexOf(n) === i);

        return [
            new TreeNode({
                name: `${name} - ${emails.length}`,
                tooltip: <p>Discovered active email addresses.</p>,
                category: false,
                getChildren: mapChildren(emails, v => new TreeNode({ 
                    name: v
                }))
            })
        ];
    })
}

const getCompromisedCredentials = async (api: Api, domain: string): Result => {
    return HandleResult(api.GetTable("breached_credentials", `domain=eq.${domain}`), data => {
        return [
            new TreeNode({
                name: `Compromised Credential Records - ${data.length}`,
                tooltip: <p>Total number of compromised credentials discovered on the Dark Web.</p>,
                category: false,
                getChildren: mapChildren(groupBy(data, "database_name"), v => new TreeNode({
                    name: `${v.database_name} - ${v.items.length}`,
                    tooltip: <p>Credentials from the {v.database_name} leak</p>,
                    category: false,
                    getChildren: async () => ok(v.items.map(v => new TreeNode({
                        name: v.email || v.username || v.name || "Unknown",
                        tooltip: (
                            <>
                                <p>
                                    User {v.email || v.username || v.name} with {
                                        v.password
                                            ? `password ${v.password}`
                                            : v.hashed_password
                                                ? `hashed password ${v.hashed_password}`
                                                : "no password"
                                    }.
                                </p>
                                <p>First seen on {new Date(v.first_seen).toLocaleString()}.</p>
                            </>
                        )
                    })))
                }))
            })
        ];
    })
}

const getPasswordScore = async (api: Api, domain: string): Result => {
    return HandleResult(api.GetTable("breached_credentials", `domain=eq.${domain}&password_score=gt.-1`), data => {
        let averageScoreStr = 'none found';
        if(data.length > 0){
            const averageScore = Math.round(data.reduce((result, current) => result + current.password_score,0) / data.length);

            if(averageScore == 0) {
                averageScoreStr = 'Weak';
            } else if(averageScore == 1) {
                averageScoreStr = 'Moderate';
            } else if(averageScore == 2) {
                averageScoreStr = 'Good';
            } else if(averageScore == 3) {
                averageScoreStr = 'Strong';
            } else if(averageScore == 4) {
                averageScoreStr = 'Very strong';
            }
        } 
        const name = `Password Score - ${averageScoreStr}`
        return [
            new TreeNode({
                name,
                tooltip: <p>An average password strength score based on historical dark web records.</p>,
            })
        ];
    })
}

const getPhishingTargets = async (api: Api, domain: string): Result => CombineResults(
    getDiscoveredEmailAddresses("Discovered Email Addresses", api, domain),
    getPasswordScore(api, domain),
    getCompromisedCredentials(api, domain)
);

const getSocialMediaProfiles = async (api: Api, domain: string): Result => {
    const profiles = [
        "LinkedIn",
        "Facebook",
        "Hi5",
        "MySpace",
        "Other",
        "Twitter",
        "Wayn",
        "Youtube",
        "Pinterest"
    ];

    return HandleResult(api.GetTable("breached_credentials", `domain=eq.${domain}`), data => {
        return profiles.map(p => {
            const filteredData = data.filter(d => d.database_name == p)
            return new TreeNode({
                name: `${p} Profiles - ${filteredData.length}`,
                tooltip: <p>Discovered {p} profiles associated with email addresses related to {domain}.</p>,
                category: false,
                getChildren: mapChildren(filteredData, v => new TreeNode({
                    name: `${v.email}`,
                    category: false,
                }))
            })
        });
    })
}

const getSocialMediaExposure = async (api: Api, domain: string): Result => CombineResults(
    getDiscoveredEmailAddresses("Email profiles", api, domain),
    getSocialMediaProfiles(api, domain)
);

type IssueType = Row<"issues">["type"];

const getIssues = async (api: Api, domain: string, types: IssueType[], title: string, tooltip?: JSX.Element): Result => {
    const typeParam = encodeURIComponent(`in.(${types.join(",")})`);
    return HandleResult(api.GetTable("issues", `target_domain=eq.${domain}&type=${typeParam}&resolved=is.null`), data => {
        return [
            new TreeNode({
                name: `${title} - ${data.length}`,
                tooltip: tooltip,
                category: false,
                getChildren: mapChildren(data, v => new TreeNode({
                    name: `${v.title}`,
                    tooltip: (
                        <div>
                            <p>{v.title}</p>
                            <p>Severity: {v.severity}</p>
                            <p>{v.desc}</p>
                            <p>First seen: {v.first_seen.toLocaleString()}</p>
                            <p>Last seen: {v.last_seen.toLocaleString()}</p>
                        </div>
                    ),
                    category: false,
                }))
            })
        ];
    })
}

const getGrade = (num: number): string => {
    if (num >=95){
        return "A+"
    } else if (num >=90){
        return "A"
    }else if (num >=85){
        return "B+"
    }else if (num >=80){
        return "B"
    }else if (num >=75){
        return "C+"
    }else if (num >=70){
        return "C"
    }else if (num >=65){
        return "D+"
    }else if (num >=60){
        return "D"
    }else {
        return "F"
    }
}