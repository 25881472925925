import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DnsDataRecord } from '../services/full-report';
import { RequestStatus } from 'src/shared/enums';
import { FullReportLoading } from '../molecules/FullReportLoading';
import { Fade, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  data: DnsDataRecord[];
  status: number;
}


const StyledTable = styled(Table)({
    minWidth: 650,
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.table.head.background,
    color: theme.palette.table.head.text,
}))

export const DnsRecord: React.FC<Props> = ({
  data,
  status
}) => {
  if (status === RequestStatus.Pending) {
    return <FullReportLoading />
  }

  return (
      <Fade in={!!data}>
          <TableContainer>
              <StyledTable aria-label="DNS Record table">
                  <StyledTableHead>
                      <TableRow>
                          <TableCell>
                              <Typography variant="subtitle2">Name</Typography>
                          </TableCell>
                          <TableCell>
                              <Typography variant="subtitle2">Type</Typography>
                          </TableCell>
                          <TableCell>
                              <Typography variant="subtitle2">
                                  Address
                              </Typography>
                          </TableCell>
                      </TableRow>
                  </StyledTableHead>
                  <TableBody>
                      {data.map((row, index) => (
                          <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                  {row.name}
                              </TableCell>
                              <TableCell>{row.type}</TableCell>
                              <TableCell>{row.target_domain}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </StyledTable>
          </TableContainer>
      </Fade>
  );
}