import { Paper, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { InvitationTable } from './InvitationTable';
import DeleteSubscriptionDialog from './SubscriptionDeleteDialog';
import { SubscriptionContext } from '../../services/subscription-provider/SubscriptionProvider';
import { UserTable } from './UserTable';
import { styled } from '@mui/material/styles';

const StyledRoot = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.default,
    '& > *': {
        marginBottom: theme.spacing(1.5)
    }
}));

const StyledSpacer = styled('div')(({ theme }) => ({
    height: theme.spacing(3),
}))

const StyledValue = styled(Typography)(({ theme }) => ({
    padding: `${theme.spacing(0.1)} ${theme.spacing(0.6)}`,
}))

export const SubscriptionPage: React.FC = (): JSX.Element => {
    const subscription = useContext(SubscriptionContext).current;

    return (
        <StyledRoot elevation={0}>
            <div>
                <Typography component="h2" variant="h6">Subscription Name</Typography>
                <StyledValue variant='h3'>{subscription.name}</StyledValue>
            </div>
            <StyledSpacer/>
            <Typography component="h2" variant="h6">Users</Typography>
            <UserTable/>
            <StyledSpacer/>
            <Typography component="h2" variant="h6">Invitations</Typography>
            <Typography variant="body2">
                Invite a user by pressing the add button below and entering the user&apos;s email address.
                Send the resulting link to the user, who should follow the link to sign up and accept the invite.
                The user must sign up with the given email address.
            </Typography>
            <InvitationTable/>
            <StyledSpacer/>
            <DeleteSubscriptionDialog />
        </StyledRoot>
    );
}