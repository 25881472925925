import React, { useState } from "react";
import {
    StripeTextFieldCVC,
    StripeTextFieldExpiry,
    StripeTextFieldNumber,
} from "./commonTextFields";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    "& > *": {
        flexGrow: 1,
        width: "auto"
    }
}))

type Props = {
    disabled: boolean;
}

export const StripeCardElements: React.FC<Props> = ({ disabled }) => {
    const [state, setState] = useState({
        cardNumberComplete: false,
        expiredComplete: false,
        cvcComplete: false,
        cardNumberError: undefined,
        expiredError: undefined,
        cvcError: undefined,
        submitError: "",
    });

    const onElementChange = (field: string, errorField: string) => ({
        complete,
        error = { message: null },
    }: {
        complete: boolean;
        error?: { message: null | string };
    }) => {
        setState({ ...state, [field]: complete, [errorField]: error.message });
    };

    const { cardNumberError, expiredError, cvcError } = state;

    return (
        <StyledRoot>
            <StripeTextFieldNumber
                style={{ width: "100%" }}
                error={Boolean(cardNumberError)}
                labelErrorMessage={cardNumberError}
                onChange={onElementChange(
                    "cardNumberComplete",
                    "cardNumberError"
                )}
                disabled={disabled}
            />
            <StripeTextFieldExpiry
                error={Boolean(expiredError)}
                labelErrorMessage={expiredError}
                onChange={onElementChange(
                    "expiredComplete",
                    "expiredError"
                )}
                disabled={disabled}
            />
            <StripeTextFieldCVC
                error={Boolean(cvcError)}
                labelErrorMessage={cvcError}
                onChange={onElementChange("cvcComplete", "cvcError")}
                disabled={disabled}
            />
        </StyledRoot>
    );
};

