import { Button, Card, CardActions, CardContent, CircularProgress, Typography } from "@mui/material";
import { RefreshOutlined } from "@mui/icons-material";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Row, usePostgrest } from "src/services/postgrest-provider";
import { BasicLayout } from "../../features/layout/BasicLayout";
import Splash from "../../features/splash/Splash";
import CreateSubscriptionWizard from "../../features/subscriptions/SubscriptionCreateWizard";


type Props = {
    children: JSX.Element | Array<JSX.Element>
}

export type Subscription = Row<"subscriptions_with_features">;

type SubscriptionInfo = {
    current: Subscription,
    refresh: () => void
};

type state = { state: "loading" }
           | { state: "error", error: string }
           | { state: "none" }
           | { state: "ok", subscription: Subscription }
           | { state: "refreshing", subscription: Subscription };


export default function SubscriptionProvider({ children }: Props): JSX.Element {

    const postgrest = usePostgrest();
    const [state, setState] = useState<state>({ state: "loading" });
   
    const fetch = useCallback((): void => {
        postgrest.GetTable("subscriptions_with_features").then(res => {
            if (res.type === "success") {
                if (res.data.length < 1) {
                    setState({ state: "none" });
                } else {
                    setState({ state: "ok", subscription: res.data[0] });
                }
            } else {
                setState({ state: "error", error: "couldn't fetch subscription information" });
            }
        });
    }, [postgrest]);

    const load = useCallback((): void => {
        setState({ state: "loading" });
        fetch();
    }, [fetch]);

    const refresh = useCallback((): void => {
        if (state.state !== "ok") return;
        setState({ state: "refreshing", subscription: state.subscription });
        fetch();
    }, [fetch, state]);

    useEffect(() => load(), [load]);

    switch (state.state) {
    case "loading":
        return <Splash>
            <CircularProgress />
            <Typography>Loading Subscriptions</Typography>
        </Splash>;
    case "error":
        return <Splash>
            <Card>
                <CardContent>
                    <Typography color="error">Error: {state.error}</Typography>
                </CardContent>
                <CardActions>
                    <Button startIcon={<RefreshOutlined/>} onClick={fetch}>Refresh</Button>
                </CardActions>
            </Card>
        </Splash>;
    case "none":
        return <BasicLayout>
            <CreateSubscriptionWizard reload={load} />
        </BasicLayout>;
    case "refreshing":
        // Fallthrough
    case "ok":
        return <SubscriptionContext.Provider value={{
            current: state.subscription,
            refresh: refresh
        }}>
            {children}
        </SubscriptionContext.Provider>
    }
}

export const SubscriptionContext = createContext<SubscriptionInfo>({
    current: { name: '', uuid: '', show_welcome: true, 
        users_count: '',
        domains_count: '',
        interactive_threat_map: false,
        security_baseline_and_rating: false,
        executive_dashboard: false,
        reporting: false,
        api_integration: false,
        continuous_security_monitoring: false,
        dynamic_threat_updates: false,
        web_security: false,
        dns_security: false,
        certificate_strength: false,
        critical_network: false,
        critical_system: false,
        attack_surface_exposure_data_breach_report: false,
        third_party_hosted_services: false,
        cloud_services: false,
        digital_supply_chain_data_breach_report: false,
        data_sovereign_risk_management: false,
        data_web_exposures: false,
        people_social_threat_map: false,
        compromised_data_exposure: false,
        identity_breach_detection: false 
    },
    refresh: () => { throw Error("SubscriptionContext not set") }
});

/**
 * Get access to the user's subscription.
 * @returns A SubscriptionInfo object.
 */
 export const useSubscription = (): SubscriptionInfo => {
    return useContext(SubscriptionContext);
}