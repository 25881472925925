import { useTheme } from "@mui/material";
import {
    add,
} from "date-fns/esm";
import React, { useCallback, useEffect, useState } from "react";
import {
    CriticalRiskIssuesWidget,
    HighRiskIssuesWidget,
    MediumRiskIssuesWidget,
    LowRiskIssuesWidget,
    NewIssuesWidget,
    RemainingIssuesWidget,
    TotalIssuesWidget,
    ClosedIssuesWidget,
} from "src/features/issue-history";
import {
    DAYS_IN_PERIOD,
    GroupedIssueHistoryData,
    IssueHistoryData,
    IssueHistoryWidgetProps,
} from "./const";
import {
    divideData,
    filterCriticalRiskData,
    filterHighRiskData,
    filterLowRiskData,
    filterMediumRiskData,
    getDateList,
    getLineStyle,
    isClosedData,
    isNewData,
    isRemainingData,
    isTotalData,
} from "./utils";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')({
    width: "100%",
})

const StyledHistoryCharts = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
    width: "100%",
}));

const StyledSeverityCharts = styled('div')({
    width: "50%",
    minWidth: "600px",
    "& > div": {
        margin: "0.3125rem 0",
    },
})
const StyledStatusCharts = styled('div')(({ theme }) => ({
    width: "50%",
    minWidth: "600px",
    "& > div": {
        margin: "0.3125rem 0",
        backgroundColor: theme.palette.background.paper,
    },
}))

/**
 *
 * @param startDate The start date of the issue history
 * @param endDate The end date of the issue history
 * @returns Struct data for grouping issues by date
 *  E.g.
 *  [{
 *      date: "01/01/2022"      // Locale Date String
 *      data: [],
 *  }, ...]
 */
const initialGroupedData = (
    startDate: Date,
    endDate: Date
): GroupedIssueHistoryData[] => {
    const initialDataSet: GroupedIssueHistoryData[] = [];

    let targetDate = startDate;

    while (targetDate <= endDate) {
        const dateString = targetDate.toLocaleDateString();
        initialDataSet.push({
            date: dateString,
            data: [],
        });

        targetDate = add(targetDate, { days: 1 });
    }

    return initialDataSet;
};

type Props = {
    today: Date;
    startOfLastPeriod: Date;
    issuesData: IssueHistoryData[];
};

export const IssueHistoryInSevenDays: React.FC<Props> = ({
    today,
    startOfLastPeriod,
    issuesData,
}) => {
    const theme = useTheme();

    const [criticalRiskIssuesDataSet, setCriticalRiskIssuesDataSet] =
        useState<IssueHistoryWidgetProps>();
    const [highRiskIssuesDataSet, setHighRiskIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [mediumRiskIssuesDataSet, setMediumRiskIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [lowRiskIssuesDataSet, setLowRiskIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [totalIssuesDataSet, setTotalIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [newIssuesDataSet, setNewIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [remainingIssuesDataSet, setRemainingIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();
    const [closedIssuesDataSet, setClosedIssuesDataSet] =
        useState<IssueHistoryWidgetProps | null>();

    /**
     * Construct Props structure for widget IssueHistoryWidget
     */
    const constructWidgetProps = useCallback(
        (data: number[], isSeverity = false) => {
            const [prevPeriodData, currPeriodData] = divideData(data);

            // Get [total]
            const thisPeriodSum = currPeriodData.reduce(
                (sum, prev) => sum + prev,
                0
            );

            const lastPeriodSum = prevPeriodData.reduce(
                (sum, prev) => sum + prev,
                0
            );

            // Get [difference]
            const difference = thisPeriodSum - lastPeriodSum;

            // Get [dataSet]
            const currPeriodChartData = Object.assign(
                {},
                getLineStyle("current", isSeverity),
                { values: currPeriodData }
            );
            const prevPeriodChartData = Object.assign(
                {},
                getLineStyle("previous", isSeverity),
                { values: prevPeriodData }
            );

            const widgetProps: IssueHistoryWidgetProps = {
                total: thisPeriodSum,
                difference: difference,
                numberOfDays: DAYS_IN_PERIOD,
                dataSet: [currPeriodChartData, prevPeriodChartData],
            };

            return widgetProps;
        },
        []
    );

    useEffect(() => {
        const dateStringList = getDateList(startOfLastPeriod, today);

        const newIssuesDataSet: GroupedIssueHistoryData[] = initialGroupedData(
            startOfLastPeriod,
            today
        );
        const closedIssuesDataSet: GroupedIssueHistoryData[] =
            initialGroupedData(startOfLastPeriod, today);
        const totalIssuesDataSet: GroupedIssueHistoryData[] =
            initialGroupedData(startOfLastPeriod, today);
        const remainingIssuesDataSet: GroupedIssueHistoryData[] =
            initialGroupedData(startOfLastPeriod, today);

        dateStringList.forEach(({ dateString, date }) => {
            issuesData.forEach((issue) => {
                if (isTotalData(issue, date)) {
                    const totalIssueTargetDateGroup = totalIssuesDataSet.find(
                        (item) => item.date === dateString
                    );
                    if (totalIssueTargetDateGroup) {
                        totalIssueTargetDateGroup.data.push(issue);
                    }
                }

                if (isNewData(issue, date)) {
                    const newIssueTargetDateGroup = newIssuesDataSet.find(
                        (item) => item.date === dateString
                    );
                    if (newIssueTargetDateGroup) {
                        newIssueTargetDateGroup.data.push(issue);
                    }
                }

                if (isRemainingData(issue, date)) {
                    const remainingIssueTargetDateGroup =
                        remainingIssuesDataSet.find(
                            (item) => item.date === dateString
                        );
                    if (remainingIssueTargetDateGroup) {
                        remainingIssueTargetDateGroup.data.push(issue);
                    }
                }

                if (isClosedData(issue, date)) {
                    const closedIssueTargetDateGroup = closedIssuesDataSet.find(
                        (item) => item.date === dateString
                    );
                    if (closedIssueTargetDateGroup) {
                        closedIssueTargetDateGroup.data.push(issue);
                    }
                }
            });
        });

        /**
         * Filter out issues by severity
         */
        const criticalRiskIssuesDataSet =
            filterCriticalRiskData(totalIssuesDataSet);
        const highRiskIssuesDataSet = filterHighRiskData(totalIssuesDataSet);
        const mediumRiskIssuesDataSet =
            filterMediumRiskData(totalIssuesDataSet);
        const lowRiskIssuesDataSet = filterLowRiskData(totalIssuesDataSet);

        /**
         * Transforming issues into counts
         */
        const newIssuesCounts = newIssuesDataSet.map(
            (data) => data.data.length
        );
        const remainingIssuesCounts = remainingIssuesDataSet.map(
            (data) => data.data.length
        );
        const closedIssuesCounts = closedIssuesDataSet.map(
            (data) => data.data.length
        );
        const totalIssuesCounts = totalIssuesDataSet.map(
            (data) => data.data.length
        );
        const criticalRiskIssuesCounts = criticalRiskIssuesDataSet.map(
            (data) => data.data.length
        );
        const highRiskIssuesCounts = highRiskIssuesDataSet.map(
            (data) => data.data.length
        );
        const mediumRiskIssuesCounts = mediumRiskIssuesDataSet.map(
            (data) => data.data.length
        );
        const lowRiskIssuesCounts = lowRiskIssuesDataSet.map(
            (data) => data.data.length
        );

        // /**
        //  * Removing first item which were used to calculate the first remaining issues count
        //  */
        // criticalRiskIssuesCounts.shift();
        // highRiskIssuesCounts.shift();
        // mediumRiskIssuesCounts.shift();
        // lowRiskIssuesCounts.shift();
        // totalIssuesCounts.shift();
        // newIssuesCounts.shift();
        // remainingIssuesCounts.shift();
        // closedIssuesCounts.shift();

        const criticalRiskIssuesWidgetProps: IssueHistoryWidgetProps =
            constructWidgetProps(criticalRiskIssuesCounts, true);
        const highRiskIssuesWidgetProps: IssueHistoryWidgetProps =
            constructWidgetProps(highRiskIssuesCounts, true);
        const mediumRiskIssuesWidgetProps: IssueHistoryWidgetProps =
            constructWidgetProps(mediumRiskIssuesCounts, true);
        const lowRiskIssuesWidgetProps: IssueHistoryWidgetProps =
            constructWidgetProps(lowRiskIssuesCounts, true);
        const totalIssuesWidgetProps: IssueHistoryWidgetProps =
            constructWidgetProps(totalIssuesCounts);
        const newIssuesWidgetProps: IssueHistoryWidgetProps =
            constructWidgetProps(newIssuesCounts);
        const remainingIssuesWidgetProps: IssueHistoryWidgetProps =
            constructWidgetProps(remainingIssuesCounts);
        const closedIssuesWidgetProps: IssueHistoryWidgetProps =
            constructWidgetProps(closedIssuesCounts);

        setTotalIssuesDataSet(totalIssuesWidgetProps);
        setNewIssuesDataSet(newIssuesWidgetProps);
        setRemainingIssuesDataSet(remainingIssuesWidgetProps);
        setClosedIssuesDataSet(closedIssuesWidgetProps);
        setCriticalRiskIssuesDataSet(criticalRiskIssuesWidgetProps);
        setHighRiskIssuesDataSet(highRiskIssuesWidgetProps);
        setMediumRiskIssuesDataSet(mediumRiskIssuesWidgetProps);
        setLowRiskIssuesDataSet(lowRiskIssuesWidgetProps);
    }, [
        constructWidgetProps,
        issuesData,
        startOfLastPeriod,
        today,
    ]);

    return (
        <StyledRoot>
            <StyledHistoryCharts>
                <StyledSeverityCharts>
                    <div>
                        {criticalRiskIssuesDataSet && (
                            <CriticalRiskIssuesWidget
                                {...criticalRiskIssuesDataSet}
                            />
                        )}
                    </div>
                    <div>
                        {highRiskIssuesDataSet && (
                            <HighRiskIssuesWidget {...highRiskIssuesDataSet} />
                        )}
                    </div>
                    <div>
                        {mediumRiskIssuesDataSet && (
                            <MediumRiskIssuesWidget
                                {...mediumRiskIssuesDataSet}
                            />
                        )}
                    </div>
                    <div>
                        {lowRiskIssuesDataSet && (
                            <LowRiskIssuesWidget {...lowRiskIssuesDataSet} />
                        )}
                    </div>
                </StyledSeverityCharts>
                <StyledStatusCharts>
                    <div>
                        {totalIssuesDataSet && (
                            <TotalIssuesWidget
                                {...totalIssuesDataSet}
                                backgroundColor={theme.palette.background.paper}
                            />
                        )}
                    </div>
                    <div>
                        {newIssuesDataSet && (
                            <NewIssuesWidget
                                {...newIssuesDataSet}
                                backgroundColor={theme.palette.background.paper}
                            />
                        )}
                    </div>
                    <div>
                        {remainingIssuesDataSet && (
                            <RemainingIssuesWidget
                                {...remainingIssuesDataSet}
                                backgroundColor={theme.palette.background.paper}
                            />
                        )}
                    </div>
                    <div>
                        {closedIssuesDataSet && (
                            <ClosedIssuesWidget
                                {...closedIssuesDataSet}
                                backgroundColor={theme.palette.background.paper}
                            />
                        )}
                    </div>
                </StyledStatusCharts>
            </StyledHistoryCharts>
            <div></div>
        </StyledRoot>
    );
};
