import { IssueHistoryChartData } from "./IssueHistory";

export type IssueHistoryWidgetProps = {
    total: number;
    difference: number;
    numberOfDays: number;
    backgroundColor?: string;
    dataSet: IssueHistoryChartData[];
};

export type IssueHistoryData = {
    severity: number;
    first_seen: Date | string;
    last_seen: Date | string;
    resolved: Date | string | null;
    target_domain: string;
};

export type GroupedIssueHistoryData = {
    date: string;
    data: IssueHistoryData[];
};

export const DAYS_IN_PERIOD = 7;

export const CURR_PERIOD_STYLE_TEMPLATE: IssueHistoryChartData = {
    values: [],
    stroke: {
        style: "normal",
        width: "2",
    },
    label: "This 7 days",
    showValue: true,
    display: true,
};

export const PREV_PERIOD_STYLE_TEMPLATE: IssueHistoryChartData = {
    values: [],
    stroke: {
        style: "dot",
        width: "1",
    },
    label: "Last 7 days",
    showValue: false,
    display: true,
};
