import { format } from "date-fns";
import React from "react";
import { PaymentMethod } from "src/misc/codecs/stripe/PaymentMethod";
import { cardType } from "../Card/Card";
import { CardIcon } from "../Card/CardIcon";
import { styled } from '@mui/material/styles';

type Props = {
    method: PaymentMethod;
};

const StyledRoot = styled('div')({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "nowrap",
})

const StyledCardNumber = styled('p')({
    flexGrow: 2,
    marginLeft: "1rem",
});

const StyledCardExpiryDate = styled('p')({
    flexGrow: 2,
    textAlign: "right",
})

export const CardMenuItem: React.FC<Props> = ({ method }) => {
    return (
        <>
        { 
            method && method.object === "payment_method" && method.type === "card" ? (
                <StyledRoot>
                    <CardIcon height={40} type={cardType(method)} />
                    <StyledCardNumber>**** **** **** {method.card.last4}</StyledCardNumber>
                    <StyledCardExpiryDate>
                        {format(new Date(method.card.exp_year, method.card.exp_month-1), "MMM yyyy")}
                    </StyledCardExpiryDate>
                </StyledRoot>
            ) : <></>
        }
        </>
    );
};
