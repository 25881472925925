import React, { useCallback, useEffect, useMemo, useState } from "react";
import {IssueHistoryInSevenDays} from "src/features/issue-history/IssueHistoryInSevenDays";
import { endOfToday, formatRFC3339, startOfDay, sub } from "date-fns";
import { IssueHistoryData } from "../issue-history/const";
import { usePostgrest } from "src/services/postgrest-provider";
import { styled } from '@mui/material/styles';

const StyledRoot = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
});

const StyledTotal = styled("div")({
    flexGrow: 1,
    width: "100%",
});

/*
 * TODO: Use when new widgets [Tags, Domains] is implemented
 *
const StyledTags = styled("div")({
    width: "40%",
});

const StyledDomain = styled("div")({
    width: "60%",
});
*/


export const WeeklyReport: React.FC = () => {
    const postgrest = usePostgrest();

    const [data, setData] = useState<IssueHistoryData[]>([]);

    /**
     * Set date/time range
     */
    const today = useMemo(() => endOfToday(), []);
    // const today = useMemo(() => new Date(2022, 6, 14), []);
    // const today = new Date(2022, 2, 13);

    /**
     * Note:
     *  - Require 14 days records but getting 15 days instead.
     *    The reason for getting extra 1 day is used to calculate first remaining issues
     * - Today Remaining = Yesterday's total - Today's closed
     */
    const startOfLastPeriod = useMemo(() => startOfDay(sub(today, { weeks: 1, days: 6 })), [today]);

    const getIssuesData = useCallback(
        async (): Promise<IssueHistoryData[]> => {
            const encodedStartDate = encodeURIComponent(
                formatRFC3339(startOfLastPeriod)
            );
            const encodedEndDate = encodeURIComponent(formatRFC3339(today));

            const response = await postgrest.GetTableSlice(
                "issues",
                ["severity", "first_seen", "last_seen", "resolved", "target_domain"],
                `first_seen=gte.${encodedStartDate}&first_seen=lte.${encodedEndDate}&order=first_seen`
            );

            if (response.type === "success") {
                return response.data;
            } else {
                console.error("Failed retrieving data");
                return [];
            }
        },
        [postgrest, startOfLastPeriod, today]
    );

    useEffect(() => {
        const fetchData = async () => {
            // Retrieve all issues in database
            const issuesData = await getIssuesData();
            setData(issuesData);
        };

        fetchData().catch((error) => console.error(error));
    }, [getIssuesData]);

    return (
        <StyledRoot>
            <StyledTotal>
                {
                    data && <IssueHistoryInSevenDays
                        today={today}
                        startOfLastPeriod={startOfLastPeriod}
                        issuesData={data}
                    />
                }
            </StyledTotal>
            {/* Further widgets implement here */}
            {/* <StyledTags>Tags</StyledTags>
            <StyledDomain>
                <Typography variant="h2">By Domain</Typography>
                <IssueHistoryByDomainWidget today={today} startOfLastPeriod={startOfLastPeriod} data={data}/>
            </StyledDomain> */}
        </StyledRoot>
    );
}
