import { useTheme } from "@mui/material";
import React, { FC } from "react"
import { styled } from '@mui/material/styles';

type Props = {
    children: React.ReactNode
    padding?: number
}

const StyledRoot = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > * + *': {
        marginTop: theme.spacing(2),
    },
}))

export const Splash: FC<Props> = ({ children, padding = 0 }: Props) => {
    const theme = useTheme();
    return <StyledRoot style={{ padding: theme.spacing(padding) }}>
        {children}
    </StyledRoot>
}

export default Splash