import * as t from "io-ts";
import { CodecFields, Row, RowSlice, TableName } from "./types";
import { getCodec, getCodecSlice } from "./codec";
import { RangeCodec } from "src/misc/codecs/Range";
import { Api, parseData, parseHeaders, ApiResult } from "../api-provider";

export type PagedResult<T> = Promise<ApiResult<number, PageData<T>>>;

export type PageData<T> = {
    current: {
        number: number;
        data: T;
    },
    pages: Array<{
        number: number;
        load: () => Promise<ApiResult<number, PageData<T>>>;
    }>;
    total: number;
}

const HeadersCodec = t.interface({
    "content-range": RangeCodec,
});

export class Postgrest {

    private api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    /**
     * Query the given api table.
     * @param name The name of the api table to GET.
     * @param query Query parameters to append to the request URL. These can be any parameters supported by
     * https://postgrest.org/en/v7.0.0/api.html to perform filtering, sorting, etc. Adding the select key may
     * cause the API call to fail, as it may prevent expected columns from being returned.
     * @returns A tagged union containing either the response data or an error. The response data is an array
     * of objects matching the row type of the requested api table.
     */
    async GetTable<T extends TableName>(name: T, query?: string): Promise<ApiResult<number, Array<Row<T>>>> {
        const codec = t.array(getCodec(name));
        const url = `/${name}?${query}`;
        return this.api.Get(url).then(parseData(codec));
    }

    /**
     * Query the given subset of columns/fields from an api table.
     * @param name The name of the api table to GET.
     * @param fields The fields to select from the api table.
     * @param query Query paremeters to append to the request URL. These can be any parameters supported by
     * https://postgrest.org/en/v7.0.0/api.html to perform filtering, sorting, etc. Adding the select key
     * may cause the API call to fail, as it may prevent expected columns from being returned.
     * @returns A tagged union containing either the response data or an error. The response data is an array
     * of objects matching the requested subset of fields from row type of the requested api table.
     */
    async GetTableSlice<T extends TableName, K extends CodecFields<T>>(name: T, fields: K[], query?: string): Promise<ApiResult<number, Array<RowSlice<T, typeof fields[0]>>>> {
        const select = fields ? `&select=${fields.join(",")}` : "";
        const url = `/${name}?${query}${select}`;
        const codec = t.array(getCodecSlice(name, fields));
        return this.api.Get(url).then(parseData(codec));
    }

    async GetTablePaged<T extends TableName>(name: T, pageSize: number, query?: string): PagedResult<Array<Row<T>>> {
        return this.getTablePage(name, `/${name}?${query}`, pageSize, 1);
    }

    private async getTablePage<T extends TableName>(name: T, url: string, pageSize: number, pageNumber: number): PagedResult<Array<Row<T>>> {

        const codec = t.array(getCodec(name));

        return new Promise(resolve => {
            if (pageSize < 1) {
                return resolve({ type: "error", message: "page size must be > 0" });
            }
            if (pageNumber < 1) {
                return resolve({ type: "error", message: "page number must be > 0" });
            }

            const start = (pageNumber - 1) * pageSize;
            const end = (pageNumber * pageSize) - 1;

            this.api.Request({
                method: "GET",
                url: url,
                headers: {
                    "Range": `${start}-${end}`,
                    "Prefer": "count=exact",
                },
            }).then(parseHeaders(HeadersCodec)).then(parseData(codec)).then(res => {
                if (res.type === "error") {
                    return resolve(res);
                }
                const n = Math.ceil(res.headers["content-range"].total / pageSize);
                const pages = [...Array(n).keys()].map(x => x + 1);

                return resolve({
                    type: "success",
                    status: res.status,
                    headers: res.headers,
                    data: {
                        current: {
                            number: pageNumber,
                            data: res.data,
                        },
                        pages: pages.map(page => ({
                            number: page,
                            load: () => this.getTablePage(name, url, pageSize, page),
                        })),
                        total: res.headers["content-range"].total
                    },
                });
            }).catch((err: Error) => resolve({
                type: "error", message: err.message,
            }));
        });
    }
}