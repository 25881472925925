import React from "react";
import { Currency } from "src/misc/codecs";
import { styled } from '@mui/material/styles';

const StyledCurrency = styled('span')({
    verticalAlign: "super",
    fontSize: ".5em",
    letterSpacing: "-0.05em",
    marginLeft: "-0.05em",
})

const StyledSign = styled('span')({
    fontSize: ".85em",
})

type Props = {
    amount: number,
    currency: Currency,
}

export const Price: React.FC<Props> = ({ amount, currency }) => {
    return (
        <span>
            <StyledSign>{sign(currency)}</StyledSign>
            {formatAmount(amount, currency)}
            <StyledCurrency>{currency}</StyledCurrency>
        </span>
    );
}

const sign = (currency: Currency): string => {
    switch (currency) {
        case "USD":
        case "TWD":
        case "AUD":
            return "$";
        case "GBP":
            return "£";
        case "EUR":
            return "€$";
        default:
            return "";
    }
}

const formatAmount = (amount: number, currency: Currency): string => {
    switch (currency) {
    case "BIF":
    case "CLP":
    case "DJF":
    case "GNF":
    case "JPY":
    case "KMF":
    case "KRW":
    case "MGA":
    case "PYG":
    case "RWF":
    case "UGX":
    case "VND":
    case "VUV":
    case "XAF":
    case "XOF":
    case "XPF":
        // These are "zero-decimal" currencies: https://stripe.com/docs/currencies#zero-decimal
        // so we use the amount as is.
        return formatDecimal(amount, 1);
    default:
        // The rest are given in cents, so we give as a decimal number
        return formatDecimal(amount, 100);
    } 
}

const formatDecimal = (amount: number, cents: number): string => {
    const units = Math.floor(amount / cents)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    if (amount % cents === 0) {
        return `${units}`
    }
    
    const places = cents.toString().length - 1;
    let decimals = (amount % cents).toString();
    while (decimals.length < places) {
        decimals = "0" + decimals;
    }
    return `${units}.${decimals}`;
}
