import React from 'react';
import { Divider } from '@mui/material';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledLoadingWrapper = styled('div')({
    width: "100%",
    height: "100%",
    margin: "16px auto",
    borderRadius: "2px",
    "-webkit-box-shadow": "0px 1px 0px 0px rgba(0,0,0,0.08)",
    "-moz-box-shadow": "0px 1px 0px 0px rgba(0,0,0,0.08)",
    boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.08)",
});

export const WhatsNewLoading: React.FC = () => {
  return (
    <StyledLoadingWrapper>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton width="50%" animation="wave" />
      <Divider variant="middle" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </StyledLoadingWrapper>
  )
}
