import React from "react";
import { LeakCredentialsWithApi } from "./LeakCredentialsWithApi";
import { ExposedUsersWithApi } from "./ExposedUsersWithApi";
import { Tabs } from "src/elements/Tabs/Tabs";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    minHeight: "100%",
    padding: theme.spacing(2),
    background: theme.palette.background.default,
}))

export const SocialThreatIntelligenceReport: React.FC = () => (
    <StyledRoot>
        <Tabs tabs={["Leaked Credentials", "Exposed Users"]}>
            <LeakCredentialsWithApi />
            <ExposedUsersWithApi />
        </Tabs>
    </StyledRoot>
);
