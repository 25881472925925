import * as t from 'io-ts';
import { EpochSeconds } from 'src/misc/codecs';
import { LiteralUnion } from '../LiteralUnion';


// https://stripe.com/docs/api/payment_intents/object
export const PaymentIntentCodec = t.interface({
    amount: t.number,
    client_secret: t.string,
    created: EpochSeconds,
    status: LiteralUnion([
        "requires_payment_method",
        "requires_confirmation",
        "requires_action",
        "processing",
        "requires_capture",
        "canceled",
        "succeeded",
    ] as const),
});

export type PaymentIntent = t.TypeOf<typeof PaymentIntentCodec>;