import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Carousel from "react-material-ui-carousel";
import { WhatsNewRecord } from "./whats-new";
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Divider } from "@mui/material";
import ReactMarkdown from "react-markdown";

type Props = {
  whatsNewRecords: WhatsNewRecord[];
};

const StyledCarousel = styled(Carousel)(({ theme }) => ({
    width: "100%",
    height: "100%",
    color: theme.palette.text.primary
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
}));

const StyledTitleText = styled('div')({
    fontSize: "1rem",
    fontWeight: "bold",
    display: "block",
    height: "44px",
});

const StyledTimestampText = styled('span')({
    fontSize: "0.9rem",
    fontStyle: "italic",
    margin: "10px auto",
});

const StyledBodyText = styled('div')({
    height: "54px",
    fontSize: "0.8rem",
    textOverflow: "ellipsis",
    overflow: "hidden !important",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
});

const StyledReadMoreButton = styled(Button)({
    margin: "10px 0 0 0",
    left: "100%",
    transform: "translateX(-100%)",
});

export const WhatsNewCarousel: React.FC<Props> = ({
  whatsNewRecords,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalRecord, setModalRecord] = useState<null | WhatsNewRecord>(null);

  const handleClick = (record: WhatsNewRecord) => {
    setModalOpen(true);
    setModalRecord(record);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return <>
    <StyledCarousel
      stopAutoPlayOnHover={true}
      interval={10000}
      navButtonsAlwaysInvisible={true}
    >
      {whatsNewRecords.map((whatsNewRecord, i) => (
        <div key={i} data-testid="whats_new_carousel">
          <StyledTitleText>{whatsNewRecord.title}</StyledTitleText>
          <StyledTimestampText>
            {" —  " + whatsNewRecord.timestamp.toLocaleDateString()}
          </StyledTimestampText>
          <Divider variant="middle" />
          <StyledBodyText>{whatsNewRecord.body}</StyledBodyText>
          <StyledReadMoreButton
            variant="outlined"
            onClick={() => handleClick(whatsNewRecord)}
          >
            Read more
          </StyledReadMoreButton>
        </div>
      ))}
    </StyledCarousel>
    <Dialog
      aria-labelledby="whats-new-dialog-title"
      open={modalOpen}
      onClose={handleClose}
    >
      <StyledDialogTitle id="customized-dialog-title">
        {modalRecord ? modalRecord.title : ""}
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <ReactMarkdown>
          {modalRecord ? modalRecord.body : ""}
        </ReactMarkdown>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </StyledDialogActions>
    </Dialog>
  </>;
};
