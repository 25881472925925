import { Stripe } from "@stripe/stripe-js";
import { Subscription, SubscriptionCodec } from "src/misc/codecs/stripe/Subscription";
import { Api, parseData } from "src/services/api-provider";

export type SubmitResult =
    | { type: "error", message: string, updated?: Subscription | null }
    | { type: "ok", updated: Subscription };

export type PaymentHandler = (cardID: string) => Promise<SubmitResult>;

export const paymentHandler = async (
    subscription: Subscription | null,
    priceID: string | undefined,
    cardID: string,
    stripe: Stripe | null,
    api: Api,
): Promise<SubmitResult> => {

    if (!stripe) {
        return { type: "error", message: "payment system unavailable, please try again later" };
    }

    const plan = subscription ? subscription.plan.id : priceID;

    // If the subscription is in an unpaid state, then we want to delete and recreate it.
    const deleteFirst = subscription && subscription.status === "unpaid";

    if (deleteFirst) {
        const result = await api.Delete("/api/stripe/subscription");
        if (result.type === "error") {
            return { type: "error", message: "couldn't recreate subscription" };
        }
    }

    // Create or update the requested stripe subscription via our backend.
    const sub = await api.Put("/api/stripe/subscription", {
        price_id: plan,
        payment_method_id: cardID,
    }).then(parseData(SubscriptionCodec));

    if (sub.type === "error") {
        return {
            type: "error",
            message: "couldn't create subscription",
            updated: deleteFirst ? null : undefined,
        };
    }

    // If confirmation is not required, we are done.
    const intent = sub.data.latest_invoice.payment_intent;
    if (!intent || !requiresConfirmation(intent.status)) {
        return { type: "ok", updated: sub.data };
    }

    // Confirm the payment with stripe.
    const { error } = await stripe.confirmCardPayment(intent.client_secret, {
        payment_method: cardID,
        setup_future_usage: "off_session",
    });
    if (error !== undefined) {
        return {
            type: "error",
            message: error.message || "couldn't confirm payment",
            updated: sub.data,
        };
    }

    // Confirming with stripe will change the subscription status, so get the updated
    // version.
    const updated = await api.Get("/api/stripe/subscription").then(parseData(SubscriptionCodec));
    if (updated.type === "error") {
        return {
            type: "error",
            message: "couldn't fetch updated subscription data, reload the app to see latest data",
            updated: sub.data,
        };
    } else {
        return { type: "ok", updated: updated.data };
    }
}

type PaymentIntentStatus = Exclude<Subscription["latest_invoice"]["payment_intent"], null>["status"];

const requiresConfirmation = (status: PaymentIntentStatus): boolean => {
    switch (status) {
    case "canceled":
    case "processing":
    case "succeeded":
        return false;
    case "requires_action":
    case "requires_capture":
    case "requires_confirmation":
    case "requires_payment_method":
        return true;
    }
}