import React from "react";
import { IssueHistoryChartData } from "src/features/issue-history/IssueHistory";
import { Legend } from "./Legend";
import { PADDING } from "./utils";

/** @typedef {object} LegendsProps React props for `<Legends />` component*/
type LegendsProps = {
    /**
     * DataSet of line chart data
     */
    dataSet: IssueHistoryChartData[];
}

/**
 * Legends section locates under chart
 *
 * @param {LegendsProps} {
 *     dataSet,
 * }
 * @return {JSX.Element} 
 */
export const Legends: React.FC<LegendsProps> = ({
    dataSet,
}) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexWrap: "wrap",
                margin: `0 ${PADDING}px`,
            }}
        >
            {dataSet.map((data, index) => {
                return (
                    <Legend key={index} data={data} />
                )
            })}
        </div>
    )
}