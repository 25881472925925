import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { FC } from "react";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
}))

type Props = {
    icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">>,
    children: React.ReactNode,
}

export const Notice: FC<Props> = ({ icon: Icon, children }) => (
    <StyledRoot>
        <Icon fontSize="small" />
        <span>{children}</span>
    </StyledRoot>
)