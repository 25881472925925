import { FormControlLabel, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import React, {useContext} from "react";
import { useAuth } from "../../services/auth-provider/AuthProvider";
import { LayoutContext } from "./LayoutProvider";
import { styled } from '@mui/material/styles';


const StyledRoot = styled('div')({
    display: 'flex',
    alignSelf: 'stretch',
})

type Props = {
    className?: string;
}

export const Controls: React.FC<Props> = ({ className }: Props) => {
    return (
        <StyledRoot className={className}>
            <DarkModeSwitch/>
            <div style={{ flexGrow: 1 }}></div>
            <LogoutButton/>
        </StyledRoot>
    );
}


export const DarkModeSwitch: React.FC = () => {
    const { darkMode, toggleDarkMode } = useContext(LayoutContext);

    return (
        <FormControlLabel 
            control={<Switch size="small" checked={darkMode} onChange={toggleDarkMode} name="Dark Mode" />}
            label={<Typography variant="caption">Dark Mode</Typography>}
        />
    )
}

export const LogoutButton: React.FC = () => {
    const auth = useAuth();
    return (
        <Tooltip title="Logout">
            <IconButton size="small" color="inherit" onClick={() => auth.logout()}>
                <ExitToApp fontSize="small"/>
            </IconButton>
        </Tooltip>
    );
}