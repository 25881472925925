import React, { useEffect, useState } from "react";
import { RequestStatus } from "../../../shared/enums";
import { WhatsNew } from "./WhatsNew";
import { WhatsNewRecord } from "./whats-new";
import { usePostgrest } from "src/services/postgrest-provider";

export const WhatsNewWithApi: React.FC = () => {
  const postgrest = usePostgrest();
  const [reqStatus, setReqStatus] = useState(RequestStatus.Pending);
  const [whatsNewRecords, setWhatsNewRecords] = useState<WhatsNewRecord[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getData(), []);

  const getData = () => {
    postgrest
      .GetTableSlice("whats_new", ["timestamp", "title", "body"], "order=timestamp.desc&limit=5")
      .then((res) => {
        if (res.type === "success") {
          setReqStatus(RequestStatus.Success);
          setWhatsNewRecords(res.data);
        } else {
          console.error("error fetching what's new", res.message);
          setReqStatus(RequestStatus.Error);
        }
      })
  };
  return (
    <WhatsNew status={reqStatus} data={whatsNewRecords} getData={getData} />
  );
};
