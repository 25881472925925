import { IssueHistoryChartData } from "src/features/issue-history/IssueHistory";
import { Coordinate, LabelData, Size } from "./const";


export const DASHARRAY = 4;
export const PADDING = 20;
export const RADIUS = 2;
export const LEGEND_LABEL_HEIGHT = 50;

/**
 * Finding the maximum value of all data sets
 * This is used to calculate the highest point in the line charts
 *
 * @param {IssueHistoryChartData[]} dataSet
 * @return {*}  {number}
 */
export const findMaxValue = (dataSet: IssueHistoryChartData[]): number => {
    let max = 0;

    dataSet.forEach(data => {
        const dataMax = Math.max(...data.values);
        if (dataMax > max) {
            max = dataMax;
        }
    })

    return Math.max(max, 1);
}

/**
 * Base on data and svg's size to calculate coordinates of each point
 *
 * @param {number[]} data Data values
 * @param {number} max Maximum value of all data sets
 * @param {Size} size Size of <svg>
 * @param {number} xGap The horizontal distance between each point/coordinate
 * @return {Coordinate[]} Set of coordinates to draw in svg
 */
export const getCoordinates = ( data: number[], max: number, size: Size, xGap: number ): Coordinate[] => {
    if (data.length > 0) {
        const points: Coordinate[] = [];
        data.forEach((value, index) => {
            points.push({
                x: xGap * index + PADDING,
                y:
                    (1 - value / max)                           // Calculate the percentage occupied of y value
                    * (size.height - LEGEND_LABEL_HEIGHT)       // Reduce label height to avoid overflow
                    + 2 * PADDING,                              // Shift down with padding
            });
        });

        return points;
    }
    return [];
}


/**
 * Convert coordinates to point array to paint <polyline>
 *
 * @param {Coordinate[]} coordinates
 * @return {string} Points value for painting in <polyline>
 */
export const getPoints = (coordinates: Coordinate[]): string => {
    return coordinates.map(coordinate => `${coordinate.x},${coordinate.y}`).join(' ');
}

/**
 * Converting number to string with `K/M/G`
 *
 * @param {number} value
 * @return {string} Number with `K/M/G`
 */
export const formatCount = (value: number): string => {
    if (Math.abs(value) >= 1000000000) {
        return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (Math.abs(value) >= 1000000) {
        return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (Math.abs(value) >= 1000) {
        return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return value.toString();
} 

/**
 * Wrap debouncing with callback function
 *
 * @param {() => void} callback
 * @param {number} delay Delay calling in milliseconds
 * @return {*}  {() => void}
 */
export const debounce = (callback: () => void, delay:number): () => void => {
    let inDebounce: ReturnType<typeof setTimeout>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function (this:any, ...args: any) {
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => callback.apply(this, args), delay);
    };
};

/**
 * Combine coordinates of labels and values of labels for painting labels in svg chart
 *
 * @param {Coordinate[]} coordinates - Array of coordinate for painting labels in svg
 * @param {(number[] | string[])} values - Array of data
 * @return {LabelData[]} Array of `LabelData` for painting labels on svg
 */
export const generateLabelData = (coordinates: Coordinate[], values: number[] | string[]): LabelData[] => {
    const labelData: LabelData[] = [];

    if (coordinates.length !== values.length) {
        return labelData;
    }

    coordinates.map((coordinate, index) => {
        labelData.push({...coordinate, value: values[index]});
    })

    return labelData;
}