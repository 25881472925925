import * as t from 'io-ts';
import { CurrencyCodec } from 'src/misc/codecs';
import { LiteralUnion } from '../LiteralUnion';
import { PaymentIntentCodec } from './PaymentIntent';


// https://stripe.com/docs/api/invoices/object
export const InvoiceCodec = t.interface({
    amount_due: t.number,
    amount_paid: t.number,
    amount_remaining: t.number,
    currency: CurrencyCodec,
    payment_intent: t.union([t.null, PaymentIntentCodec]),
    status: LiteralUnion([
        "draft",
        "open",
        "paid",
        "uncollectible",
        "void"
    ] as const),
});

export type Invoice = t.TypeOf<typeof InvoiceCodec>;