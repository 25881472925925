import React from "react";
import { styled } from '@mui/material/styles';

type Color = "primary" | "secondary" | "initial" | "inherit" | "error";

export type Props = {
    color?: Color;
    onClick: () => void;
    className?: string;
    children: React.ReactNode;
}

type StyledProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    color: string;
}

const StyledButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'color',
})<StyledProps>(({ color, theme }) => ({
    border: "none",
    background: "none",
    padding: 0,
    color: theme.palette.primary.main,
    font: "inherit",
    letterSpacing: "inherit",
    display: "inline",
    boxSizing: "content-box",
    height: "fit-content",
    cursor: "pointer",
    textAlign: "start",
    "&:hover": {
        textDecoration: "underline",
    },
    ...(color === 'primary' && { color: theme.palette.primary.main}
        || color === 'secondary' && { color: theme.palette.supplementary.main }
        || color === 'initial' && { color: "blue" }
        || color === 'inherit' && { color: 'inherit' }
        || color === 'error' && { color: theme.palette.error.main }),
}));

export const LinkButton: React.FC<Props> = ({ onClick, color = "primary", children, className }) => {
    return (
        <StyledButton onClick={onClick} color={color} className={className}>
            {children}
        </StyledButton>
    );
}