import React, { useCallback, useState } from "react";
import {
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineItemProps,
    TimelineSeparator,
} from "@mui/lab";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { WelcomeStepIndex } from "src/shared/enums";
import { useSubscription } from "../../services/subscription-provider/SubscriptionProvider";
import { useApi } from "../../services/api-provider/ApiProvider";
import { styled } from '@mui/material/styles';

type Props = {
    step: number;
};

const StyledTimelineContent = styled(TimelineContent)({
    minWidth: "100%",
    maxWidth: "100%",
});

const StyledTimelineSeparator = styled(TimelineSeparator)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
}));

const StyledDescription = styled(TimelineSeparator)(({ theme }) => ({
    margin: `${theme.spacing(0.8)} 0 ${theme.spacing(1.5)} 0`,
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    width: "90%",
    fontSize: "0.9rem",
}));

const StyledButtonRow = styled('div')(({ theme }) => ({
    display: "flex",
    marginTop: theme.spacing(1),
    alignItems: "center",
    "& > * + *": {
        marginLeft: theme.spacing(1),
    }
}));

const StyledFooter = styled(Typography)(({ theme }) => ({
    fontSize: "0.8rem",
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(9),
    "& a, & a:visited": {
        color: `${theme.palette.text.disabled} !important`,
    },
}))

type StyledInactiveTimelineItemProps = TimelineItemProps & {
    isActiveStep: boolean;
}

const StyledTimelineItem = styled(TimelineItem, {
    shouldForwardProp: (prop) => prop !== 'isActiveStep',
})<StyledInactiveTimelineItemProps>(({ isActiveStep, theme }) => ({
    ...(!isActiveStep && { color: theme.palette.text.disabled })
}))

export const WelcomeCompleted: React.FC<Props> = ({ step }) => {
    const isActiveStep = step === WelcomeStepIndex.Completed;
    const subscription = useSubscription();
    const api = useApi();
    const [dismissState, setDismissState] = useState<"none" | "pending" | "failed">("none");

    const dismiss = useCallback(() => {
        setDismissState("pending");
        api.Patch(`/subscriptions?uuid=eq.${subscription.current.uuid}`, {
            show_welcome: false,
        }).then(res => {
            if (res.type === "success") {
                subscription.refresh();
            } else {
                console.error("Update set_welcome", res.message);
                setDismissState("failed");
            }
        });
    }, [subscription, api]);

    return (
        <StyledTimelineItem isActiveStep={isActiveStep}>
            <StyledTimelineSeparator>
                <TimelineDot
                    color={isActiveStep ? "primary" : "grey"}
                    variant={isActiveStep ? "filled" : "outlined"}
                ></TimelineDot>
            </StyledTimelineSeparator>
            <StyledTimelineContent>
                <Typography variant="h6" component="div">
                    <Box component="span" fontWeight="fontWeightBold">
                        Continuous Threat Monitoring
                    </Box>
                </Typography>
                <StyledDescription>
                    On a continual basis, our predictive threat engine gathers the
                    latest threat exposure information for your organization, allowing
                    you to gauge your current and historical risk.
                </StyledDescription>
                { isActiveStep && <>
                     <Typography>
                        Depending on your organization&apos;s current threat exposure, you will begin
                        seeing issues in your dashboard shortly.
                    </Typography>
                    
                    <StyledButtonRow>
                        <Button disabled={dismissState === "pending"} color="primary" variant="contained" onClick={dismiss}>
                            Continue
                        </Button>
                        { dismissState === "pending" && <CircularProgress size="1rem" /> }
                        { dismissState === "failed" && (
                            <Typography variant="body2">Request failed, please try again.</Typography>
                        )}
                    </StyledButtonRow>
                    <StyledFooter>
                        Or would you like to{" "}
                        <Link to="/target-domains">add another domain</Link>.
                    </StyledFooter>
                </>}
            </StyledTimelineContent>
        </StyledTimelineItem>
    );
};
