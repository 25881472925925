import { useTheme } from "@mui/material";
import React from "react";
import { IssueHistoryChartData } from "src/features/issue-history/IssueHistory";
import { DASHARRAY } from "./utils";

/** @typedef {object} LegendProps React props for `<Legend />` component*/
type LegendProps = {
    /**
     * One set of chart data
     */
    data: IssueHistoryChartData;
}

/**
 * Single Legend in Legends section
 *
 * @param {LegendProps} {
 *     data,
 * }
 * @return {JSX.Element} 
 */
export const Legend: React.FC<LegendProps> = ({
    data,
}) => {
    const theme = useTheme();
    return (
        <div style={{ 
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "nowrap",
        }}>
            <svg width="40" height="25">
                <polyline
                    x="0"
                    y="25"
                    strokeWidth="2"
                    stroke={data.stroke.color || theme.palette.text.primary}
                    strokeDasharray={data.stroke.style === "dot" ? DASHARRAY : 0}
                    points="15,12.5 30,12.5"
                />
            </svg>
            <span>{data.label}</span>
        </div>
    )
}