import React from 'react';
import './App.css';
import { AuthProvider } from './services/auth-provider/AuthProvider';
import { BrowserRouter as Router, Navigate, Route, Routes, useParams } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import { SubscriptionPage } from './features/subscriptions/SubscriptionPage';
import { TargetDomainsPage } from './features/target-domains/TargetDomainsPage';
import SubscriptionProvider from './services/subscription-provider/SubscriptionProvider';
import Dashboard from './features/dashboard/Dashboard';
import { Layout } from './features/layout/Layout';
import { BasicLayout } from './features/layout/BasicLayout';
import { ThreatExposurePage } from './pages/ThreatExposurePage';
import { FullReport } from './features/full-report/orgamisms/FullReport';
import { AcceptInvitationWrapper } from './features/invitations/AcceptInvitationWrapper';
import { LayoutProvider } from './features/layout/LayoutProvider';
import { Payment } from './features/payment/Payment';
import { ApiProvider } from './services/api-provider';
import { PostgrestProvider } from './services/postgrest-provider';
import { IssueTablePage } from './pages/IssueTablePage';
import { NoticeProvider } from './services/notice-provider/NoticeProvider';
import { AttackSurfaceReport } from './features/full-report/orgamisms/AttackSurfaceReport';
import { DigitalSupplyChainReport } from './features/full-report/orgamisms/DigitalSupplyChainReport';
import { SocialThreatIntelligenceReport } from './features/full-report/orgamisms/SocialThreatIntelligenceReport';

const Invite = () => {
  const { code } = useParams();
  return (
    <AcceptInvitationWrapper code={code || ""}/>
  );
}

export default function App(): JSX.Element {
  return (
      <Services>
          <Routes>
              <Route path="invitation/:code" element={
                  <BasicLayout>
                      <Invite/>
                  </BasicLayout>
                  }/>
              <Route path="*" element={
                  <SubscriptionProvider>
                      <Layout>
                          <Routes>
                              <Route path="*" element={<Navigate to="dashboard"/>}/>
                              <Route path="dashboard/*" element={<Dashboard />}/>
                              <Route path="full-report/*">
                                  <Route path=":id" element={<FullReport/>}/>
                                  <Route path="*" element={<FullReport/>}/>
                              </Route>
                              <Route path="attack-surface-exposure/*" element={<AttackSurfaceReport/>} />
                              <Route path="digital-supply-chain/*" element={<DigitalSupplyChainReport/>} />
                              <Route path="social-threat-intelligence/*" element={<SocialThreatIntelligenceReport/>} />
                              <Route path="target-domains/*" element={<TargetDomainsPage/>}/>
                              <Route path="users/*" element={<SubscriptionPage/>}/>
                        <Route path="threat-exposure/*" element={<ThreatExposurePage/>}/>
                              <Route path="plans/*" element={<Payment/>}/>
                              <Route path="issues/*" element={<IssueTablePage/>}/>
                          </Routes>
                      </Layout>
                  </SubscriptionProvider>
                  }/>
          </Routes>
      </Services>
  );
}

type ServicesProps = {
    children: React.ReactNode,
}

const Services: React.FC<ServicesProps> = ({ children }) => (
  <NoticeProvider>
    <LayoutProvider>
      <CssBaseline/>
      <Router>
        <AuthProvider>
          <ApiProvider>
            <PostgrestProvider>
              {children}
            </PostgrestProvider>
          </ApiProvider>
        </AuthProvider>
      </Router>
    </LayoutProvider>
  </NoticeProvider>
);
