import { Typography } from "@mui/material";
import React, { Fragment } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LanguageIcon from "@mui/icons-material/Language";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { PlanExpandProduct } from "src/misc/codecs/stripe/Plan";
import { MakePricingTable } from "./planData";
import { styled } from '@mui/material/styles';

type Props = {
    plans: PlanExpandProduct[];
    activePlan?: string;
};

const StyledTableRow = styled('tr')({
    border: "none !important",
    "& > td": {
        borderTop: "none",
        borderBottom: "none",
    }
})

const StyledIconText = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    "& > * + *": {
        marginLeft: theme.spacing(1),
    }
}))

const StyledCategoryTableRow = styled('tr')(({ theme }) => ({
    color: "#EA3D25",
    height: "5rem !important",
    "& td": {
        verticalAlign: "bottom",
        height: "100%",
        minHeight: "2.5rem",
        alignItems: "flex-end",
        paddingBottom: theme.spacing(1),
    },
}))

type StyledTableDataProps = React.TableHTMLAttributes<HTMLTableCellElement> & {
    isActive: boolean;
}


const StyledTableData = styled('td', {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<StyledTableDataProps>(({ isActive, theme }) => ({
    ...(isActive && {
        backgroundColor: theme.palette.action.disabledBackground,
    })
}))

export const PlanTableBody: React.FC<Props> = ({ 
    plans,
    activePlan,
}) => {
    const { users, domains, featureRows, featureCategoryRows } = MakePricingTable(plans);
    const activeIndex = plans.findIndex(plan => plan.id === activePlan);
    const active = (index: number) => index === activeIndex;
    return (
        <tbody>
            <StyledTableRow>
                <td>
                    <StyledIconText>
                        <PersonOutlineIcon />
                        <Typography variant="body2" component="span">Users</Typography>
                    </StyledIconText>
                </td>
                {users.map((value, i) => (
                    <StyledTableData key={i} isActive={active(i)}>{value}</StyledTableData>
                ))}
            </StyledTableRow>
            <StyledTableRow>
                <td>
                    <StyledIconText>
                        <LanguageIcon />
                        <Typography variant="body2" component="span">Domains</Typography>
                    </StyledIconText>
                </td>
                {domains.map((value, i) => (
                    <StyledTableData key={i} isActive={active(i)}>{value}</StyledTableData>
                ))}
            </StyledTableRow>
            {featureRows.map((row, i) => (
                <tr key={i}>
                    <td><Typography variant="body2" component="span">{row.name}</Typography></td>
                    {row.columns.map((col, i) => (
                        <StyledTableData key={i} isActive={active(i)}>
                            { col && <CheckOutlinedIcon style={{fontSize: "1.2em"}} /> }
                        </StyledTableData>
                    ))}
                </tr>
            ))}
            {featureCategoryRows.map((category, i) => (
                <Fragment key={i}>
                    <StyledCategoryTableRow>
                        <td>
                            <Typography variant="h3">
                                {category.category}
                            </Typography>
                        </td>
                        {plans.map((_, i) => (<StyledTableData key={i} isActive={active(i)} />))}
                    </StyledCategoryTableRow>
                    {category.rows.map((row, i) => (
                        <tr key={i}>
                            <td>
                                <Typography variant="body2" component="span">
                                    {row.name}
                                </Typography>
                            </td>
                            {row.columns.map((col, i) => (
                                <StyledTableData key={i} isActive={active(i)}>
                                    { col && <CheckOutlinedIcon style={{fontSize: "1.2em"}} /> }
                                </StyledTableData>
                            ))}
                        </tr>
                    ))}
                </Fragment>
            ))}
        </tbody>
    );
};

