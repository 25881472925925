import { either } from "fp-ts/Either";
import * as t from "io-ts";

export class Range {
    private _start: number;
    private _end: number;
    private _total: number;

    get start(): number {
        return this._start;
    }

    get end(): number {
        return this._end;
    }

    get total(): number {
        return this._total;
    }

    toString(): string {
        return `${this.start}-${this.end}/${this.total}`;
    }

    constructor(s: string) {
        const [_range, _total] = s.split("/");
        const [_start, _end] = _range === "*" ? ["0", "0"] : _range.split("-");
        const total = Number(_total);
        const start = Number(_start);
        const end = Number(_end);

        if (!Number.isInteger(total) || !Number.isInteger(start) || !Number.isInteger(end)) {
            throw "expect range to be in format '%d-%d/%d'";
        }

        if (end < start) {
            throw `end (${end}) < start (${start})`;
        }

        if (total < end) {
            throw `total (${total}) < end (${end})`;
        }

        this._start = start;
        this._end = end;
        this._total = total;
    }
}

export const RangeCodec = new t.Type<Range, string, unknown>(
  'Range',
  (u): u is Range => u instanceof Range,
  (u, c) => either.chain(t.string.validate(u, c), (s) => {
        try {
            return t.success(new Range(s));
        } catch (err: unknown) {
            return t.failure(u, c, typeof err === "string" ? err : undefined);
        }
    }),
  (a) => a.toString()
);