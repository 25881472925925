import { add, endOfDay, startOfDay } from "date-fns";
import { StrokeStyle } from "src/elements/Charts/LineChart/const";
import {CURR_PERIOD_STYLE_TEMPLATE, GroupedIssueHistoryData, IssueHistoryData, PREV_PERIOD_STYLE_TEMPLATE} from "./const";
import { IssueHistoryChartData } from "./IssueHistory";

export const isCriticalRiskIssue = (data: IssueHistoryData): boolean => {
    return data.severity >= 9;
}

export const isHighRiskIssue = (data: IssueHistoryData): boolean => {
    return data.severity >= 7 && data.severity < 9;
}


export const isMediumRiskIssue = (data: IssueHistoryData): boolean => {
    return data.severity >= 4 && data.severity < 7;
}

export const isLowRiskIssue = (data: IssueHistoryData): boolean => {
    return data.severity >= 1 && data.severity < 4;
}

export const isClosedIssue = (data: IssueHistoryData): boolean => {
    return data.resolved !== null;
}

export const isOpenedIssue = (data: IssueHistoryData): boolean => {
    return !isClosedIssue(data);
}

const filterData = (
    data: GroupedIssueHistoryData[],
    severity: "critical" | "high" | "medium" | "low"
): GroupedIssueHistoryData[] => {
    const result: GroupedIssueHistoryData[] = [];

    data.forEach((item) => {
        const filteredData = Object.assign(
            {},
            {
                ...item,
                data: item.data.filter((issue) => {
                    if (severity === "critical") {
                        return isCriticalRiskIssue(issue);
                    } else if (severity === "high") {
                        return isHighRiskIssue(issue);
                    } else if (severity === "medium") {
                        return isMediumRiskIssue(issue);
                    } else {
                        return isLowRiskIssue(issue);
                    }
                }),
            }
        );
        result.push(filteredData);
    });

    return result;
};

export const filterCriticalRiskData = (
    data: GroupedIssueHistoryData[]
): GroupedIssueHistoryData[] => {
    return filterData(data, "critical");
};

export const filterHighRiskData = (
    data: GroupedIssueHistoryData[]
): GroupedIssueHistoryData[] => {
    return filterData(data, "high");
};

export const filterMediumRiskData = (
    data: GroupedIssueHistoryData[]
): GroupedIssueHistoryData[] => {
    return filterData(data, "medium");
};

export const filterLowRiskData = (
    data: GroupedIssueHistoryData[]
): GroupedIssueHistoryData[] => {
    return filterData(data, "low");
};

export const isTotalData = (issue: IssueHistoryData, targetDate: Date): boolean => {
    return (
        new Date(issue.first_seen) <= startOfDay(targetDate) &&
        (issue.resolved === null ||
            new Date(issue.resolved) >= endOfDay(targetDate))
    );
};

export const isNewData = (issue: IssueHistoryData, targetDate: Date): boolean => {
    return (
        new Date(issue.first_seen) >= startOfDay(targetDate) &&
        new Date(issue.first_seen) <= endOfDay(targetDate)
    );
};

export const isRemainingData = (
    issue: IssueHistoryData,
    targetDate: Date
): boolean => {
    return (
        new Date(issue.first_seen) <= startOfDay(targetDate) &&
        (issue.resolved === null ||
            new Date(issue.resolved) > endOfDay(targetDate))
    );
};

export const isClosedData = (issue: IssueHistoryData, targetDate: Date): boolean => {
    return (
        issue.resolved !== null &&
        new Date(issue.resolved) >= startOfDay(targetDate) &&
        new Date(issue.resolved) <= endOfDay(targetDate)
    );
};




export const getDateList = (
    startDate: Date,
    endDate: Date
): { dateString: string; date: Date }[] => {
    const dateList: { dateString: string; date: Date }[] = [];

    let targetDate = startDate;

    while (targetDate <= endDate) {
        const dateString = targetDate.toLocaleDateString();
        dateList.push({ dateString: dateString, date: targetDate });
        targetDate = add(targetDate, { days: 1 });
    }

    return dateList;
};


/**
 * Get line style base on category
 * @param type Data type represent [current] or [previous]
 * @param isSeverity Determine if line is for severity
 * @returns Line style for rendering line in chart
 */
export const getLineStyle = (
    type: "current" | "previous",
    isSeverity: boolean
): IssueHistoryChartData => {
    const currPeriodStroke: StrokeStyle = {
        color: isSeverity ? "#0E151B" : "",
        style: "normal",
        width: "2",
    };
    const prevPeriodStroke: StrokeStyle = {
        color: isSeverity ? "#0E151B" : "",
        style: "dot",
        width: "1",
    };

    if (type === "current") {
        return Object.assign({}, CURR_PERIOD_STYLE_TEMPLATE, {
            stroke: currPeriodStroke,
        });
    } else {
        return Object.assign({}, PREV_PERIOD_STYLE_TEMPLATE, {
            stroke: prevPeriodStroke,
        });
    }
};

/**
 * Split data of whole period into two arrays
 * @param data Counts of data in time range
 * @returns Two separated data represented every 7 days data
 */
export const divideData = (data: number[]): [number[], number[]] => {
    const middleIndex = Math.ceil(data.length / 2);
    const prevPeriodData = data.slice().splice(0, middleIndex);
    const currPeriodData = data.slice().splice(-middleIndex);

    return [prevPeriodData, currPeriodData];
};

