import React, { useEffect, useState } from 'react';
import { usePostgrest } from 'src/services/postgrest-provider';
import { RequestStatus } from "src/shared/enums";
import { DetectedServiceProvider } from '../services/full-report';
import { ServiceProviders } from './ServiceProviders';

export const ServiceProvidersWithApi:React.FC = () => {
  const postgrest = usePostgrest();
  const [reqStatus, setReqStatus] = useState(RequestStatus.Pending);
  const [data, setData] = useState([] as DetectedServiceProvider[]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getData(), []);

  const getData = () => {
    postgrest
      .GetTable('service_providers')
      .then((res) => {
        if (res.type === "success"){
          setReqStatus(RequestStatus.Success);
          setData(res.data);
        } else if (res.type === "error") {
          console.error(res.message);
          setReqStatus(RequestStatus.Error);
        }
      })
  }
  return (
    <ServiceProviders data={data} status={reqStatus} />
  )
}