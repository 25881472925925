import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import React, { createContext, useMemo } from "react";
import { dark, light } from "src/theme";
import createPersistedState from "use-persisted-state";
import '@mui/styles';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


type LayoutInfo = {
    // Light/Dark Mode
    darkMode: boolean;
    setDarkMode: (value: boolean) => void;
    toggleDarkMode: () => void;
};

type LayoutProviderProps = {
    children: React.ReactNode,
};

const defaultState = {
    darkMode: true,
};

const useDarkMode = createPersistedState("dark-mode");

export const LayoutContext = createContext<Partial<LayoutInfo>>(defaultState);

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
    const [darkMode, setDarkMode] = useDarkMode(defaultState.darkMode);

    const toggleDarkMode = () => setDarkMode(!darkMode);

    const themeOptions = darkMode ? dark : light;
    const theme = useMemo(() => createTheme(themeOptions), [themeOptions]);

    return (
        <LayoutContext.Provider
            value={{
                darkMode: !!darkMode,
                setDarkMode: setDarkMode,
                toggleDarkMode: toggleDarkMode,
            }}
        >
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </StyledEngineProvider>
        </LayoutContext.Provider>
    );
};
