import { either } from "fp-ts/Either";
import { Type, success, failure, number } from "io-ts";

export const EpochSeconds = new Type<Date, number, unknown>(
    'EpochSeconds',
    (v): v is Date => v instanceof Date,
    (v, c) => either.chain(number.validate(v, c), n => {
        const d = new Date(n * 1000);
        return isNaN(d.getTime()) ? failure(v, c) : success(d)
    }),
    date => Math.round(date.getTime() / 1000)
);