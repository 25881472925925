import React, { FC } from 'react';
import { ExposureMap } from '../features/exposure-map/ExposureMap';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')({
    width: '100%',
    height: '100%',
})

export const ThreatExposurePage: FC = () => {
  return (
      <StyledRoot>
          <ExposureMap />
      </StyledRoot>
  );
}
