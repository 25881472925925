import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { styled } from '@mui/material/styles';

type ReportErrorDialogProps = {
    hasError: boolean;
};

const StyledModal = styled(Dialog)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
})

export const ReportErrorDialog: React.FC<ReportErrorDialogProps> = ({
    hasError,
}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (hasError) {
            handleOpen();
        }
    }, [hasError]);

    return (
        <StyledModal
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTitle id="alert-dialog-title">
                {"Download Error"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Report cannot be downloaded at this moment. <br />
                    <br />
                    Please try again later.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    Close
                </Button>
            </DialogActions>
        </StyledModal>
    );
};
