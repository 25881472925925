import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import { LineChart } from 'src/elements/Charts/LineChart/LineChart';
import { StrokeStyle } from 'src/elements/Charts/LineChart/const';
import { formatCount } from 'src/elements/Charts/LineChart/utils';
import { styled } from '@mui/material/styles';


/** 
 * @typedef {object} IssueHistoryChartData
*/
export type IssueHistoryChartData = {
    /**
     * The label/category of single data set
     */
    label: string;
    /**
     * Dataset to be drawn in line chart SVG
     */
    values: number[];
    /**
     * The stroke style of single line in chart
     */
    stroke: StrokeStyle;
    /**
     * Determine the value is displayed or not in chart
     */
    showValue: boolean | "onChanged";
    /**
     * Display/hide the line in chart
     */
    display: boolean;
}

/**
 * @typedef {object} Props - React props for Component [IssueHistory]
 */
type Props = {
    /**
     * Title of current IssueHistory widget, locate on top right corner
     *  - string: Single line title
     *  - string[]: Multiple line title with one line each item
     */
    title: string | string[];
    /**
     * Subtitle under title section
     *  - string: Single line title
     *  - string[]: Multiple line title with one line each item
     */
    subtitle?: string | string[];
    /**
     * Total number of issues
     */
    total: number;
    /**
     * Difference in number compared with last period
     */
    difference: number;
    /**
     * Description of difference with last period
     *  - string: Single line description
     *  - string[]: Multiple line description with one line each item
     */
    differenceDescription: string | string[];
    /**
     * Determine if difference number/symbol using theme color or red/green
     */
    differenceWithColor?: boolean;
    /**
     * Background color
     */
    backgroundColor?: string;
    /**
     * Text color
     */
    color?: string;
    /**
     * Determine the visibility of the baseline
     */
    showBaseline?: boolean;
    /**
     * All data for drawing lines in chart
     */
    dataSet: IssueHistoryChartData[];
}

/**
 * @Component Render single line Typography string or multiple lines with string array
 *
 * @param {({text: string | string[], variant?: "inherit" | Variant, className: string})} {text, variant, className}
 * @return {JSX.Element} 
 */
export const Text = ({
    text,
    variant,
    className,
}: {
    text: string | string[];
    variant?: "inherit" | TypographyVariant;
    className?: string;
}): JSX.Element => {
    return (
        <>
            {typeof text === "string" ? (
                <Typography variant={variant} className={className}>
                    {text}
                </Typography>
            ) : (
                <>
                    {text.length > 0 &&
                        text.map((str, index) => (
                            <Typography
                                key={index}
                                variant={variant}
                                className={className}
                            >
                                {str}
                            </Typography>
                        ))}
                </>
            )}
        </>
    );
};

const StyledRoot = styled('div')(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    minWidth: "480px",
    [theme.breakpoints.down(600)]: {
        flexWrap: "wrap",
    }
}));

const StyledDetail = styled('div')(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "30%",
    marginTop: "0.8125rem",
    marginLeft: "0.6875rem",
    flexWrap: "wrap",
    [theme.breakpoints.down(600)]: {
        width: "100%",
        justifyContent: "space-between",
        flexWrap: "nowrap",
    }
}));


const StyledTitles = styled('div')(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down(600)]: {
        width: "30%",
    }
}));

const StyledTitle = styled(Text)({
    display: "block",
    fontSize: "0.875rem",
    lineHeight: "1.05rem",
    fontWeight: 500,
})

const StyledSubtitle = styled(Typography)({
    fontSize: "0.625rem",
    lineHeight: "0.75rem",
})

const StyledStatistics = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexWrap: "nowrap",
    marginTop: "0.5rem",
    width: "100%",
    [theme.breakpoints.down(600)]: {
        width: "auto",
        marginTop: "0",
        marginRight: "0.6875rem",
    }
}));

const StyledCount = styled(Typography)({
    fontSize: "3rem",
    lineHeight: "4.5rem",
    fontWeight: 500,
})

const StyledDifferenceGroup = styled('div')({
    width: "100%",
    marginLeft: "0.3125rem",
    marginTop: "0.75rem",
})

const StyledDifferenceWrapper = styled('div')({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
})

const StyledDifference = styled(Typography)({
    fontSize: "1.3125rem",
    lineHeight: "2rem",
    fontWeight: 500,
})

const StyledDifferenceDescription = styled(Text)({
    display: "block",
    fontSize: "0.625rem",
    lineHeight: "0.75rem",
})

const StyledChart = styled('div')(({ theme }) => ({
    width: "70%",
    height: "100%",
    [theme.breakpoints.down(600)]: {
        width: "100%",
    }
}))

/**
 * @Component Combination of difference and symbol (up/down triangle)
 * @param {{difference: number, differenceWithColor: boolean}} {difference, differenceWithColor}
 * @return {JSX.Element} 
 */
const DifferenceWrapper = ({
    difference,
    differenceWithColor,
}: {
    difference: number;
    differenceWithColor: boolean;
}): JSX.Element => {
    const theme = useTheme();

    const color =
        differenceWithColor && difference > 0
            ? theme.palette.primary.main
            : differenceWithColor && difference < 0
            ? theme.palette.success.main
            : "";

    return (
        <StyledDifferenceWrapper
            style={{
                fill: color,
                color: color,
            }}
        >
            <StyledDifference variant="h3">
                {difference >= 0 ? `+${formatCount(difference)}` : formatCount(difference)}
            </StyledDifference>
            {difference > 0 ? (
                <ArrowIndicator direction="up" />
            ) : difference < 0 ? (
                <ArrowIndicator direction="down" />
            ) : (
                ""
            )}
        </StyledDifferenceWrapper>
    );
};

/**
 * @Component Up/down triangle to indicate increasing or decreasing
 * @param {({ direction: "up" | "down"})} {direction}
 * @return {JSX.Element} 
 */
const ArrowIndicator = ({ direction }: { direction: "up" | "down" }) => {
    return (
        <svg
            width="17px"
            height="32px"
            style={{
                marginLeft: "0.25rem",
                fill: "currentColor",
                transform: `rotate(${direction === "up" ? 0 : 180}deg)`,
            }}
        >
            <polygon points="0,24.5 8.5,7.5 17,24.5"></polygon>
        </svg>
    );
};

/**
 * @Component Issue History widget
 *
 * @param {Props} {
 *     title,
 *     subtitle = "",
 *     total,
 *     difference,
 *     differenceDescription,
 *     differenceWithColor = false,
 *     backgroundColor,
 *     color,
 *     showBaseline = true,
 *     dataSet,
 * }
 * @return {JSX.Element} 
 */
export const IssueHistory: React.FC<Props> = ({
    title,
    subtitle = "",
    total,
    difference,
    differenceDescription,
    differenceWithColor = false,
    backgroundColor,
    color,
    showBaseline = true,
    dataSet,
}) => {
    const theme = useTheme();

    return (
        <StyledRoot
            style={{ backgroundColor: backgroundColor, color: color || "" }}
        >
            <StyledDetail>
                <StyledTitles>
                    <StyledTitle text={title} variant="h3" />
                    <StyledSubtitle variant="body2">
                        {subtitle}
                    </StyledSubtitle>
                </StyledTitles>
                <StyledStatistics>
                    <StyledCount variant="h1">
                        {formatCount(total)}
                    </StyledCount>
                    <StyledDifferenceGroup>
                        <DifferenceWrapper difference={difference} differenceWithColor={differenceWithColor} />
                        <StyledDifferenceDescription text={differenceDescription} variant="caption" />
                    </StyledDifferenceGroup>
                </StyledStatistics>
            </StyledDetail>
            <StyledChart>
                <LineChart
                    width="100%"
                    height="100%"
                    backgroundColor={backgroundColor}
                    textColor={color || theme.palette.text.primary}
                    dataSet={dataSet}
                    showBaseline={showBaseline}
                />
            </StyledChart>
        </StyledRoot>
    );
}
