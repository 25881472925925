import React from "react";
import * as types from "./svgs";

type CardType = keyof typeof types;

type Props = {
    type: CardType;
    width?: number;
    height?: number;
}

export const CardIcon: React.FC<Props> = ({ type, width, height }) => {
    const Icon = types[type];
    return <Icon width={width} height={height} />
}