import React from "react";
import {IssueHistory} from "./IssueHistory";
import {IssueHistoryWidgetProps} from "./const";


export const MediumRiskIssuesWidget: React.FC<IssueHistoryWidgetProps> = ({
    total,
    difference,
    numberOfDays,
    dataSet,
}) => {
    const args = {
        title: ["Medium risk issues", "on average"],
        subtitle: `in ${numberOfDays} day${numberOfDays === 1 ? "" : "s"}`,
        total: total,
        difference: difference,
        differenceDescription: ["Compare with", `last ${numberOfDays} day${numberOfDays === 1 ? "" : "s"}`],
        backgroundColor: "#FFE142",
        color: "#0E151B",
        showBaseline: false,
        dataSet: dataSet
    }
    return (
        <IssueHistory {...args}></IssueHistory>
    )
}

