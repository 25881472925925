import React, { useContext, useState } from "react";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { GetApp } from "@mui/icons-material";
import { SubscriptionContext } from "src/services/subscription-provider/SubscriptionProvider";
import { useApi } from "src/services/api-provider";
import { ReportErrorDialog } from "./ReportErrorDialog";
import { styled } from '@mui/material/styles';

type Props = {
    domain: string;
    domain_id: number;
};

type DownloadState =
    | { key: "downloading" }
    | { key: "done" }
    | { key: "error"; error: string };

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.info.main,
    width: `${theme.spacing(3)} !important`,
    height: `${theme.spacing(3)} !important`,
    marginLeft: theme.spacing(1),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.info.main,
}))

export const IssuesByDomainDownloadReportBtn: React.FC<Props> = ({
    domain,
    domain_id,
}) => {
    const api = useApi();
    const subscriptionId = useContext(SubscriptionContext).current.uuid;
    const [downloadStatus, setDownloadStatus] = useState<DownloadState>({
        key: "done",
    });

    const onDownload = () => {
        setDownloadStatus({ key: "downloading" });
        api.Request({
            url: "/api/report",
            method: "POST",
            data: {
                report_name: "summary",
                subscription: subscriptionId,
                target_domain_id: domain_id,
            },
            headers: {
                "content-type": "application/pdf",
            },
            responseType: "arraybuffer",
        }).then((res) => {
            if (res.type === "success") {
                setDownloadStatus({ key: "done" });
                if (res.data instanceof ArrayBuffer) {
                    const a = window.document.createElement("a");
                    const file = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    a.href = window.URL.createObjectURL(file);
                    a.download = `summary_${domain}_${subscriptionId}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            } else if (res.type === "error") {
                setDownloadStatus({ key: "error", error: res.message });
            }
        });
    };

    const content = (() => {
        if (downloadStatus.key === "done" || downloadStatus.key === "error") {
            return (
                <Tooltip title="Download Summary Report" placement="right-start">
                    <StyledIconButton
                        size="small"
                        onClick={() => onDownload()}
                    >
                        <GetApp />
                    </StyledIconButton>
                </Tooltip>
            );
        } else if (downloadStatus.key === "downloading") {
            return <StyledCircularProgress />;
        }
    })();

    return (
        <>
            {content}
            <ReportErrorDialog hasError={downloadStatus.key === "error"} />
        </>
    );
};
