import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DnsRecordWithApi } from './DnsRecordWithApi';
import { ExposedServicesWithApi } from './ExposedServicesWithApi';
import { ServiceProvidersWithApi } from './ServiceProvidersWithApi';
import { LeakCredentialsWithApi } from './LeakCredentialsWithApi';
import { ExposedUsersWithApi } from './ExposedUsersWithApi';
import { EmailConfigIssuesWithApi } from './EmailConfigIssuesWithApi';
import { useNavigate, useParams } from "react-router-dom";
import { Issues } from './Issues';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    width: '100%',
    minHeight: '100%',
    padding: theme.spacing(2),
    background: theme.palette.background.default,
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
}));

const StyledAccordion = styled(Accordion)({
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxShadow: 'none'
});

const StyledSubheading = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
}));

const categories = [
  {
    name: "Technical Vulnerabilities",
    reports: [
      { id: "dns-record", title: "DNS Records", element: <DnsRecordWithApi /> },
      { id: "tls", title: "TLS Issues", element: <Issues types={["cert_expired", "cert_trust"]} /> },
      { id: "exposed-services", title: "Exposed Services", element: <ExposedServicesWithApi /> },
      { id: "email-config", title: "Email Config Issues", element: <EmailConfigIssuesWithApi /> },
    ]
  },
  {
    name: "Digital Supply Chain",
    reports: [
      { id: "service-providers", title: "Service Providers", element: <ServiceProvidersWithApi /> },
    ]
  },
  {
    name: "People Threat Intelligence",
    reports: [
      { id: "leaked-credentials", title: "Leaked Credentials", element: <LeakCredentialsWithApi /> },
      { id: "exposed-users", title: "Exposed Users", element: <ExposedUsersWithApi /> },
    ]
  }
];

export const FullReport: React.FC = () => {
  const navigate = useNavigate();
  const { id: expanded } = useParams();

  return (
      <StyledRoot>
          {categories.map(({ name, reports }) => (
              <div key={name}>
                  <StyledHeading variant='h4'>{name}</StyledHeading>
                  {reports.map(({ id, title, element }) => (
                      <StyledAccordion
                          key={id}
                          expanded={expanded === id}
                          onChange={() => navigate(expanded === id ? ".." : `../${id}`)}
                      >
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1aa-content"
                              id="panel1aa-header"
                          >
                              <StyledSubheading>{title}</StyledSubheading>
                          </AccordionSummary>
                          <AccordionDetails>
                              {element}
                          </AccordionDetails>
                      </StyledAccordion>
                  ))}
              </div>
          ))}
      </StyledRoot>
  );
}