import * as t from 'io-ts';
import { EpochSeconds } from 'src/misc/codecs';
import { LiteralUnion } from '../LiteralUnion';
import { CustomerCodec } from './Customer';
import { InvoiceCodec } from './Invoice';
import { PaymentMethodCodec } from './PaymentMethod';
import { PlanCodec } from './Plan';


// https://stripe.com/docs/api/subscriptions/object
export const SubscriptionCodec = t.interface({
    cancel_at_period_end: t.boolean,
    current_period_end: EpochSeconds,
    current_period_start: EpochSeconds,
    customer: CustomerCodec,
    default_payment_method: t.union([t.null, PaymentMethodCodec]),
    latest_invoice: InvoiceCodec,
    plan: PlanCodec,
    status: LiteralUnion([
        "incomplete",
        "incomplete_expired",
        "trialing",
        "active",
        "past_due",
        "canceled",
        "unpaid",
    ] as const),
});

export type Subscription = t.TypeOf<typeof SubscriptionCodec>;