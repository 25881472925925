import React from "react";
import { Timeline } from "@mui/lab";
import { WelcomeProvideTargets } from "./WelcomeProvideTargets";
import { WelcomeVerifyDomain } from "./WelcomeVerifyDomain";
import { WelcomeInviteUsers } from "./WelcomeInviteUsers";
import { WelcomeCompleted } from "./WelcomeCompleted";
import { DomainStat, Step } from "./Welcome";
import { styled } from '@mui/material/styles';

type Props = {
    step: Step;
    skipInvite: () => void;
    domainStat: DomainStat;
};

const StyledRoot = styled('div')({
    width: "100%",
});

const StyledTimeline = styled(Timeline)({
    padding: "0"
});

export const WelcomeSteps: React.FC<Props> = ({
    step,
    skipInvite,
    domainStat,
}) => {
    return (
        <StyledRoot>
            <StyledTimeline>
                <WelcomeProvideTargets step={step} />
                <WelcomeVerifyDomain step={step} domainStat={domainStat} />
                <WelcomeInviteUsers step={step} skipInvite={skipInvite} />
                <WelcomeCompleted step={step} />
            </StyledTimeline>
        </StyledRoot>
    );
};
