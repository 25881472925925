import React from "react";
import { DataRow } from "./IssuesByDomain";
import {
    CountBySeverity,
    DEFAULT_LIST_ITEM,
    IssuesByDomainListItem,
    IssuesByDomainListItemProps,
} from "./IssuesByDomainListItem";
import { styled } from '@mui/material/styles';

export type IssuesByDomainType = {
    domain: string;
    severity: number;
};

type Props = {
    data: DataRow[];
};

type SeverityLevelString = "low" | "medium" | "high" | "critical";


const StyledRoot = styled('div')({
    "& > div": {
        margin: "0 0 1rem 0",
    },
})

/**
 * Get severity level string base on score
 * @param severity Severity score from database
 * @returns Severity level string
 */
const getSeverityLevel = (severity: number): SeverityLevelString => {
    if (severity <= 3) {
        return "low";
    } else if (severity <= 6) {
        return "medium";
    } else if (severity <= 8) {
        return "high";
    } else {
        return "critical";
    }
};

/**
 * Get new counts object about each severity level to 0
 * @returns Default counts for each severity
 */
const newCounts = (): CountBySeverity => {
    return Object.assign(
        {},
        {
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
        }
    );
};

/**
 * Create new list item for different domains
 * @returns New Issues By Domain Item props in list
 */
const newListItemProps = (): IssuesByDomainListItemProps => {
    return Object.assign({}, DEFAULT_LIST_ITEM, { counts: newCounts() });
};

export const IssuesByDomainList: React.FC<Props> = ({ data }) => {
    // Store all props for each item/domain
    const listItemProps: IssuesByDomainListItemProps[] = [];

    // Store current target domain
    let targetDomain = "";
    let itemProps: IssuesByDomainListItemProps | null = null;

    data.forEach((record, index) => {
        // First data record
        if (index === 0) {
            targetDomain = record.target_domain;
            itemProps = newListItemProps();
            itemProps.domain = record.target_domain;
            itemProps.domain_id = record.target_domain_id;
            const severityLevelString = getSeverityLevel(record.severity);
            itemProps.counts[severityLevelString]++;
        } else {
            // New domain in data records
            if (record.target_domain !== targetDomain) {
                // Push existing prop item into array `listItemProps`
                if (itemProps) {
                    const tempItemProps = Object.assign({}, itemProps);
                    listItemProps.push(tempItemProps);
                }

                // Create new item props for new domain
                itemProps = newListItemProps();
                itemProps.domain = record.target_domain;
                itemProps.domain_id = record.target_domain_id;

                // Update targetDomain to current domain
                targetDomain = record.target_domain;
            }

            // Update severity counts
            if (itemProps) {
                const severityLevelString = getSeverityLevel(record.severity);
                itemProps.counts[severityLevelString]++;
            }

            // When react the last data record, push item props into array `listItemProps`
            if (index === data.length - 1) {
                const tempItemProps = Object.assign({}, itemProps);
                listItemProps.push(tempItemProps);
            }
        }
    });

    // Find max count of each domain
    let maxCount = 0;
    listItemProps.forEach((item) => {
        const totalCount =
            item.counts.critical +
            item.counts.high +
            item.counts.medium +
            item.counts.low;
        if (totalCount > maxCount) {
            maxCount = totalCount;
        }
    });

    // Assign `maxCount` to each prop item
    listItemProps.map((item) => {
        item.maxCount = maxCount;
    });

    return (
        <StyledRoot>
            {listItemProps.length >= 0 &&
                listItemProps.map((props, index) => {
                    return <IssuesByDomainListItem key={index} {...props} />;
                })}
        </StyledRoot>
    );
};
