import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DetectedServiceProvider } from '../services/full-report';
import { RequestStatus } from 'src/shared/enums';
import { FullReportLoading } from '../molecules/FullReportLoading';
import { Fade, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


type Props = {
  data: DetectedServiceProvider[];
  status: number;
}

const StyledTable = styled(Table)({
    minWidth: 650,
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.table.head.background,
    color: theme.palette.table.head.text,
}))

export const ServiceProviders:React.FC<Props> = ({
  data,
  status
}) => {

  if (status === RequestStatus.Pending) {
    return <FullReportLoading />
  }

  return (
      <Fade in={!!data}>
          <TableContainer>
              <StyledTable
                  aria-label="Service Providers table"
              >
                  <StyledTableHead>
                      <TableRow>
                          <TableCell>
                              <Typography variant="subtitle2">
                                  Domain
                              </Typography>
                          </TableCell>
                          <TableCell>
                              <Typography variant="subtitle2">
                                  Provider
                              </Typography>
                          </TableCell>
                      </TableRow>
                  </StyledTableHead>
                  <TableBody>
                      {data.map((row, index) => (
                          <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                  {row.domain}
                              </TableCell>
                              <TableCell>{row.service_provider}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </StyledTable>
          </TableContainer>
      </Fade>
  );
}