import { Typography, Button } from "@mui/material";
import React from "react";
import { InvitationTable } from "./InvitationTable";
import { styled } from '@mui/material/styles';

type Props = {
    reload: () => void;
};

const StyledRoot = styled('div')(({ theme }) => ({
    margin: theme.spacing(2)
}));

const StyledBold = styled(Typography)({
    fontWeight: 600,
})

const StyledInvitationContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    minHeight: "30vh",
    "& > *": {
        alignSelf: "stretch",
    },
}))

const StyledButtonWrapper = styled('div')(({ theme }) => ({
    marginTop: "auto",
    display: "flex",
    paddingTop: theme.spacing(2),
}))

const StyledButton = styled(Button)({
    cursor: "pointer",
    justifySelf: "flex-start",
})


export const InvitationPage: React.FC<Props> = ({
	reload
}: Props) => (
    <StyledRoot>
        <StyledBold variant="h5">
            Would you like to invite any other users to this subscription?
        </StyledBold>
        <StyledInvitationContainer>
            <StyledBold variant="body1">
                Account Email
            </StyledBold>
            <InvitationTable />
            <StyledButtonWrapper>
                <StyledButton onClick={reload}>
                    Done
                </StyledButton>
            </StyledButtonWrapper>
        </StyledInvitationContainer>
    </StyledRoot>
);

