import React from "react";
import { Theme, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';

const StyledSvg = styled('svg')(({ theme }) => ({
    "& > text": {
        fontFamily: theme.typography.fontFamily,
    },
}))

const StyledCircle = styled('circle')(({ theme }) => ({
    stroke: theme.palette.grey[400],
    fill: "transparent",
}))

export type ChartData = {
    isTotalChart: boolean;
    category: string;
    count: number;
    differenceWithPrevious: number;
    score: string;
    issueSeverities: {
        name: string;
        value: number;
        color: string;
        dasharray?: string;
        offset?: number;
    }[];
};

type DifferenceDetail = {
    color: string;
    value: string;
};

export type Props = {
    data: ChartData;
    width: number;
    x: number;
    y: number;
};

export const DonutChart: React.FC<Props> = ({ data, width, x, y }: Props) => {
    const theme = useTheme();

    const radius = 16;
    let offset = 0;

    const sumOfSeverities = data.issueSeverities.reduce(
        (sum, issueSeverity) => sum + issueSeverity.value,
        0
    );
    const circumference = Math.PI * (radius * 2);

    data.issueSeverities.map((issueSeverity) => {
        const percent = (issueSeverity.value * 100) / sumOfSeverities;
        issueSeverity.dasharray = `${circumference * percent} ${circumference}`;
        issueSeverity.offset = offset;
        offset += percent;
    });

    const differenceDetail = differenceWithPreviousDetail(data, theme);

    return (
        <StyledSvg width={width + "%"} x={x} y={y}>
            <svg viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
                <g>
                    <circle
                        r={radius - 2.5}
                        cx="50%"
                        cy="50%"
                        fill={theme.palette.supplementary.main}
                    ></circle>
                    {data.count === 0 ? (
                        <StyledCircle
                            cx="50%"
                            cy="50%"
                            r={radius - 1}
                        />
                    ) : (
                        data.issueSeverities.map((issueSeverity) => (
                            <circle
                                key={issueSeverity.name}
                                r={radius - 1}
                                cx="50%"
                                cy="50%"
                                strokeDasharray={issueSeverity.dasharray}
                                strokeDashoffset={
                                    (issueSeverity.offset || 0) * -1
                                }
                                stroke={issueSeverity.color}
                                fill="transparent"
                            ></circle>
                        ))
                    )}
                </g>
                {data.isTotalChart
                    ? totalChartText(data, differenceDetail)
                    : null}
            </svg>
            {!data.isTotalChart
                ? categoryChartText(data, differenceDetail, width, radius)
                : null}
        </StyledSvg>
    );
};

function totalChartText(data: ChartData, differenceDetail: DifferenceDetail) {
    const countTextLength =
        data.count.toString().length < 3
            ? data.count.toString().length * 4
            : 12;

    const polygonPath =
        data.differenceWithPrevious > 0
            ? `${countTextLength + 12.5},13.5  ${countTextLength + 13.2},12.3  ${
                  countTextLength + 13.9
              },13.5`
            : data.differenceWithPrevious < 0
            ? `${countTextLength + 12.5},12.3  ${countTextLength + 13.2},13.5  ${
                  countTextLength + 13.9
              },12.3`
            : "";

    return (
        <g fill="#fff" style={{ pointerEvents: "none" }}>
            <text
                x="6"
                y="17"
                textLength={countTextLength}
                lengthAdjust={
                    data.count.toString().length !== 1
                        ? "spacingAndGlyphs"
                        : "spacing"
                }
                fontSize="0.5rem"
            >
                {data.count}
            </text>
            <text
                x={countTextLength + 8}
                y="14"
                fill={differenceDetail.color}
                fontSize="0.18rem"
                textLength="4"
                lengthAdjust={
                    data.differenceWithPrevious.toString().length !== 1
                        ? "spacingAndGlyphs"
                        : "spacing"
                }
            >
                {differenceDetail.value}
            </text>
            <text>
                {breakString(
                    "compared to yesterday",
                    9,
                    countTextLength + 8,
                    15.5,
                    "0.06rem",
                    1.2
                )}
            </text>
            <polygon points={polygonPath} fill={differenceDetail.color} />
            <text
                x="50%"
                y="20.5"
                fontSize="0.2rem"
                fontWeight={600}
                textAnchor="middle"
                textLength="20"
            >
                Issue{data.count === 1 ? "" : "s"} In Total
            </text>
        </g>
    );
}

function categoryChartText(
    data: ChartData,
    differenceDetail: DifferenceDetail,
    width: number,
    radius: number
) {
    const countTextLength = 5;
    const polygonPath =
        data.differenceWithPrevious > 0
            ? `${countTextLength + 13},37.7  ${countTextLength + 13.7},36.5  ${
                  countTextLength + 14.4
              },37.7`
            : data.differenceWithPrevious < 0
            ? `${countTextLength + 13},36.5  ${countTextLength + 13.7},37.7  ${
                  countTextLength + 14.4
              },36.5`
            : "";

    return (
        <svg
            fill="#fff"
            style={{ pointerEvents: "none" }}
            x={(width - 27.5) / 2}
            y={radius / 3}
        >
            <text color="rgba(255, 255, 255, 0.78)">
                {breakString(data.category, 9, 6, 32, "0.13rem", 2.5)}
                <tspan
                    x="6"
                    y="40"
                    textLength={countTextLength}
                    lengthAdjust={
                        data.count.toString().length !== 1
                            ? "spacingAndGlyphs"
                            : "spacing"
                    }
                    fontSize="0.3rem"
                >
                    {data.count}
                </tspan>
                <tspan x="6" y="43" fontSize="0.12rem" fontWeight={600}>
                    Issue{data.count === 1 ? "" : "s"}
                </tspan>
            </text>
            <text
                x="14"
                y="38"
                textLength="3"
                lengthAdjust={
                    data.differenceWithPrevious.toString().length !== 1
                        ? "spacingAndGlyphs"
                        : "spacing"
                }
                fill={differenceDetail.color}
                fontSize="0.14rem"
            >
                {differenceDetail.value}
            </text>
            <polygon points={polygonPath} fill={differenceDetail.color} />
            <text color="rgba(255, 255, 255, 0.78)">
                {breakString(
                    "compared to yesterday",
                    9,
                    14,
                    40,
                    `0.07rem`,
                    1.7
                )}
            </text>
        </svg>
    );
}

function differenceWithPreviousDetail(
    data: ChartData,
    theme: Theme
): DifferenceDetail {
    const differenceDetail = {
        color: theme.palette.severity.medium.main,
        value: `+${data.differenceWithPrevious}`,
    };

    if (data.differenceWithPrevious > 0) {
        differenceDetail.color = theme.palette.severity.critical.main;
    } else if (data.differenceWithPrevious < 0) {
        differenceDetail.color = theme.palette.success.main;
        differenceDetail.value = data.differenceWithPrevious.toString();
    }

    return differenceDetail;
}

function breakString(
    str: string,
    length: number,
    x: number,
    y: number,
    font_size: string,
    lineHeight: number
) {
    const words = str.split(" ");
    const strList: string[] = [];
    let tempStr = "";
    words.forEach((word, index) => {
        if (tempStr.length > 0) {
            tempStr += " " + word;
        } else {
            tempStr = word;
        }
        if (tempStr.length >= length || index === words.length - 1) {
            strList.push(tempStr);
            tempStr = "";
        }
    });

    return strList.map((str: string, index: number) => (
        <tspan
            key={index}
            y={y + lineHeight * index}
            x={x}
            fontSize={font_size}
        >
            {str}
        </tspan>
    ));
}
