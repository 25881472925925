type Group<T, Keys extends keyof T> = Index<T, Keys> & {
    items: T[]
}

type Index<T, Keys extends keyof T> = {
    [Property in Keys]: T[Property]
}

export function groupBy<T, Key extends keyof T>(xs: T[], ...groupBy: Key[]): Array<Group<T, typeof groupBy[0]>> {
    const groups = xs.reduce((acc, t) => {
        const key = getIndex(t, groupBy)
        if (!acc.find(v => equal(v, key))) acc.push(key);
        return acc;
    }, [] as Index<T, typeof groupBy[0]>[]);
    return groups.map(g => ({
        ...g,
        items: xs.reduce((acc, v) => {
          if (equal(g, getIndex(v, groupBy))) acc.push(v);
          return acc;
        }, [] as T[])
    }))
}

function getIndex<T>(x: T, index: Array<keyof T>): Index<T, typeof index[0]> {
    return index.reduce((acc, v) => {
        acc[v] = x[v]
        return acc
    }, {} as Index<T, typeof index[0]>)
}

type Obj = {
    [Property in string]: unknown
}

function equal(as: Obj, bs: Obj): boolean {
    for (const key in as) {
        if (JSON.stringify(as[key]) !== JSON.stringify(bs[key])) {
            return false
        }
    }
    for (const key in bs) {
        if (JSON.stringify(bs[key]) !== JSON.stringify(as[key])) {
            return false
        }
    }
    return true;
}