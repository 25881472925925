type Config = {
    domain: string
}

const match = /^app\.(.*)$/;

const domain = (): string => {
    const result = window.location.host.match(match);
    if (result == null || result.length != 2) {
        return "attackbound.test";
    }
    return result[1]  ;
}

export const config: Config = {
    domain: domain()
}

// TODO: Set live key for production environment.
export const stripe_publishable_key = "pk_test_51KVVGrAXwY56S1iPYdGoPbMIaWiMYeyt6zLZQenq737w0YaZn4Nk1UHBd4ESmHktYwlhJQ1T8ACjSbNQwrINjYlO00Fe2MIdZo";