import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Size } from './Tree';

export type GetChildrenFnResult = { type: "success", children: TreeNode[] }
                                | { type: "error", error: string };

export type GetChildrenFn = () => Promise<GetChildrenFnResult>;

export type TreeNodeProps = {
    name: string;
    getChildren?: GetChildrenFn;
    tooltip?: JSX.Element;
    category?: boolean;
    notify?: () => void;
}

type State = { state: "collapsed", children?: TreeNode[] }
           | { state: "loading" }
           | { state: "expanded", children: TreeNode[] }
           | { state: "error", error: string };

export class TreeNode {
    private _state: State = { state: "collapsed" };
    private _getChildren?: GetChildrenFn;
    private _tooltip?: JSX.Element;
    private _isCategory: boolean | undefined;
    private _size: Size = {width: 1, height: 1};
    private _notify: (() => void) | undefined;

    public name: string;
    public key: string;

    constructor({ name, getChildren, tooltip, category, notify }: TreeNodeProps) {
        this.name = name;
        this.key = uuidv4();
        this._getChildren = getChildren;
        this._tooltip = tooltip;
        this._isCategory = category;
        this._notify = notify;
    }

    // Toggle the expanded state of the node, loading children on-demand if necessary. The given
    // redraw callback is fired after each "visible" state change, and can be used by the caller
    // to trigger a redraw.
    async toggle(): Promise<void> {
        if (!this._getChildren) return;

        switch (this._state.state) {
            case "collapsed": {
                if (this._state.children !== undefined) {
                    this._state = { state: "expanded", children: this._state.children };
                } else {
                    this._state = { state: "loading" };
                    this.notify();
                    const res = await this.getChildren();
                    if (res.type === "success") {
                      this._state = { state: "expanded", children: res.children };
                    } else {
                      this._state = { state: "error", error: res.error };
                    }
                }
                break;
            }
            case "error": {
                this._state = { state: "loading" };
                this.notify();
                const res = await this.getChildren();
                    if (res.type === "success") {
                      this._state = { state: "expanded", children: res.children };
                    } else {
                      this._state = { state: "error", error: res.error };
                    }
                break;
            }
            case "loading": {
                // Already loading, so do nothing.
                break;
            }
            case "expanded": {
                this._state = { state: "collapsed", children: this._state.children }
                break;
            }
        }
        this.notify();
    }

    private async getChildren(): Promise<GetChildrenFnResult> {
        if (!this._getChildren) {
            return {
                type: "error",
                error: "no getChildren function defined"
            }
        }
        const res = await this._getChildren();
        if (res.type === "success" && res.children.length === 0) {
            return {
                type: "success",
                children: [new TreeNode({
                    name: "None found",
                    notify: this._notify,
                })]
            }
        }
        // Attach notify handler to each child
        if (res.type == "success" && this._notify) {
            for (let i = 0; i < res.children.length; i++) {
                res.children[i].setNotify(() => this.notify());
            }
        }
        return res
    }

    state(): State {
        return this._state;
    }

    tooltip(): React.ReactNode {
      if (this._state.state === "error") {
        return (
            <>
                <p>Couldn&apos;t fetch children.</p>
                <p>Error: {this._state.error}.</p>
                <p>Click the node to try again.</p>
            </>
        );
      }
      if (this._tooltip) {
        return this._tooltip;
      }
      return <p>{this.name}</p>
    }

    isCategory(): boolean {
        if (this._isCategory === undefined) {
            return !!this._getChildren;
        }
        return this._isCategory;
    }

    isExpandable(): boolean {
        return !!this._getChildren;
    }
    
    setSize(size: Size): void {
        this._size = size;
        this.notify();
    }

    getSize(): Size {
        return this._size;
    }

    setNotify(f: () => void): void {
        this._notify = f
    }

    private notify() {
        if (this._notify) {
            this._notify()
        }
    }
}

