import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { MenuItem } from './Nav';


type NavListItemProp = {
    item: MenuItem;
};

export const NavListItem: React.FC<NavListItemProp> = ({ item, ...props }) => {
    const theme = useTheme();
    const resolved = useResolvedPath(item.link || "");
    const match = useMatch({ path: resolved.pathname, end: false });

    return (
        <>
            <ListItem
                selected={!!match}
                component={NavLink}
                to={item.link || ""}
                {...props}
            >
                <ListItemIcon
                    style={{ marginRight: theme.spacing(1), minWidth: "auto" }}
                >
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            variant="subtitle2"
                            style={{
                                color: theme.palette.text.primary,
                                fontWeight: match ? "bold" : "normal",
                            }}
                        >
                            {item.name}
                        </Typography>
                    }
                />
            </ListItem>
        </>
    );
};