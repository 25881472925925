import React, { FC } from "react";
import { HeroWidget } from "../issue-summary/hero-widget/HeroWidget";
import { ScanDetailsWithApi } from "../side-widgets/scan-details/organisms/ScanDetailsWithApi";
import { WhatsNewWithApi } from "../side-widgets/whats-new/WhatsNewWithApi";
import { Typography } from "@mui/material";
import { Tabs } from "src/elements/Tabs/Tabs";
import { IssueTableWidget } from "../issue-summary/IssueTable/IssueTableWidget";
import { IssuesByDomain } from "../issue-summary/IssuesByDomain/IssuesByDomain";
import { styled } from '@mui/material/styles';

const StyledRow = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    alignItems: "stretch",
}))

const StyledColumn = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "stretch",
}))

const StyledIssues = styled(Tabs)({
    flexGrow: 1,
})

type Props = {
    className?: string;
}

const ISSUE_COUNT = 13;

export const MostRecent: FC<Props> = ({ className }) => {
    return (
        <StyledRow className={className}>
            <StyledColumn>
                <HeroWidget />
                <StyledIssues tabs={["Open", "Closed"]}>
                    <IssueTableWidget state="open" count={ISSUE_COUNT}/>
                    <IssueTableWidget state="closed" count={ISSUE_COUNT}/>
                </StyledIssues>
            </StyledColumn>
            <StyledColumn>
                <div>
                    <Typography component="h2" variant="h6">By Domain</Typography>
                    <IssuesByDomain/>
                </div>
                <div>
                    <Typography component="h2" variant="h6">What&apos;s New</Typography>
                    <WhatsNewWithApi />
                </div>
                <div>
                    <Typography component="h2" variant="h6">Analysis Details</Typography>
                    <ScanDetailsWithApi />
                </div>
            </StyledColumn>
        </StyledRow>
    );
}
