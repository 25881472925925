import React, { useState } from "react";
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { MenuItem } from "./Nav";
import { NavSubMenuListItem } from "./NavSubMenuListItem";

type NavListSubMenuProp = {
    item: MenuItem;
};

type NavListSubMenuExpandIconProps = {
    open: boolean;
}

const NavListSubMenuExpandIcon: React.FC<NavListSubMenuExpandIconProps> = ({
    open,
}) => {
    return (
        <>
            {open ? (
                <ExpandLess color="primary" />
            ) : (
                <ExpandMore color="primary" />
            )}
        </>
    );
};

export const NavListSubMenu: React.FC<NavListSubMenuProp> = ({ item, ...props }) => {
    const theme = useTheme();

    const [open, setOpen] =
        useState<boolean>(false);

    const handleSubMenu = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem onClick={() => handleSubMenu()} style={{ cursor: "pointer",}} {...props}>
                <ListItemIcon
                    style={{ marginRight: theme.spacing(1), minWidth: "auto" }}
                >
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            variant="subtitle2"
                            style={{
                                color: theme.palette.text.primary,
                            }}
                        >
                            {item.name}
                        </Typography>
                    }
                />
                <NavListSubMenuExpandIcon open={open} />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { item.submenu && item.submenu.map(item => (
                        <NavSubMenuListItem item={item} key={item.name} />
                    ))}
                </List>
            </Collapse>
        </>
    );
}
