import { Typography } from "@mui/material";
import React from "react";

type Props = {
    children: JSX.Element | Array<JSX.Element> | string;
    className?: string;
};

export const PageTitle: React.FC<Props> = ({ children, ...props }) => {
    return (
        <Typography variant="h4" component="h1" {...props}>
            {children}
        </Typography>
    );
};
