import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FullReportLoading } from '../molecules/FullReportLoading';
import { Error } from '../molecules/Error';
import { Row, RowSlice, usePostgrest } from 'src/services/postgrest-provider';
import { Fade, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledTable = styled(Table)({
    minWidth: 650,
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.table.head.background,
    color: theme.palette.table.head.text,
}))

type IssueType = Row<"issues">["type"];

type Props = {
  types: IssueType[];
}

type DataRow = RowSlice<"issues", "title" | "desc" | "last_seen" | "severity">;

type State = { key: "loading" }
           | { key: "ok", data: DataRow[] }
           | { key: "error", error: string }

export const Issues: React.FC<Props> = ({ types }) => {

  const postgrest = usePostgrest();
  const [state, setState] = useState<State>({ key: "loading" });

  const typeParam = encodeURIComponent(`in.(${types.join(",")})`);

  useEffect(() => {
    postgrest.GetTableSlice(
      "issues",
      ["title", "severity", "desc", "last_seen"],
      `type=${typeParam}&resolved=is.null`
    ).then(res => {
      if (res.type === "error") {
        setState({ key: "error", error: res.message });
      } else {
        setState({ key: "ok", data: res.data });
      }
    });
  }, [postgrest, typeParam]);

  if (state.key === "loading") {
    return <FullReportLoading />
  }

  if (state.key === "error") {
    return <Error message={state.error} />;
  }

  return (
      <Fade in={!!state.data}>
          <TableContainer>
              <StyledTable aria-label="TLS table">
                  <StyledTableHead>
                      <TableRow>
                          <TableCell>
                              <Typography variant="subtitle2">
                                  Issue
                              </Typography>
                          </TableCell>
                          <TableCell>
                              <Typography variant="subtitle2">
                                  Severity
                              </Typography>
                          </TableCell>
                          <TableCell>
                              <Typography variant="subtitle2">
                                  Description
                              </Typography>
                          </TableCell>
                          <TableCell>
                              <Typography variant="subtitle2">
                                  Last Seen
                              </Typography>
                          </TableCell>
                      </TableRow>
                  </StyledTableHead>
                  <TableBody>
                      {state.data.map((row, index) => (
                          <TableRow key={index}>
                              <TableCell>{row.title}</TableCell>
                              <TableCell>{row.severity}</TableCell>
                              <TableCell>{row.desc}</TableCell>
                              <TableCell>
                                  {row.last_seen.toLocaleDateString()}
                              </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </StyledTable>
          </TableContainer>
      </Fade>
  );
}
