import React from "react";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ScanDetailRecord } from "../services/scan-details";
import { RequestStatus } from "src/shared/enums";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Skeleton } from '@mui/material';

type Props = {
  status: number;
  data: ScanDetailRecord[];
  getData: () => void;
};

const StyledScanDetails = styled('div')({
    maxWidth: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 201,
});

const StyledTimelineContent = styled(TimelineContent)({
    minWidth: 270,
    maxWidth: 270,
});

const StyledPaper = styled(Paper)(
    ({ theme }) => ({
        padding: "6px 16px",
        backgroundColor: theme.palette.background.paper
    })
);


const StyledSkeleton = styled(Skeleton)({
    width: '100%',
    height: 201,
    transform: 'none',
});


const getTimeDiff = (now: Date, past: Date): string => {
  let ret: string;

  let diff = now.getTime() - past.getTime();
  if (diff < 0) diff = 0;	// Avoid showing negative difference when accessing same subscription with different users

  const days_diff = Math.floor(diff / 1000 / 60 / 60 / 24);
  const hour_diff = Math.floor(diff / 1000 / 60 / 60);
  const minute_diff = Math.floor(diff / 1000 / 60);
  const second_diff = Math.floor(diff / 1000);


  if (days_diff > 0) {
    ret = days_diff > 1 ? `${days_diff} days ago` : `${days_diff} day ago`;
  } else if (hour_diff > 0) {
    ret = hour_diff > 1 ? `${hour_diff} hours ago` : `${hour_diff} hour ago`;
  } else if (minute_diff > 0) {
    ret = minute_diff > 1 ? `${minute_diff} minutes ago` : `${minute_diff} minute ago`;
  } else {
    ret = second_diff > 1 ? `${second_diff} seconds ago` : `${second_diff} second ago`;
  } 

  return ret;
}

export const ScanDetails: React.FC<Props> = ({ status, data, getData }) => {
  const now = new Date();

  if (status === RequestStatus.Pending) {
    return (
      <StyledScanDetails data-testid="scan_details_loading">
        <StyledSkeleton />
      </StyledScanDetails>
    );
  }

  if (status === RequestStatus.Error) {
    return (
      <StyledScanDetails data-testid="scan_details_error">
        <Button onClick={getData}>Refresh</Button>
      </StyledScanDetails>
    );
  }

  if (status === RequestStatus.Success && data.length === 0) {
    return (
      <StyledScanDetails data-testid="scan_details_empty">
        <h3>No record found</h3>
      </StyledScanDetails>
    );
  }

  return (
    <StyledScanDetails data-testid="scan_details">
      <Timeline>
        {data.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot />
              {index !== data.length - 1 ? <TimelineConnector /> : ""}
            </TimelineSeparator>
            <StyledTimelineContent>
              <StyledPaper elevation={3}>
                <Typography variant="h3">
                  {getTimeDiff(now, new Date(item.timestamp))}
                </Typography>
                <Divider />
                <Typography variant="caption">{item.desc}</Typography>
              </StyledPaper>
            </StyledTimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </StyledScanDetails>
  );
};
