import React, { createContext } from "react";
import { useContext } from "react";
import { Postgrest } from "./Postgrest";
import { MockApi } from "../api-provider/MockApiProvider";
import { useApi } from "../api-provider";

type PostgrestProviderProps = {
    children: React.ReactNode,
}

export const PostgrestContext = createContext<Postgrest>(new Postgrest(
    new MockApi(() => { throw new Error("not in a PostgrestContext"); }, 0),
));

export const PostgrestProvider: React.FC<PostgrestProviderProps> = ({ children }) => {
    const api = useApi();
    const postgrest = new Postgrest(api);

    return (
        <PostgrestContext.Provider value={postgrest}>
            {children}
        </PostgrestContext.Provider>
    );
}

export const usePostgrest = (): Postgrest => {
    return useContext(PostgrestContext);
}
