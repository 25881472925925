import React from "react"
import { Logo } from './Logo';
import { Link } from "react-router-dom";
import { DarkModeSwitch, LogoutButton } from "./Controls";
import AuthView from "../auth-view/AuthView";
import { styled } from '@mui/material/styles';

type Props = {
    children: JSX.Element | Array<JSX.Element>
}

const StyledRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const StyledHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    '& > *': {
        margin: theme.spacing(2),
        marginRight: 0,
    }
}));

const StyledLogo = styled(Logo)({
    width: 220,
    height: 'auto',
});

const StyledContent = styled('div')(({ theme }) => ({
    background: theme.palette.background.paper,
    flexGrow: 1,
}))

export const BasicLayout: React.FC<Props> = ({ children }: Props) => (
    <StyledRoot>
        <StyledHeader>
            <Link to="/">
                <StyledLogo />
            </Link>
            <div style={{flexGrow: 1}}/>
            <DarkModeSwitch />
            <AuthView/>
            <LogoutButton/>
        </StyledHeader>
        <StyledContent>
            {children}
        </StyledContent>
    </StyledRoot>
);