import { Card } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AcceptInvitation } from './AcceptInvitation';
import { styled } from '@mui/material/styles';

type Props = {
  code: string;
}

const StyledRoot = styled(Card)(({ theme }) => ({
    border: 'none',
    margin: theme.spacing(2)
}))

export const AcceptInvitationWrapper: React.FC<Props> = ({ code }) => {
  const navigate = useNavigate();

  return (
    <StyledRoot variant="outlined">
      <AcceptInvitation code={code} done={() => navigate("/")} />
    </StyledRoot>
  );
}