import React, { FC, useMemo, useState } from 'react';
import { Card, CircularProgress, Link, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Bubble, Bubbles } from './Bubbles';
import { Tree } from './Tree';
import { makeTree } from './make-tree';
import Splash from '../splash/Splash';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { usePostgrest } from 'src/services/postgrest-provider';
import { styled } from '@mui/material/styles';


const StyledContainer = styled('div')({
    width: '100%',
    height: '100%',
})

const StyledInfoWrapper = styled('div')({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
})


const StyledInfo = styled(Card)(({ theme }) => ({
    width: "60%",
    minHeight: "180px",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: theme.palette.action.hover

}))

const StyledInfoIcon = styled(InfoRoundedIcon)(({ theme }) => ({
    color: theme.palette.info.main
}))

type State = { state: "loading" }
           | { state: "bubbles", bubbles: Bubble[] }
           | { state: "error", error: string }
           | { state: "selected", target: string, bubbles: Bubble[] }


export const ExposureMap: FC = () => {
  const postgrest = usePostgrest();

  const [state, setState] = useState<State>({ state: "loading" });

  const fetchTargets = useCallback(() => {
    setState({ state: "loading" });
    postgrest.GetTableSlice("target_domains_validity", ["domain"], "passed=eq.true").then(res => {
      if (res.type === "success") {
        setState({ state: "bubbles", bubbles: res.data.map(v => ({ name: v.domain, size: 1 })) });
      } else {
        setState({ state: "error", error: "couldn't fetch target domains" });
        console.error("fetch target domains", res.message);
      }
    })
  }, [postgrest]);

  useEffect(() => fetchTargets(), [fetchTargets]);

  const selectTarget = (selected: Bubble) => {
    if (state.state !== "bubbles") return;
    setState({ state: "selected", target: selected.name, bubbles: state.bubbles });
  }

  const back = () => {
    if (state.state !== "selected") return;
    setState({ state: "bubbles", bubbles: state.bubbles });
  }


  return (
    <StyledContainer>
      {(() => {
        switch (state.state) {
          case "loading":
            return (
              <Splash>
                <CircularProgress />
              </Splash>
            )
          case "error":
            return (
              <Splash>
                <Typography>Error: {state.error}.</Typography>
                <Typography>Click <Link onClick={fetchTargets} href="javascript:;" underline="hover">here</Link> to retry</Typography>
              </Splash>
            );
          case "bubbles":
            return <>
              { 
                state.bubbles.length 
                  ? <Bubbles bubbles={state.bubbles} select={selectTarget} />
                  : <ExposureMapNoData />
              }
            </>
          case "selected":
            return <TreeWithData target={state.target} back={back} />
        }
      })()}
    </StyledContainer>
  );
}

type TreeWithDataProps = {
  target: string;
  back: () => void;
}

const TreeWithData: FC<TreeWithDataProps> = ({ target, back }) => {
  const postgrest = usePostgrest();
  const tree = useMemo(() => makeTree(postgrest, target), [postgrest, target]);
  const tooltip = useMemo(() => <p>Findings for {target}.</p>, [target]);
  return <Tree name={target} tooltip={tooltip} left={tree[0]} right={tree[1]} back={back}/>
}


const ExposureMapNoData: FC = () => {
  return (
    <StyledInfoWrapper>
      <StyledInfo>
        <StyledInfoIcon fontSize="large"/>
        <Typography align="center" variant="h5">
          Diagram will be displayed after target domains are verified
        </Typography>
      </StyledInfo>
    </StyledInfoWrapper>
  )
}