import React, { useEffect, useState } from 'react';
import { usePostgrest } from 'src/services/postgrest-provider';
import { RequestStatus } from "src/shared/enums";
import { DnsDataRecord } from '../services/full-report';
import { DnsRecord } from './DnsRecord';

export const DnsRecordWithApi: React.FC = () => {
  const postgrest = usePostgrest();
  const [reqStatus, setReqStatus] = useState(RequestStatus.Pending);
  const [data, setData] = useState([] as DnsDataRecord[]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getData(), []);

  const getData = () => {
    postgrest
      .GetTable('dns_records')
      .then((res) => {
        if (res.type === "success"){
          setReqStatus(RequestStatus.Success);
          setData(res.data);
        } else if (res.type === "error") {
          console.error(res.message);
          setReqStatus(RequestStatus.Error);
        }
      })
  }
  return (
    <DnsRecord data={data} status={reqStatus} />
  );
}