import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { config } from "../../config";
import { useAuth } from "../auth-provider/AuthProvider";
import { Api } from "./Api";

const apiURL = `https://api.${config.domain}`;

export const ApiContext = createContext<Api>(new Api("", ""))

type Props = {
    children: JSX.Element
}

export const ApiProvider: React.FC<Props> = ({ children }: Props) => {

    const auth = useAuth();
    const token = auth.user.access_token;

    const [api] = useState(new Api(token, apiURL));
    useEffect(() => api.updateToken(token), [token, api]);

    return <ApiContext.Provider value={api}>
        {children}
    </ApiContext.Provider>
}

/**
 * Get access to the API from React components which are children of the ApiProvider component.
 * @returns An instance of the Api class.
 */
export const useApi = (): Api => {
    return useContext(ApiContext);
}