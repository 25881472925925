import * as t from 'io-ts';
import { CurrencyCodec } from '../Currency';
import { LiteralUnion } from '../LiteralUnion';
import { ProductCodec } from './Product';

// https://stripe.com/docs/api/plans/object

const base = {
    id: t.string,
    active: t.boolean,
    amount: t.number,
    currency: CurrencyCodec,
    interval: LiteralUnion(["day", "week", "month", "year"] as const),
    interval_count: t.number,
    object: t.literal("plan"),
};

export const PlanCodecDefault = t.interface({
    ...base,
    product: t.string,
});

export const PlanCodecExpandProduct = t.interface({
    ...base,
    product: ProductCodec,
});

export const PlanCodec = t.union([PlanCodecDefault, PlanCodecExpandProduct]);

export type Plan = t.TypeOf<typeof PlanCodec>;
export type PlanDefault = t.TypeOf<typeof PlanCodecDefault>;
export type PlanExpandProduct = t.TypeOf<typeof PlanCodecExpandProduct>;