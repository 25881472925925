import React from "react";
import { DnsRecordWithApi } from "./DnsRecordWithApi";
import { EmailConfigIssuesWithApi } from "./EmailConfigIssuesWithApi";
import { ExposedServicesWithApi } from "./ExposedServicesWithApi";
import { Issues } from "./Issues";
import { Tabs } from "src/elements/Tabs/Tabs";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    width: "100%",
    minHeight: "100%",
    padding: theme.spacing(2),
    background: theme.palette.background.default,
}))

export const AttackSurfaceReport: React.FC = () => (
    <StyledRoot>
        <Tabs tabs={["DNS Records", "TLS Issues", "Exposed Services", "Email Config Issues"]}>
            <DnsRecordWithApi />
            <Issues types={["cert_expired", "cert_trust"]} />
            <ExposedServicesWithApi />
            <EmailConfigIssuesWithApi />
        </Tabs>
    </StyledRoot>
);
