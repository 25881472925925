import { Button, Typography, ButtonProps as MuiButtonProps } from "@mui/material";
import React from "react";
import { Price } from "src/elements/Price/Price";
import { PlanExpandProduct } from "src/misc/codecs/stripe/Plan";
import { styled } from '@mui/material/styles';

type StyledPlanHeaderButtonProps = MuiButtonProps & {
    hasActivePlan: boolean;
    active: boolean;
}

const StyledPlanHeaderButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'hasActivePlan' && prop !== 'active'
})<StyledPlanHeaderButtonProps>(({ hasActivePlan, active }) => ({
    width: "100%",
    ...(hasActivePlan && active && {
        pointerEvents: "none",
        cursor: "default",
    })
}))

const StyledRoot = styled('thead')(({ theme }) => ({
    "& tr > th": {
        verticalAlign: "top",
        padding: theme.spacing(2),
    },
}))

const StyledDetail = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
}))

const StyledProductPrice = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

type StyledTableHeadProps = React.TableHTMLAttributes<HTMLTableCellElement> & {
    isActive: boolean;
}

const StyledTableHead = styled('th', {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<StyledTableHeadProps>(({ isActive, theme }) => ({
    ...(isActive && {
        backgroundColor: theme.palette.action.disabledBackground,
    })
}))

/***************************************
 *  Table header with select button
 ***************************************/
type ButtonProps = {
    activePlan: string | undefined;
    price_id: string;
    checkout: (priceId: string) => void;
};

const PlanHeaderButton: React.FC<ButtonProps> = ({
    activePlan,
    price_id,
    checkout,
}) => {
    const hasActivePlan = activePlan !== undefined;
    const active = activePlan === price_id;

    // Handle button onClick to create subscription if it doesn't exist
    const handleOnClick = (priceId: string) => {
        if (!hasActivePlan) {
            checkout(priceId);
        }
    };

    return (
        <StyledPlanHeaderButton
            size="small"
            variant={active ? "contained" : "outlined"}
            color="primary"
            disabled={hasActivePlan && !active}
            onClick={() => handleOnClick(price_id)}
            hasActivePlan={hasActivePlan}
            active={active}
        >
            {active ? "Current Plan" : "Select"}
        </StyledPlanHeaderButton>
    );
};

type Props = {
    plans: PlanExpandProduct[];
    activePlan?: string;
    checkout: (priceId: string) => void;
};

export const PlanTableHeader: React.FC<Props> = ({
    plans,
    activePlan,
    checkout,
}) => {
    const active = (id: string) => id === activePlan;
    return (
        <StyledRoot>
            <tr>
                <th>
                    <Typography variant="h2">Plans & pricing</Typography>
                </th>
                {plans.map(plan => (
                    <StyledTableHead key={plan.id} isActive={active(plan.id)}>
                        <StyledDetail>
                            <Typography variant="h2">
                                {plan.product.name}
                            </Typography>
                            <StyledProductPrice variant="h2">
                                <Price amount={plan.amount} currency={plan.currency}/> <span style={{fontSize: "0.8rem"}}>/ {plan.interval}</span>
                            </StyledProductPrice>
                            <PlanHeaderButton
                                activePlan={activePlan}
                                price_id={plan.id}
                                checkout={checkout}
                            />
                        </StyledDetail>

                    </StyledTableHead>
                ))}
            </tr>
        </StyledRoot>
    );
};
