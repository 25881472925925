import React from "react";
import {
    TimelineConnector,
    TimelineConnectorProps,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineItemProps,
    TimelineSeparator,
    TimelineSeparatorProps,
} from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { WelcomeStepIndex } from "src/shared/enums";
import { styled } from '@mui/material/styles';

type Props = {
    step: number;
};

const StyledTimelineContent = styled(TimelineContent)({
    minWidth: "100%",
    maxWidth: "100%",
});

type StyledInactiveTimelineItemProps = TimelineItemProps & {
    isActiveStep: boolean;
}

const StyledTimelineItem = styled(TimelineItem, {
    shouldForwardProp: (prop) => prop !== 'isActiveStep',
})<StyledInactiveTimelineItemProps>(({ isActiveStep, theme }) => ({
    ...(!isActiveStep && { color: theme.palette.text.disabled })
}))


type StyledTimelineSeparatorProps = TimelineSeparatorProps & {
    isCompletedStep: boolean;
}

const StyledTimelineSeparator = styled(TimelineSeparator, {
    shouldForwardProp: (prop) => prop !== 'isCompletedStep'
})<StyledTimelineSeparatorProps>(({ isCompletedStep, theme }) => ({
    ...(!isCompletedStep && ({
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1.3),
    }))
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
    width: theme.spacing(2),
    height: theme.spacing(2),
}))

type StyledTimelineConnectorProps = TimelineConnectorProps & {
    isActiveStep: boolean;
    isCompletedStep: boolean;
}

const StyledTimelineConnector = styled(TimelineConnector, {
    shouldForwardProp: (prop) => prop !== 'isActiveStep' && prop !== 'isCompletedStep'
})<StyledTimelineConnectorProps>(({ isActiveStep, isCompletedStep, theme}) => ({
    ...((isActiveStep || isCompletedStep) && { backgroundColor: theme.palette.primary.dark })
}));


const StyledTableData = styled('td')({
    height: "100%",
    verticalAlign: "top",
});

type StyledTableRowProps = React.TableHTMLAttributes<HTMLTableRowElement> & {
    isCompletedStep: boolean;
}
const StyledTableRow = styled('tr', {
    shouldForwardProp: (prop) => prop !== 'isCompletedStep'
})<StyledTableRowProps>(({ isCompletedStep }) => ({
    ...(isCompletedStep && { display: 'none' })
}))


const StyledDescription = styled(Typography)(({ theme }) => ({
    margin: `${theme.spacing(0.8)} 0 ${theme.spacing(1.5)} 0`,
    fontSize: "0.9rem",
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.spacing(1.5),
}))

export const WelcomeProvideTargets: React.FC<Props> = ({ step }) => {
    const navigate = useNavigate();

    const addDomainAction = () => navigate("/target-domains");

    const isActiveStep = step === WelcomeStepIndex.ProviderTargets;
    const isCompletedStep = step > WelcomeStepIndex.ProviderTargets;

    return (
        <StyledTimelineItem isActiveStep={isActiveStep}>
            <StyledTimelineSeparator isCompletedStep={isCompletedStep}>
                <TimelineDot
                    color={isActiveStep || isCompletedStep ? "primary" : "grey"}
                    variant={
                        isActiveStep || isCompletedStep ? "filled" : "outlined"
                    }
                >
                    {isCompletedStep && (
                        <StyledCheckIcon />
                    )}
                </TimelineDot>
                <StyledTimelineConnector isActiveStep={isActiveStep} isCompletedStep={isCompletedStep} />
            </StyledTimelineSeparator>
            <StyledTimelineContent>
                <table>
                    <tbody>
                        <tr>
                            <StyledTableData>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">1.</Box>
                                </Typography>
                            </StyledTableData>
                            <StyledTableData>
                                <Typography variant="h6" component="div">
                                    <Box fontWeight="fontWeightBold">
                                        Provide targets
                                    </Box>
                                </Typography>
                            </StyledTableData>
                        </tr>
                        <StyledTableRow isCompletedStep={isCompletedStep}>
                            <td></td>
                            <td>
                                <StyledDescription>
                                    Add a domain or a lists of domains
                                </StyledDescription>
                                <StyledButton
                                    variant="contained"
                                    color="primary"
                                    onClick={addDomainAction}
                                >
                                    Add Target Domain
                                </StyledButton>
                            </td>
                        </StyledTableRow>
                    </tbody>
                </table>
            </StyledTimelineContent>
        </StyledTimelineItem>
    );
};
