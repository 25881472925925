import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { BreachRecord } from '../services/full-report';
import { RequestStatus } from 'src/shared/enums';
import { FullReportLoading } from '../molecules/FullReportLoading';
import { Fade, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  data: BreachRecord[];
  status: number;
}

const StyledTable = styled(Table)({
    minWidth: 650,
    width: "100%",
    tableLayout: "fixed",
    "& th, td": {
        wordWrap: "break-word",
    }
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.table.head.background,
    color: theme.palette.table.head.text,
}))


export const LeakCredentials:React.FC<Props> = ({
  data,
  status
}) => {
  if (status === RequestStatus.Pending) {
    return <FullReportLoading />
  }

  return (
      <Fade in={true}>
          <TableContainer>
              <StyledTable
                  aria-label="Leak Credentials table"
              >
                  <StyledTableHead>
                      <TableRow>
                          <TableCell width="20%">
                              <Typography variant="subtitle2">
                                  Email
                              </Typography>
                          </TableCell>
                          <TableCell width="20%">
                              <Typography variant="subtitle2">
                                  Username
                              </Typography>
                          </TableCell>
                          <TableCell width="40%">
                              <Typography variant="subtitle2">
                                  Hashed password
                              </Typography>
                          </TableCell>
                          <TableCell width="20%">
                              <Typography variant="subtitle2">
                                  Source
                              </Typography>
                          </TableCell>
                      </TableRow>
                  </StyledTableHead>
                  <TableBody>
                      {data.map((row, index) => (
                          <TableRow key={index}>
                              <TableCell component="th" scope="row" width="20%">
                                  {row.email}
                              </TableCell>
                              <TableCell width="20%">{row.username}</TableCell>
                              <TableCell width="40%">{row.hashed_password}</TableCell>
                              <TableCell width="20%">{row.database_name}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </StyledTable>
          </TableContainer>
      </Fade>
  );
}