import {
    CircularProgress,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import { DeleteOutline, Refresh } from '@mui/icons-material';
import React from 'react'
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { Row, usePostgrest } from 'src/services/postgrest-provider';
import { useAuth } from '../../services/auth-provider/AuthProvider';
import UserDeleteDialog from './UserDeleteDialog';
import { styled } from '@mui/material/styles';


const StyledIconButton = styled(IconButton)({
    padding: 0,
})

const StyledControls = styled('span')({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
})

const StyledEmailLabel = styled(Typography)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
})

const StyledTable = styled(Table)({
    width: "100%",
})

type UserDetail = Row<"user_detail">;

type State = { state: "loading" }
           | { state: "error"; error: string }
           | { state: "ok"; users: UserDetail[]; refreshing: boolean };

export const UserTable: React.FC = (): JSX.Element => {

    const postgrest = usePostgrest();
    const [state, setState] = useState<State>({state: "loading"});
    const [open, setOpen] = useState(false);
    const [targetUser, setTargetUser] = useState<UserDetail | null>(null);

    const auth = useAuth();

    const handleOpen = (user: UserDetail) => {
        setOpen(true)
        setTargetUser(user);
    }

    const handleClose = () => {
        setOpen(false)
    }


    const load = useCallback(() => {
        setState(state.state === "ok" ? {...state, refreshing: true} : {state: "loading"});
        postgrest.GetTable("user_detail").then(res => {
            if (res.type === "success") {
                setState({state: "ok", users: res.data, refreshing: false});
            } else {
                setState({state: "error", error: "couldn't fetch user details"});
            }
        });
    }, [postgrest, state]);

    // Load on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => load(), []);

    const refreshButton = (
        <StyledIconButton size="small" onClick={() => load()}>
            <Refresh color="action" fontSize="inherit"/>
        </StyledIconButton>
    );

    const theme = useTheme();

    const spinner = <CircularProgress size="18px" color="inherit" style={{padding: 3, color: theme.palette.action.active}}/>;

    // The control to render in table header
    const control = (() => {
        switch (state.state) {
        case "loading":
            return spinner;
        case "error":
            return refreshButton;
        case "ok":
            return state.refreshing ? spinner : refreshButton;
        }
    })();

    const contentLoading = (
        <TableRow>
            <TableCell colSpan={2}>
                Loading...
            </TableCell>
        </TableRow>
    );

    const contentError = (error: string) => (
        <TableRow>
            <TableCell colSpan={2}>Error: {error}</TableCell>
        </TableRow>
    );

    const contentOK = (users: UserDetail[]) => (<>
        {users.map(user => {
            const removeButton = (
                <IconButton size="small" onClick={() => handleOpen(user)}>
                    <DeleteOutline color="action" fontSize="inherit"/>
                </IconButton>
            );

            const control = (() => {
                if(auth.user.profile.sub != user.id){
                    return removeButton;
                }
            })();

            return (
                <TableRow key={user.id}>
                    <TableCell>{user.first_name} {user.last_name}</TableCell>
                    <TableCell><Link href={`mailto:${user.email}`} color='inherit' underline="hover">{user.email}</Link></TableCell>
                    <TableCell>
                        <StyledControls>
                            {control}
                        </StyledControls>
                    </TableCell>
                </TableRow>
            );
        })}
        { targetUser ? <UserDeleteDialog userId={targetUser.id} username={targetUser.first_name+" "+targetUser.last_name} open={open} closeDialog={handleClose} postAction={load}/> : "" }
    </>);

    // The main table content/rows
    const content = (() => {
        switch (state.state) {
        case "loading":
            return contentLoading;
        case "error":
            return contentError(state.error);
        case "ok":
            return contentOK(state.users);
        }
    })();

    return (
        <StyledTable size="small">
            <TableHead>
                <TableRow>
                    <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                    <TableCell>
                        <StyledEmailLabel variant="subtitle2">Email</StyledEmailLabel>
                    </TableCell>
                    <TableCell style={{width: 0}}>{control}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {content}
            </TableBody>
        </StyledTable>
    );
}
