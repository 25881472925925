import * as t from 'io-ts';
import { LiteralUnion } from '../LiteralUnion';

// https://stripe.com/docs/api/payment_methods/object
export const PaymentMethodCodec = t.intersection([
    // Shared fields, they exist in all payment methods
    t.interface({
        id: t.string,
        object: t.literal("payment_method"),
        billing_details: t.interface({
            address: t.interface({
                city: t.string,
                country: t.string,
                line1: t.string,
                line2: t.string,
                postal_code: t.string,
                state: t.string,
            }),
            email: t.string,
            name: t.string,
            phone: t.string,
        }),
    }),
    // Type specific fields, e.g., if type=card, then a card object exists
    // with details specific to the card type.
    // We only deal with cards for the moment, so the details of all other
    // types are left as unknown.
    t.union([
        t.interface({
            type: t.literal("card"),
            card: t.interface({
                brand: LiteralUnion([
                    "amex",
                    "diners",
                    "discover",
                    "jcb",
                    "mastercard",
                    "unionpay",
                    "visa",
                    "unknown",
                ] as const),
                checks: t.interface({
                    address_line1_check: LiteralUnion(["pass", "fail", "unavailable", "unchecked", ""] as const),
                    address_postal_code_check: LiteralUnion(["pass", "fail", "unavailable", "unchecked", ""] as const),
                    cvc_check: LiteralUnion(["pass", "fail", "unavailable", "unchecked", ""] as const),
                }),
                country: t.string,
                exp_month: t.number,
                exp_year: t.number,
                fingerprint: t.string,
                funding: LiteralUnion(["credit", "debit", "prepaid", "unknown"] as const),
                last4: t.string,
                networks: t.interface({
                    available: t.array(t.string),
                    preferred: t.string,
                }),
                three_d_secure_usage: t.interface({
                    supported: t.boolean,
                }),
                wallet: t.unknown,
            })
        }),
        t.interface({
            type: t.literal("acss_debit"),
            acss_debit: t.unknown,
        }),
        t.interface({
            type: t.literal("afterpay_clearpay"),
            afterpay_clearpay: t.unknown,
        }),
        t.interface({
            type: t.literal("alipay"),
            alipay: t.unknown,
        }),
        t.interface({
            type: t.literal("au_becs_debit"),
            au_becs_debit: t.unknown,
        }),
        t.interface({
            type: t.literal("bacs_debit"),
            bacs_debit: t.unknown,
        }),
        t.interface({
            type: t.literal("bancontact"),
            bancontact: t.unknown,
        }),
        t.interface({
            type: t.literal("boleto"),
            boleto: t.unknown,
        }),
        t.interface({
            type: t.literal("eps"),
            eps: t.unknown,
        }),
        t.interface({
            type: t.literal("fpx"),
            fpx: t.unknown,
        }),
        t.interface({
            type: t.literal("giropay"),
            giropay: t.unknown,
        }),
        t.interface({
            type: t.literal("grabpay"),
            grabpay: t.unknown,
        }),
        t.interface({
            type: t.literal("ideal"),
            ideal: t.unknown,
        }),
        t.interface({
            type: t.literal("interac_present"),
            interac_present: t.unknown,
        }),
        t.interface({
            type: t.literal("klarna"),
            klarna: t.unknown,
        }),
        t.interface({
            type: t.literal("konbini"),
            konbini: t.unknown,
        }),
        t.interface({
            type: t.literal("oxxo"),
            oxxo: t.unknown,
        }),
        t.interface({
            type: t.literal("p24"),
            p24: t.unknown,
        }),
        t.interface({
            type: t.literal("paynow"),
            paynow: t.unknown,
        }),
        t.interface({
            type: t.literal("sepa_debit"),
            sepa_debit: t.unknown,
        }),
        t.interface({
            type: t.literal("sofort"),
            sofort: t.unknown,
        }),
        t.interface({
            type: t.literal("wechat_pay"),
            wechat_pay: t.unknown,
        }),
    ]),
]);

export type PaymentMethod = t.TypeOf<typeof PaymentMethodCodec>;